<mat-sidenav-container (backdropClick)="close()">
    <main class="main-container">
        <div class="content">
            <div class="content-background"></div>
            <div class="content-inner">
                <div class="section-heading pb-3">
                    Professional Property Valuation
                </div>
                <div class="section-text pb-3">
                    You may need an objective assessment to determine the 'open market value' of a property for making
                    informed decisions, or for financing or auditing purposes.
                </div>
                <div class="section-text pb-5">
                    Our national panel of selected professional valuers, registered with the South African Council for
                    the Property Valuers Profession (“SACPVP”), is eager to investigate and value all types of
                    properties.
                </div>
                <div class="section-sub-heading pb-3">
                    Options Available:
                </div>
                <div class="row levels-container">
                    <div class="level col-6">
                        <div class="level-heading">
                            Level
                        </div>
                        <div class="level-number">
                            1
                        </div>
                        <div class="level-sub-heading">
                            Desktop valuation:
                        </div>
                        <div class="list-items">
                            <ul class="paragraph">
                                <li>2-page memorandum</li>
                                <li>Excludes site visit. Pictures & information provided by owner or agent</li>
                                <li>Professional valuer conducts market value assessment</li>
                                <li>3 to 5 working days for delivery</li>
                            </ul>
                        </div>
                        <div class="section-text bold py-4">
                            R2 000 - R6 000
                        </div>
                        <div class="learn-more-button">
                            <button class="button" (click)="openLearnMoreOne(0)">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                    <div class="level col-6">
                        <div class="level-heading">
                            Level
                        </div>
                        <div class="level-number">
                            2
                        </div>
                        <div class="level-sub-heading">
                            Formal valuation report:
                        </div>
                        <div class="list-items">
                            <ul class="paragraph">
                                <li>15 to 20-page report</li>
                                <li>Includes site visit by professional valuer</li>
                                <li>Full details of all market research and calculations</li>
                                <li>10 to 12 working days for delivery</li>
                            </ul>
                        </div>
                        <div class="section-text bold py-4">
                            R3 500 - R30 000
                        </div>
                        <div class="learn-more-button">
                            <button class="button" (click)="openLearnMoreTwo(0)">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <mat-sidenav #sidenav (keydown.escape)="close()" position="end">
        <section class="rotate-buttons" [hidden]="isShow">
            <button class="sidenav-button heading-2" [ngClass]="{'open':open, 'active': tabIndex === 0}"
                (click)="openLearnMoreOne(0)">
                LEVEL 1
            </button>
            <button class="sidenav-button heading-2" [ngClass]="{'open':open, 'active': tabIndex === 1}"
                (click)="openLearnMoreTwo(1)">
                LEVEL 2
            </button>
        </section>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevelOne">
            <div class="row py-3">
                <div class="col-11 feasibility-dialog-heading">
                    Level 1: Desktop Valuation
                </div>
                <div class="col-1 btn-close-align">
                    <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close">
                    </button>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    <span class="coral-text bold">Please note: </span>
                    <span>
                        Our national network of selected Professional Valuers - all registered with the South African
                        Council for the Property Valuers Profession (“SACPVP”) - will provide this service at a
                        competitive rate. Rest assured that in terms of the Professional Valuers code of conduct, all
                        property valuations will be performed in an independent and objective manner.
                    </span>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-5-light">
                    Description of a Desktop Valuation Report:
                </div>
                <div class="col-12 paragraph light">
                    This professional market value assessment is presented in a memorandum format. No site visit is
                    conducted. You will need to complete a questionnaire and upload recent pictures of the property, or,
                    if applicable, request one of our equipped estate agents to collect the data. Our verified valuers
                    will provide expert input, but the accuracy of the information provided is crucial. If you have
                    limited or technical skills, you may consider a Formal Valuation (Level 2).
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Factors Considered:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            <b>Location factors</b> such as macro- and micro-location, accessibility, area developments,
                            nearby properties and amenities.
                        </li>
                        <li>
                            <b>Site particulars</b>, including property size and shape, planning specifications (current
                            and potential zoning, density, height, coverage, FAR, building lines), and servitudes.
                        </li>
                        <li>
                            <b>Property improvements</b> like building type, quality, size, grade, age, parking, and
                            security.
                        </li>
                        <li>
                            <b>Building tenancy</b>, whether owner-occupied or tenanted, tenant details, lease terms,
                            renewals or tenant defaults.
                        </li>
                        <li>
                            <b>Overall market conditions</b> encompassing macro-economic factors, supply and demand
                            dynamics, and if relevant, area rental rates and vacancy trends.
                        </li>
                        <li>
                            <b>Comparative Market Analysis</b> (if required), featuring nearby comparable sales or sales
                            in similar investor nodes, similar rentals, and property saleability.
                        </li>
                        <li>
                            <b>Property price</b>, including previous purchase prices and dates, as well as the current
                            municipal valuation.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Deliveries And Time-Frames
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            2 to 4-page PDF memorandum
                        </li>
                        <li>
                            Questionnaire to filled in; or where applicable, data collection by trained estate agent
                        </li>
                        <li>
                            Professional valuer's market value assessment
                        </li>
                        <li>
                            3 to 5 working days for delivery
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    <span class="bold">Please note: </span>
                    <span>
                        This report suits financing applications; however, banks often have specific
                        templates. We can adapt the report for a fee, according to bank requirements.
                    </span>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-5-light">
                    <span class="uppercase">Fee Structure </span>
                    <span>(estimates only)</span>
                </div>
                <div class="col-12 paragraph light">
                    The fees indicated in the table below exclude VAT and may vary for properties with unusual types or
                    characteristics. Specialized properties such as hotels, guesthouses, schools, colleges, require a
                    more detailed analysis and may necessitate a Formal Valuation.
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-5-light uppercase">
                    Our Offer
                </div>
                <div class="col-12 paragraph light">
                    Fees will be reduced for properties already listed for sale on the Property Matrix website and
                    valued above R5 million.
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12">
                    <table>
                        <colgroup>
                            <col class="col-group-1">
                            <col class="col-group-2">
                        </colgroup>
                        <thead>
                            <tr>
                                <th class="heading-2-light text-center uppercase" colspan="2">
                                    Desktop Valuation
                                </th>
                            </tr>
                        </thead>
                        <tbody class="paragraph light">
                            <tr class="grey-background paragraph">
                                <td colspan="2">Single Residential property (vacant or developed)</td>
                            </tr>
                            <tr>
                                <td>Value less than R5 million</td>
                                <td>R1 500</td>
                            </tr>
                            <tr>
                                <td>Value between R5 million and R15 million</td>
                                <td>R2 500</td>
                            </tr>
                            <tr>
                                <td>Value between R15 million and R30 million</td>
                                <td>R3 500</td>
                            </tr>
                            <tr>
                                <td>Value between R30 million and R50 million</td>
                                <td>R4 500</td>
                            </tr>
                            <tr>
                                <td>Value above R50 million</td>
                                <td>5 000</td>
                            </tr>
                            <tr class="grey-background paragraph">
                                <td colspan="2">
                                    Commercial property (vacant/industrial/offices/flats/retail/other)
                                </td>
                            </tr>
                            <tr>
                                <td>Value less than R5 million</td>
                                <td>R2 500</td>
                            </tr>
                            <tr>
                                <td>Value between R5-million and R15-million</td>
                                <td>R4 500</td>
                            </tr>
                            <tr>
                                <td>Value between R15 million and R30 million</td>
                                <td>R6 500</td>
                            </tr>
                            <tr>
                                <td>Value between R30-million and R50-million</td>
                                <td>R8 500</td>
                            </tr>
                            <tr>
                                <td>Value above R50-million</td>
                                <td>R10 000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row pb-3">
                <div class="center-content">
                    <button (click)="openDialog()" class="button button-light uppercase">
                        Request
                    </button>
                </div>
            </div>
        </div>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevelTwo">
            <div class="row py-3">
                <div class="col-11 feasibility-dialog-heading">
                    Level 2: Formal Valuation Reports
                </div>
                <div class="col-1 btn-close-align">
                    <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close">
                    </button>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    <span class="coral-text bold">Please note: </span>
                    <span>
                        Our national network of selected Professional Valuers - all registered with the South African
                        Council for the Property Valuers Profession (“SACPVP”) - will provide this service at a
                        competitive rate. Rest assured that in terms of the Professional Valuers code of conduct, all
                        property valuations will be performed in an independent and objective manner.
                    </span>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-5-light">
                    Description of a Formal Valuation Report:
                </div>
                <div class="col-12 paragraph light">
                    This is a detailed valuation report and will include a site visit by the property valuer. The report
                    will contain full details of market research and will be an accurate determination of the market
                    value of the property. The report can also be used for negotiation purposes, tax and audit purposes,
                    financing purposes, etc. It also provides a replacement value of the property improvements for
                    insurance purposes.
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Factors Considered:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            <b>Location factors</b> such as macro- and micro-location, accessibility, area developments,
                            nearby properties and amenities.
                        </li>
                        <li>
                            <b>Site particulars</b>, including property size and shape, planning specifications (current
                            and potential zoning, density, height, coverage, FAR, building lines), and servitudes.
                        </li>
                        <li>
                            <b>Property improvements</b> like building type, quality, size, grade, age, parking, and
                            security.
                        </li>
                        <li>
                            <b>Building tenancy</b>, whether owner-occupied or tenanted, tenant details, lease terms,
                            renewals or tenant defaults.
                        </li>
                        <li>
                            <b>Overall market conditions</b> encompassing macro-economic factors, supply and demand
                            dynamics, and if relevant, area rental rates and vacancy trends.
                        </li>
                        <li>
                            <b>Comparative Market Analysis</b> (if required), featuring nearby comparable sales or sales
                            in similar investor nodes, similar rentals, and property saleability.
                        </li>
                        <li>
                            <b>Property price</b>, including previous purchase prices and dates, as well as the current
                            municipal valuation.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Deliveries And Time-Frames
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            15 to 30-page PDF report
                        </li>
                        <li>
                            Site visit by professional valuer
                        </li>
                        <li>
                            Full market research and calculations
                        </li>
                        <li>
                            10 to 12 working days for delivery
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    <span class="bold">Please note: </span>
                    <span>
                        This report suits financing applications; however, banks often have specific templates. We can
                        adapt the report for a fee, according to bank requirements.
                    </span>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-5-light">
                    <span class="uppercase">Fee Structure </span>
                    <span>(estimates only)</span>
                </div>
                <div class="col-12 paragraph light">
                    Fees indicated in the table below exclude VAT and vary based on property value.
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    <span class="coral-text bold">Important: </span>
                    <span>
                        This is a general indication of fees; each request for a valuation will be assessed
                        individually. A quotation will be provided based on property specific characteristics.
                    </span>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12">
                    <table>
                        <colgroup>
                            <col class="col-group-1">
                            <col class="col-group-2">
                        </colgroup>
                        <thead>
                            <tr>
                                <th class="heading-2-light text-center uppercase" colspan="2">
                                    Fee Schedule: Formal Valuation Report
                                </th>
                            </tr>
                        </thead>
                        <tbody class="paragraph light">
                            <tr class="grey-background paragraph">
                                <td colspan="2">Single Residential property (vacant or developed)</td>
                            </tr>
                            <tr>
                                <td>Value less than R5 million</td>
                                <td>R2 000 to R4 500</td>
                            </tr>
                            <tr>
                                <td>Value between R5 million and R15 million</td>
                                <td>R3 500 to R8 500</td>
                            </tr>
                            <tr>
                                <td>Value between R15 million and R30 million</td>
                                <td>R7 500 to R14 500</td>
                            </tr>
                            <tr>
                                <td>Value between R30 million and R50 million</td>
                                <td>R12 500 to R19 500</td>
                            </tr>
                            <tr>
                                <td>Value above R50 million</td>
                                <td>R17 500 +</td>
                            </tr>
                            <tr class="grey-background paragraph">
                                <td colspan="2">
                                    Commercial property (vacant/industrial/offices/flats/retail/other)
                                </td>
                            </tr>
                            <tr>
                                <td>Value less than R5 million</td>
                                <td>R3 500 to R7 500</td>
                            </tr>
                            <tr>
                                <td>Value between R5-million and R15-million</td>
                                <td>R7 500 to R15 500</td>
                            </tr>
                            <tr>
                                <td>Value between R15 million and R30 million</td>
                                <td>R14 500 to R21 500</td>
                            </tr>
                            <tr>
                                <td>Value between R30-million and R50-million</td>
                                <td>R20 500 to R28 500</td>
                            </tr>
                            <tr>
                                <td>Value above R50-million</td>
                                <td>R26 500 +</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row pb-3">
                <div class="center-content">
                    <button (click)="openDialog()" class="button button-light uppercase">
                        Request
                    </button>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>