/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddressDto } from '../models/address-dto';
import { RiskscapeAddressDto } from '../models/riskscape-address-dto';
import { RiskscapeAddressGeocodeDto } from '../models/riskscape-address-geocode-dto';
import { RiskscapeBulkPropertyDataData } from '../models/riskscape-bulk-property-data-data';
import { RiskscapeDemographicsDto } from '../models/riskscape-demographics-dto';
import { RiskscapeDolomiteDto } from '../models/riskscape-dolomite-dto';
import { RiskscapePositionLookupDto } from '../models/riskscape-position-lookup-dto';
import { RiskscapePropertyDetailsDto } from '../models/riskscape-property-details-dto';
import { RiskscapePropertyImageDto } from '../models/riskscape-property-image-dto';
import { RiskscapeSchemeUnitDto } from '../models/riskscape-scheme-unit-dto';
import { RiskscapeStructureExtentsDto } from '../models/riskscape-structure-extents-dto';
import { RiskscapeValuationDto } from '../models/riskscape-valuation-dto';

@Injectable({
  providedIn: 'root',
})
export class MapsService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1MapsGetAddressGeocodeGet
   */
  static readonly ApiV1MapsGetAddressGeocodeGetPath = '/api/v1/Maps/GetAddressGeocode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetAddressGeocodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressGeocodeGet$Response(params?: {
    searchQuery?: string;
    propertyKey?: boolean;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeAddressGeocodeDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetAddressGeocodeGetPath, 'get');
    if (params) {
      rb.query('searchQuery', params.searchQuery, {});
      rb.query('propertyKey', params.propertyKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeAddressGeocodeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetAddressGeocodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressGeocodeGet(params?: {
    searchQuery?: string;
    propertyKey?: boolean;
    context?: HttpContext
  }
): Observable<RiskscapeAddressGeocodeDto> {

    return this.apiV1MapsGetAddressGeocodeGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeAddressGeocodeDto>) => r.body as RiskscapeAddressGeocodeDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetAddressGeocodePost
   */
  static readonly ApiV1MapsGetAddressGeocodePostPath = '/api/v1/Maps/GetAddressGeocode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetAddressGeocodePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MapsGetAddressGeocodePost$Response(params?: {
    context?: HttpContext
    body?: AddressDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetAddressGeocodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetAddressGeocodePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MapsGetAddressGeocodePost(params?: {
    context?: HttpContext
    body?: AddressDto
  }
): Observable<void> {

    return this.apiV1MapsGetAddressGeocodePost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetAddressByAreaIdGet
   */
  static readonly ApiV1MapsGetAddressByAreaIdGetPath = '/api/v1/Maps/GetAddressByAreaId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetAddressByAreaIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressByAreaIdGet$Response(params?: {
    areaId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeAddressDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetAddressByAreaIdGetPath, 'get');
    if (params) {
      rb.query('areaId', params.areaId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeAddressDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetAddressByAreaIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressByAreaIdGet(params?: {
    areaId?: string;
    context?: HttpContext
  }
): Observable<RiskscapeAddressDto> {

    return this.apiV1MapsGetAddressByAreaIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeAddressDto>) => r.body as RiskscapeAddressDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetAddressDetailsGet
   */
  static readonly ApiV1MapsGetAddressDetailsGetPath = '/api/v1/Maps/GetAddressDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetAddressDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressDetailsGet$Response(params?: {
    addressId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeAddressDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetAddressDetailsGetPath, 'get');
    if (params) {
      rb.query('addressId', params.addressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeAddressDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetAddressDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressDetailsGet(params?: {
    addressId?: string;
    context?: HttpContext
  }
): Observable<RiskscapeAddressDto> {

    return this.apiV1MapsGetAddressDetailsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeAddressDto>) => r.body as RiskscapeAddressDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetAddressImageGet
   */
  static readonly ApiV1MapsGetAddressImageGetPath = '/api/v1/Maps/GetAddressImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetAddressImageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressImageGet$Response(params?: {
    propertyKey?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapePropertyImageDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetAddressImageGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapePropertyImageDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetAddressImageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressImageGet(params?: {
    propertyKey?: string;
    context?: HttpContext
  }
): Observable<RiskscapePropertyImageDto> {

    return this.apiV1MapsGetAddressImageGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapePropertyImageDto>) => r.body as RiskscapePropertyImageDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetPropertyDetailsFromLatLonGet
   */
  static readonly ApiV1MapsGetPropertyDetailsFromLatLonGetPath = '/api/v1/Maps/GetPropertyDetailsFromLatLon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetPropertyDetailsFromLatLonGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetPropertyDetailsFromLatLonGet$Response(params?: {
    lat?: string;
    lon?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapePositionLookupDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetPropertyDetailsFromLatLonGetPath, 'get');
    if (params) {
      rb.query('lat', params.lat, {});
      rb.query('lon', params.lon, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapePositionLookupDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetPropertyDetailsFromLatLonGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetPropertyDetailsFromLatLonGet(params?: {
    lat?: string;
    lon?: string;
    context?: HttpContext
  }
): Observable<RiskscapePositionLookupDto> {

    return this.apiV1MapsGetPropertyDetailsFromLatLonGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapePositionLookupDto>) => r.body as RiskscapePositionLookupDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetUnitsFromSchemeIdGet
   */
  static readonly ApiV1MapsGetUnitsFromSchemeIdGetPath = '/api/v1/Maps/GetUnitsFromSchemeId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetUnitsFromSchemeIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetUnitsFromSchemeIdGet$Response(params?: {
    schemeId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeSchemeUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetUnitsFromSchemeIdGetPath, 'get');
    if (params) {
      rb.query('schemeId', params.schemeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeSchemeUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetUnitsFromSchemeIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetUnitsFromSchemeIdGet(params?: {
    schemeId?: string;
    context?: HttpContext
  }
): Observable<RiskscapeSchemeUnitDto> {

    return this.apiV1MapsGetUnitsFromSchemeIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeSchemeUnitDto>) => r.body as RiskscapeSchemeUnitDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetSchemeDataGet
   */
  static readonly ApiV1MapsGetSchemeDataGetPath = '/api/v1/Maps/GetSchemeData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetSchemeDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetSchemeDataGet$Response(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeSchemeUnitDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetSchemeDataGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeSchemeUnitDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetSchemeDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetSchemeDataGet(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<RiskscapeSchemeUnitDto> {

    return this.apiV1MapsGetSchemeDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeSchemeUnitDto>) => r.body as RiskscapeSchemeUnitDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetPropertyDetailsGet
   */
  static readonly ApiV1MapsGetPropertyDetailsGetPath = '/api/v1/Maps/GetPropertyDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetPropertyDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetPropertyDetailsGet$Response(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapePropertyDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetPropertyDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapePropertyDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetPropertyDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetPropertyDetailsGet(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<RiskscapePropertyDetailsDto> {

    return this.apiV1MapsGetPropertyDetailsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapePropertyDetailsDto>) => r.body as RiskscapePropertyDetailsDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetStructureExtentsGet
   */
  static readonly ApiV1MapsGetStructureExtentsGetPath = '/api/v1/Maps/GetStructureExtents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetStructureExtentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetStructureExtentsGet$Response(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeStructureExtentsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetStructureExtentsGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeStructureExtentsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetStructureExtentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetStructureExtentsGet(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<RiskscapeStructureExtentsDto> {

    return this.apiV1MapsGetStructureExtentsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeStructureExtentsDto>) => r.body as RiskscapeStructureExtentsDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetValuationGet
   */
  static readonly ApiV1MapsGetValuationGetPath = '/api/v1/Maps/GetValuation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetValuationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetValuationGet$Response(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeValuationDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetValuationGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeValuationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetValuationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetValuationGet(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<RiskscapeValuationDto> {

    return this.apiV1MapsGetValuationGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeValuationDto>) => r.body as RiskscapeValuationDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetDemographicsGet
   */
  static readonly ApiV1MapsGetDemographicsGetPath = '/api/v1/Maps/GetDemographics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetDemographicsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetDemographicsGet$Response(params?: {
    subPlaceId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeDemographicsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetDemographicsGetPath, 'get');
    if (params) {
      rb.query('subPlaceId', params.subPlaceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeDemographicsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetDemographicsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetDemographicsGet(params?: {
    subPlaceId?: string;
    context?: HttpContext
  }
): Observable<RiskscapeDemographicsDto> {

    return this.apiV1MapsGetDemographicsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeDemographicsDto>) => r.body as RiskscapeDemographicsDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetDolomiteGet
   */
  static readonly ApiV1MapsGetDolomiteGetPath = '/api/v1/Maps/GetDolomite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetDolomiteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetDolomiteGet$Response(params?: {
    subPlaceId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeDolomiteDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetDolomiteGetPath, 'get');
    if (params) {
      rb.query('subPlaceId', params.subPlaceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeDolomiteDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetDolomiteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetDolomiteGet(params?: {
    subPlaceId?: string;
    context?: HttpContext
  }
): Observable<RiskscapeDolomiteDto> {

    return this.apiV1MapsGetDolomiteGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeDolomiteDto>) => r.body as RiskscapeDolomiteDto)
    );
  }

  /**
   * Path part for operation apiV1MapsGetBulkPropertyDataGet
   */
  static readonly ApiV1MapsGetBulkPropertyDataGetPath = '/api/v1/Maps/GetBulkPropertyData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetBulkPropertyDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetBulkPropertyDataGet$Response(params?: {
    subPlaceId?: string;
    neighborhoodId?: string;
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RiskscapeBulkPropertyDataData>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetBulkPropertyDataGetPath, 'get');
    if (params) {
      rb.query('subPlaceId', params.subPlaceId, {});
      rb.query('neighborhoodId', params.neighborhoodId, {});
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RiskscapeBulkPropertyDataData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetBulkPropertyDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetBulkPropertyDataGet(params?: {
    subPlaceId?: string;
    neighborhoodId?: string;
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<RiskscapeBulkPropertyDataData> {

    return this.apiV1MapsGetBulkPropertyDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RiskscapeBulkPropertyDataData>) => r.body as RiskscapeBulkPropertyDataData)
    );
  }

}
