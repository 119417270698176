<form id="ownershipDetailsForm" class="submission-form" #ownershipDetailsForm="ngForm" novalidate>
    <section>
        <div class="heading-2">
            STEP 1: OWNERSHIP DETAILS
        </div>
        <div class="paragraph">
            <b class="coral">
                IMPORTANT:
            </b>
            <li>
                To fill in this form you have to be logged in as the owner (or
                an owner) of the property.
            </li>
            <li>
                Separate properties (land parcels) will each require an
                individual, separate form.
            </li>
        </div>
        <div class="heading-5">
            <span class="burnt-red pr-1">|</span>
            <span>
                The property to be sold is registered in the name of:
            </span>
        </div>
        <mat-radio-group name="radioRegisteredInNameOf" [(ngModel)]="queryBody.propertyOwnershipType">
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of propertyOwnershipTypeDto"
                [value]="selectionValue.id" (change)="resetLegalEntityFields(selectionValue.value, selectionValue.id)">
                {{ selectionValue.value }}
            </mat-radio-button>
        </mat-radio-group>
        <div class="pb-3" *ngIf="propertyOwnershipTypeValue == 'Natural person(s)' ||
                propertyOwnershipTypeValue == 'Both natural person(s) and juristic person(s)'">
            <div class="heading-3">
                <span class="burnt-red pr-1">|</span>
                <span>
                    Details of Natural Person(s)
                </span>
            </div>
            <div class="col-12 add-button-container">
                <span class="center-content paragraph">
                    Record details of all property owners (natural persons), as
                    per the title deed of your property:
                </span>
                <button (click)="openAddNaturalPersonDialog()" class="center-content add-button">
                    <mat-icon class="add-button-icon">add</mat-icon>
                    <span class="bold paragraph add-button-label">
                        Add Owner/Co-Owner
                    </span>
                </button>
            </div>
            <div class="col-12">
                <table mat-table class="table-style" [dataSource]="queryBody.ownershipPersonalDetail"
                    #ownershipDetailsTable>
                    <ng-container matColumnDef="number">
                        <mat-header-cell class="table-header-styling " mat-header-cell *matHeaderCellDef>
                            NUMBER
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.orderNumber }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef>
                            NAME
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.fullNames }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="spouse">
                        <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef>
                            SPOUSE (IN COMMUNITY)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.spouseDetail.fullNames }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <mat-header-cell class="table-header-styling center-table-controllers" mat-header-cell
                            *matHeaderCellDef>
                            EDIT
                        </mat-header-cell>
                        <mat-cell class="center-table-controllers" *matCellDef="let element">
                            <button mat-menu-item class="center-content" (click)="openEditNaturalPersonDialog(element)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <mat-header-cell class="table-header-styling center-table-controllers" *matHeaderCellDef>
                            DELETE
                        </mat-header-cell>
                        <mat-cell class="center-table-controllers" *matCellDef="let element">
                            <button mat-menu-item class="center-content" (click)="deleteNaturalPerson(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumnsNaturalPerson">
                    </mat-header-row>
                    <mat-row mat-row *matRowDef="let row; columns: displayedColumnsNaturalPerson;">
                    </mat-row>
                </table>
            </div>
        </div>
        <div class="pb-3" *ngIf="propertyOwnershipTypeValue == 'Juristic person(s) - (company, cc, NPO, trust, etc.)' ||
                propertyOwnershipTypeValue == 'Both natural person(s) and juristic person(s)'">
            <div class="heading-3">
                <span class="burnt-red pr-1">|</span>
                <span>
                    Details of Juristic Person(s)
                </span>
            </div>
            <div class="col-12 add-button-container">
                <span class="center-content paragraph">
                    Record details of all property owners (juristic
                    persons/entities), as per the title deed of your property:
                </span>
                <button (click)="openAddJuristicPersonDialog()" class="center-content add-button">
                    <mat-icon class="add-button-icon">add</mat-icon>
                    <span class="bold paragraph add-button-label">
                        Add Juristic Person/Entity
                    </span>
                </button>
            </div>
            <div class="col-12">
                <table mat-table class="table-style" [dataSource]="queryBody.juristicPersonDetail"
                    #juristicPersonsTable>
                    <ng-container matColumnDef="number">
                        <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef>
                            NUMBER
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.orderNumber }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef>
                            NAME OF ENTITY
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.fullName }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="spouse">
                        <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef>
                            REPRESENTATIVE
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.legalRepresentative.fullNames }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <mat-header-cell class="table-header-styling center-table-controllers" mat-header-cell
                            *matHeaderCellDef>
                            EDIT
                        </mat-header-cell>
                        <mat-cell class="center-table-controllers" *matCellDef="let element">
                            <button mat-menu-item class="center-content"
                                (click)="openEditJuristicPersonDialog(element)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <mat-header-cell class="table-header-styling center-table-controllers" mat-header-cell
                            *matHeaderCellDef>
                            DELETE
                        </mat-header-cell>
                        <mat-cell class="center-table-controllers" *matCellDef="let element">
                            <button mat-menu-item class="center-content" (click)="deleteJuristicPerson(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumnsNaturalPerson">
                    </mat-header-row>
                    <mat-row mat-row *matRowDef="let row; columns: displayedColumnsNaturalPerson;">
                    </mat-row>
                </table>
            </div>
        </div>
        <div *ngIf="propertyOwnershipTypeValue == 'Juristic person(s) - (company, cc, NPO, trust, etc.)' ||
                propertyOwnershipTypeValue == 'Both natural person(s) and juristic person(s)' ||
                propertyOwnershipTypeValue == 'Natural person(s)'">
            <div class="heading-5 pb-3">
                <span class="burnt-red pr-1">|</span>
                <span>
                    Your nominated physical address for the purpose of receiving
                    legal documents and notices (domicilium citandi et executandi):
                </span>
            </div>
            <div class="row pb-3">
                <div class="col-4">
                    <label id="lblOrganizationBuildingNumber" for="txtOrganizationBuildingNumber" class="paragraph">
                        Building/Unit Number:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" id="txtOrganizationBuildingNumber" name="organizationBuildingNumber"
                        maxlength="10" [(ngModel)]="queryBody.buildingAddressDetail.unitNumber"
                        class="paragraph not-required">
                </div>
            </div>
            <div class="row propertyDetailsBoundaryInput pb-3">
                <div class="col-4">
                    <label id="lblOrganizationComplexOrBuilding" for="txtOrganizationComplexOrBuilding"
                        class="paragraph">
                        Complex/Building:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" [(ngModel)]="queryBody.buildingAddressDetail.complexName"
                        id="txtOrganizationComplexOrBuilding" name="organizationComplexOrBuilding" maxlength="50"
                        class="paragraph not-required">
                </div>
            </div>
            <div class="row propertyDetailsBoundaryInput pb-3">
                <div class="col-4">
                    <label id="lblOrganizationStreetNumber" for="txtOrganizationStreetNumber" class="paragraph">
                        Street Number:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" id="txtOrganizationStreetNumber" required
                        [(ngModel)]="queryBody.buildingAddressDetail.streetNumber" name="organizationStreetNumber"
                        #organizationStreetNumber="ngModel" maxlength="10"
                        [ngClass]="{'invalid-input': organizationStreetNumber.invalid, 'valid-input': organizationStreetNumber.valid}"
                        class="paragraph required">
                </div>
            </div>
            <div class="row propertyDetailsBoundaryInput pb-3">
                <div class="col-4">
                    <label id="lblIndividualInvoiceName" for="txtOrganizationStreetName" class="paragraph">
                        Street Name:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" id="txtOrganizationStreetName" name="organizationStreetName"
                        #organizationStreetName="ngModel" required
                        [(ngModel)]="queryBody.buildingAddressDetail.streetName" maxlength="50"
                        [ngClass]="{'invalid-input': organizationStreetName.invalid, 'valid-input': organizationStreetName.valid}"
                        class="paragraph required">
                </div>
            </div>
            <div class="row propertyDetailsBoundaryInput pb-3">
                <div class="col-4">
                    <label id="lblOrganizationSuburb" for="txtOrganizationSuburb" class="paragraph">
                        Suburb:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" id="txtOrganizationSuburb" name="organizationSuburb"
                        #organizationSuburb="ngModel" required class="paragraph required" maxlength="50"
                        [(ngModel)]="queryBody.buildingAddressDetail.suburb"
                        [ngClass]="{'invalid-input': organizationSuburb.invalid, 'valid-input': organizationSuburb.valid}">
                </div>
            </div>
            <div class="row propertyDetailsBoundaryInput pb-3">
                <div class="col-4">
                    <label id="lblOrganizationCity" for="txtOrganizationCity" class="paragraph">
                        City:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" id="txtOrganizationCity" name="organizationCity" #organizationCity="ngModel"
                        required class="paragraph required" [(ngModel)]="queryBody.buildingAddressDetail.city"
                        maxlength="50"
                        [ngClass]="{'invalid-input': organizationCity.invalid, 'valid-input': organizationCity.valid}">
                </div>
            </div>
            <div class="row text-center mt-3 mb-3">
                <div class="col-12">
                    <button id="btnSaveFinal" class="button submit-button" [disabled]="ownershipDetailsForm.invalid"
                        (click)="submitForm()">
                        SUBMIT
                    </button>
                </div>
            </div>
        </div>
    </section>
</form>