import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PropertyForSaleOfferToPurchaseWithConditionsComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-with-conditions/property-for-sale-offer-to-purchase-with-conditions.component';
import { TownPlanningApplicationRequestComponent } from './pages/town-planning-applications/town-planning-applications-request/town-planning-application-request/town-planning-application-request.component';
import { PropertyForSaleOfferToPurchaseFormEndComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form-end/property-for-sale-offer-to-purchase-form-end.component';
import { ConveyancerPersonalCompanyDetailsComponent } from './pages/register/conveyancer/option/option_B/conveyancer-personal-company-details/conveyancer-personal-company-details.component';
import { PropertyForSaleOfferToPurchaseFormComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-offer-to-purchase-form.component';
import { FeasibilityStudiesRequestComponent } from './pages/feasibility-studies/feasibility-studies-request/feasibility-studies-request/feasibility-studies-request.component';
import { PropertyValuationRequestComponent } from './pages/property-valuations/property-valuation-request/property-valuation-request/property-valuation-request.component';
import { PropertyForSaleOfferToPurchaseComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase/property-for-sale-offer-to-purchase.component';
import { ConveyancerPersonalInfoComponent } from './pages/register/conveyancer/option/option_B/steps/conveyancer-personal-info/conveyancer-personal-info.component';
import { ConveyancerRegistrationComponent } from './pages/register/conveyancer/registration/conveyancer-registration/conveyancer-registration.component';
import { ConveyancerRegisterDetailsComponent } from './pages/register/conveyancer/conveyancer-register-details/conveyancer-register-details.component';
import { EstateAgentRegisterFormComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-register-form.component';
import { EstateAgentRegistrationComponent } from './pages/register/estate-agent/estate-agent-registration/estate-agent-registration.component';
import { ConveyancerRegisterFormComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-register-form.component';
import { PlannerRegisterFormComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form.component';
import { PlannerRegistrationComponent } from './pages/register/planner/registration/planner-registration/planner-registration.component';
import { EstateAgentAgreementComponent } from './pages/register/estate-agent/estate-agent-agreement/estate-agent-agreement.component';
import { Level1InvestigationsComponent } from './pages/property-investigations/level1-investigations/level1-investigations.component';
import { Level2InvestigationsComponent } from './pages/property-investigations/level2-investigations/level2-investigations.component';
import { Level3InvestigationsComponent } from './pages/property-investigations/level3-investigations/level3-investigations.component';
import { ArchitecturalTechnologistComponent } from './pages/register/architectural-technologist/architectural-technologist.component';
import { EstateAgentProcessesComponent } from './pages/register/estate-agent/estate-agent-processes/estate-agent-processes.component';
import { ConveyancerOptionComponent } from './pages/register/conveyancer/option/conveyancer-option/conveyancer-option.component';
import { SubmissionCompletedComponent } from './pages/selling-my-property/submission-completed/submission-completed.component';
import { TownPlanningApplicationsComponent } from './pages/town-planning-applications/town-planning-applications.component';
import { ListingOverviewComponent } from './pages/admin-portal/admin-listings/listing-overview/listing-overview.component';
import { ValuerRegisterFormComponent } from './pages/register/valuer/valuer-register-form/valuer-register-form.component';
import { OwnershipDetailsComponent } from './pages/selling-my-property/ownership-details/ownership-details.component';
import { PropertyInvestigationsComponent } from './pages/property-investigations/property-investigations.component';
import { CollaborationAgreementComponent } from './pages/collaboration-agreement/collaboration-agreement.component';
import { PropertyListingComponent } from './pages/selling-my-property/property-listing/property-listing.component';
import { ContactEstateAgentComponent } from './pages/contact/contact-estate-agent/contact-estate-agent.component';
import { ContactTownPlannerComponent } from './pages/contact/contact-town-planner/contact-town-planner.component';
import { TaskOverviewComponent } from './pages/admin-portal/admin-listings/task-overview/task-overview.component';
import { ShareInOurDatabaseComponent } from './pages/share-in-our-database/share-in-our-database.component';
import { RegisterValuerComponent } from './pages/register/valuer/register-valuer/register-valuer.component';
import { OneTimePassCodeComponent } from './pages/register/one-time-pass-code/one-time-pass-code.component';
import { AdminMyProfileComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile.component';
import { PropertyFormComponent } from './pages/selling-my-property/property-form/property-form.component';
import { SiodConfirmComponent } from './pages/share-in-our-database/siod-confirm/siod-confirm.component';
import { FeasibilityStudiesComponent } from './pages/feasibility-studies/feasibility-studies.component';
import { PropertyValuationsComponent } from './pages/property-valuations/property-valuations.component';
import { RegisterChoicesComponent } from './pages/register/register-choices/register-choices.component';
import { SellingMyPropertyComponent } from './pages/selling-my-property/selling-my-property.component';
import { MarketGapAnalysisComponent } from './pages/market-gap-analysis/market-gap-analysis.component';
import { AdminListingsComponent } from './pages/admin-portal/admin-listings/admin-listings.component';
import { BondOriginatorComponent } from './pages/register/bond-originator/bond-originator.component';
import { OptionAComponent } from './pages/register/conveyancer/option/option-a/option-a.component';
import { ValuerStepsComponent } from './pages/register/valuer/valuer-steps/valuer-steps.component';
import { PropertyForSaleComponent } from './pages/property-for-sale/property-for-sale.component';
import { SiodFormComponent } from './pages/share-in-our-database/siod-form/siod-form.component';
import { SiodAddComponent } from './pages/share-in-our-database/siod-add/siod-add.component';
import { AdminUsersComponent } from './pages/admin-portal/admin-users/admin-users.component';
import { EstateAgentComponent } from './pages/register/estate-agent/estate-agent.component';
import { LoginValuersComponent } from './pages/login/login-valuers/login-valuers.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { AdminHomeComponent } from './pages/admin-portal/admin-home/admin-home.component';
import { GetInTouchComponent } from './pages/contact/get-in-touch/get-in-touch.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ContactUsComponent } from './pages/contact/contact-us/contact-us.component';
import { PropertyMatrixSharedService } from './property-matrix-shared.service';
import { PlannerComponent } from './pages/register/planner/planner.component';
import { ValuerComponent } from './pages/register/valuer/valuer.component';
import { RegisterComponent } from './pages/register/register.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { MapComponent } from './map/map.component';
import { AuthGuard } from './guards/auth-guard';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: HomeComponent },
      { path: 'ownership-details', component: OwnershipDetailsComponent },
      { path: 'login', component: LoginComponent },
      { path: 'login-valuers', component: LoginValuersComponent },
      { path: 'login-conveyancer', component: OptionAComponent },
      { path: 'register-valuer', component: RegisterValuerComponent },
      { path: 'valuer-steps', component: ValuerStepsComponent },
      { path: 'valuer-register-form', component: ValuerRegisterFormComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'register/choice', component: RegisterChoicesComponent },
      { path: 'bond-originator', component: BondOriginatorComponent },
      { path: 'architectural-technologist', component: ArchitecturalTechnologistComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'share-in-our-database', component: ShareInOurDatabaseComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'selling-my-property', component: SellingMyPropertyComponent },
      { path: 'property-listing', component: PropertyListingComponent },
      { path: 'additional-services/property-investigation', component: PropertyInvestigationsComponent },
      { path: 'additional-services/property-valuation', component: PropertyValuationsComponent },
      { path: 'additional-services/property-valuation/property-valuation-request', component: PropertyValuationRequestComponent },
      { path: 'additional-services/feasibility-studies', component: FeasibilityStudiesComponent },
      { path: 'additional-services/feasibility-studies/feasibility-studies-request', component: FeasibilityStudiesRequestComponent },
      { path: 'market-gap-analysis', component: MarketGapAnalysisComponent },
      { path: 'additional-services/town-planning-application', component: TownPlanningApplicationsComponent },
      { path: 'additional-services/town-planning-application/town-planning-application-request', component: TownPlanningApplicationRequestComponent },
      { path: 'additional-services/property-investigation/level-1-investigation', component: Level1InvestigationsComponent },
      { path: 'additional-services/property-investigation/level-2-investigation', component: Level2InvestigationsComponent },
      { path: 'additional-services/property-investigation/level-3-investigation', component: Level3InvestigationsComponent },
      { path: 'planner-register', component: PlannerComponent },
      { path: 'estate-agent-option', component: EstateAgentComponent },
      { path: 'estate-agent-agreement', component: EstateAgentAgreementComponent },
      { path: 'estate-agent-processes', component: EstateAgentProcessesComponent },
      { path: 'estate-agent-registration', component: EstateAgentRegistrationComponent },
      { path: 'estate-agent-register-form', component: EstateAgentRegisterFormComponent },
      { path: 'one-time-pass-code', component: OneTimePassCodeComponent },
      { path: 'conveyancer-option', component: ConveyancerOptionComponent },
      { path: 'conveyancer-registration', component: ConveyancerRegistrationComponent },
      { path: 'conveyancer-personal-info', component: ConveyancerPersonalInfoComponent },
      { path: 'conveyancer-personal-company-detail', component: ConveyancerPersonalCompanyDetailsComponent },
      { path: 'conveyancer-register-details', component: ConveyancerRegisterDetailsComponent },
      { path: 'conveyancer-register-form', component: ConveyancerRegisterFormComponent },
      { path: 'valuer-registration', component: ValuerComponent },
      { path: 'planner-registration', component: PlannerRegistrationComponent },
      { path: 'collaboration-agreement', component: CollaborationAgreementComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'get-in-touch', component: GetInTouchComponent },
      { path: 'planner-register-form', component: PlannerRegisterFormComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'selling-my-property/capture', canActivate: [AuthGuard], component: PropertyFormComponent },
      { path: 'selling-my-property/capture/finished', canActivate: [AuthGuard], component: SubmissionCompletedComponent },
      { path: 'map-test', component: MapComponent },
      { path: 'share-in-our-database/capture', component: SiodFormComponent },
      { path: 'share-in-our-database/confirm', component: SiodConfirmComponent },
      { path: 'share-in-our-database/add', component: SiodAddComponent },
      { path: 'property-for-sale', component: PropertyForSaleComponent },
      { path: 'contact-estate-agent', component: ContactEstateAgentComponent },
      { path: 'contact-town-planner', component: ContactTownPlannerComponent },
      { path: 'property-for-sale/offer-to-purchase', component: PropertyForSaleOfferToPurchaseComponent },
      { path: 'property-for-sale/offer-to-purchase-with-conditions', component: PropertyForSaleOfferToPurchaseWithConditionsComponent },
      { path: 'property-for-sale/offer-to-purchase/capture', component: PropertyForSaleOfferToPurchaseFormComponent },
      { path: 'property-for-sale/offer-to-purchase/capture/finish', component: PropertyForSaleOfferToPurchaseFormEndComponent },
      { path: 'admin/home', canActivate: [AuthGuard], component: AdminHomeComponent },
      { path: 'admin/listings', canActivate: [AuthGuard], component: AdminListingsComponent },
      { path: 'admin/listings/listing-overview', canActivate: [AuthGuard], component: ListingOverviewComponent },
      { path: 'admin/listings/task-overview', canActivate: [AuthGuard], component: TaskOverviewComponent },
      { path: 'admin/my-profile', canActivate: [AuthGuard], component: AdminMyProfileComponent },
      { path: 'admin/users', canActivate: [AuthGuard], component: AdminUsersComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    PropertyMatrixSharedService
  ]
})
export class AppRoutingModule { }