import { Component, OnInit, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

import { UnitSelectionDialogComponent } from './unit-selection-dialog/unit-selection-dialog.component';

declare var RiskscapeMap: any;
declare var RiskscapeSearch: any;

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss']
})
export class MapSearchComponent implements OnInit, AfterViewInit {

  @Output() selectedProperty: EventEmitter<any> = new EventEmitter();
  @Output() selectedUnitNumber: EventEmitter<any> = new EventEmitter();
  @Output() scriptLoadStatus: EventEmitter<string> = new EventEmitter();

  private apiKey: string = environment.riskscapeApiKey;
  private map: any;
  private search: any;

  defaultUnitNumber: string = '00000';

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadScripts().then(() => {
      this.initMapAndSearch();
    }).catch(_error => {
      this.scriptLoadStatus.emit('Please refresh the page or try again later.');
    });
  }

  ngAfterViewInit(): void { }

  private loadScripts(): Promise<void[]> {
    const scripts = [
      'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js',
      'https://unpkg.com/riskscape-map@0.4.0/dist/map.umd.js',
      'https://unpkg.com/riskscape-search@0.4.0/dist/search.umd.js'
    ];

    return Promise.all(scripts.map(src => this.loadScript(src)));
  }

  private loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject();
      document.body.appendChild(script);
    });
  }

  private initMapAndSearch(): void {
    const mapOptions = JSON.parse(localStorage.getItem('map')) || { maxZoom: 18 };

    this.map = new RiskscapeMap({
      key: this.apiKey,
      element: 'map',
      options: mapOptions,
    });

    this.search = new RiskscapeSearch({
      key: this.apiKey,
      element: 'search'
    });

    this.map.bindSearch(this.search);

    this.map.on('zoomend', () => {
      const data = {
        zoom: this.map.getZoom(),
        center: this.map.getCenter()
      };
      localStorage.setItem('map', JSON.stringify(data));
    });

    this.map.on('click', (event: any) => {
      if (event.originalEvent.ctrlKey) {
        this.map.selectFeature('property_scheme', event.latlng);
      }
    });

    this.map.on('rs:feature:select', async ({ feature }: any) => {
      const hasUnits = feature.is_sectional_scheme || feature.has_sectional_units;

      if (hasUnits) {
        const units = feature.type === 'scheme'
          ? await this.map.getSchemeUnits(feature.scheme_id)
          : await this.map.getPropertyUnits(feature.property_key);

        this.openUnitSelectionDialog(units, feature.property_key);
        return;
      }

      this.showConfirmProperty(feature.property_key, this.defaultUnitNumber);
    });

    this.search.on('error', (error: any) => {
      console.error('Error occurred:', error);
      setTimeout(() => {
        this.selectedProperty.emit(null);
      }, 2500);
    }).on('select', (result: any) => {
      if (result && result.position) {
        this.handleSearchSelection(result);
      } else {
        setTimeout(() => {
          this.selectedProperty.emit(null);
        }, 2500);
      }
    });
  }

  private showConfirmProperty(property_key: string, unit_number: string): void {
    this.map.getProperty(property_key, unit_number).then((data: any) => {
      this.selectedProperty.emit(data);
      this.selectedUnitNumber.emit(unit_number);
    });
  }

  private handleSearchSelection(result: any): void {
    const { lat, lon } = result.position;

    if (['StreetAddress', 'Scheme'].includes(result.category)) {
      this.showConfirmProperty(result.property_key, this.defaultUnitNumber);
      return;
    }

    this.map.setView([lat, lon], 15);
  }

  private openUnitSelectionDialog(units: any[], property_key: string): void {
    const dialogRef = this.dialog.open(UnitSelectionDialogComponent, {
      width: '20vw',
      data: { units }
    });

    dialogRef.afterClosed().subscribe((selectedUnitNumber: any) => {
      if (selectedUnitNumber) {
        this.showConfirmProperty(property_key, selectedUnitNumber);
      }
    });
  }
}
