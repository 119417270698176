import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServitudeDto } from 'src/app/services/property-matrixV2/models';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-servitude',
  templateUrl: './servitude.component.html',
  styleUrls: ['./servitude.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ServitudeComponent implements OnInit {

  @Input() addendumTwoId: string;
  @Output() servitudeChange = new EventEmitter<ServitudeDto[]>();

  servitudeData: ServitudeDto[] = [];
  maxServitudes: number = 9;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadServitudeDetails();
  }

  addItemToArray(array: any[], item: any): void {
    array.push(item);
    this.emitChanges();
  }

  removeItemFromArray(array: any[], index: number): void {
    if (index >= 0 && index < array.length) {
      array.splice(index, 1);
      this.emitChanges();
    }
  }

  createServitude(): void {
    const newServitude: ServitudeDto = {
      id: uuidv4(),
      description: ''
    };
    this.addItemToArray(this.servitudeData, newServitude);
  }

  addServitude(): void {
    if (this.servitudeData.length < this.maxServitudes) {
      this.createServitude();
    } else {
      this.notificationService.showWarningMessage('Servitude Limit Reached', 'You cannot add more than ' + this.maxServitudes + ' servitudes.');
    }
  }

  async removeServitude(index: number): Promise<void> {
    const confirmed = await this.notificationService.showConfirmMessage(
      'Confirm Deletion',
      'Are you sure you want to remove this servitude? This action cannot be undone.',
      'Remove',
      'Cancel'
    );

    if (!confirmed) {
      return;
    }

    this.removeItemFromArray(this.servitudeData, index);
  }

  loadServitudeDetails(): void {
    this.planningReportService.apiV1PlanningReportGetServitudesGet({
      addendumTwoId: this.addendumTwoId
    }).subscribe({
      next: async (response) => {
        this.servitudeData = response;
        if (this.servitudeData === null || this.servitudeData.length === 0) {
          this.createServitude();
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load servitudes.');
      }
    });
  }

  emitChanges(): void {
    this.servitudeChange.emit(this.servitudeData);
  }
}
