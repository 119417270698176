import { FileManagementService } from 'src/app/services/property-matrixV2/services';
import { FooterDocumentDto } from 'src/app/services/property-matrixV2/models';
import { Component, OnInit } from '@angular/core';

import { ReportingService } from '../../services/reporting-service/reporting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerDocuments: FooterDocumentDto[] = [];
  termsAndConditions: FooterDocumentDto;
  privacyPolicy: FooterDocumentDto;
  cookiePreferences: FooterDocumentDto;
  paiaManual: FooterDocumentDto;
  legalDisclaimer: FooterDocumentDto;

  constructor(
    private reportingService: ReportingService,
    private fileManagementService: FileManagementService
  ) { }

  ngOnInit(): void {
    this.getFooterDocuments();
  }

  async getFooterDocuments(): Promise<void> {
    this.fileManagementService.apiV1FileManagementGetFooterDocumentsGet().subscribe({
      next: (result: FooterDocumentDto[]) => {
        this.footerDocuments = result;
        this.termsAndConditions = this.footerDocuments.find(x => x.name === 'Terms & Conditions.pdf');
        this.privacyPolicy = this.footerDocuments.find(x => x.name === 'Privacy Policy.pdf');
        this.cookiePreferences = this.footerDocuments.find(x => x.name === 'Cookie Preferences.pdf');
        this.paiaManual = this.footerDocuments.find(x => x.name === 'PAIA Manual.pdf');
        this.legalDisclaimer = this.footerDocuments.find(x => x.name === 'Legal Disclaimer.pdf');
      },
      error: (_error) => { }
    });
  }

  async onLinkClick(linkName: string): Promise<void> {
    let fileUrl: string | null = null;

    if (linkName === 'Terms & Conditions') {
      fileUrl = await this.reportingService.getFileUrl(this.termsAndConditions?.documentInfoId);
    } else if (linkName === 'Privacy Policy') {
      fileUrl = await this.reportingService.getFileUrl(this.privacyPolicy?.documentInfoId);
    } else if (linkName === 'Cookie Preferences') {
      fileUrl = await this.reportingService.getFileUrl(this.cookiePreferences?.documentInfoId);
    } else if (linkName === 'PAIA Manual') {
      fileUrl = await this.reportingService.getFileUrl(this.paiaManual?.documentInfoId);
    } else if (linkName === 'Legal Disclaimer') {
      fileUrl = await this.reportingService.getFileUrl(this.legalDisclaimer?.documentInfoId);
    }

    if (fileUrl) {
      window.location.href = fileUrl;
    }
  }
}
