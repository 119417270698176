<div class="form-group">
    <div class="col-12 mt-3" *ngFor="let improvementOption of improvementOptionData; let i = index">
        <button *ngIf="i > 0" (click)="removeImprovementOption(i)" type="button" class="mat-icon-button circle-button">
            <mat-icon>remove</mat-icon>
        </button>
        <mat-card class="mt-3">
            <div class="form-group">
                <div class="heading-3 mb-3">
                    Option {{ i + 1 | numberToWords }}
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Possible Development/Elevation</label>
                        <textarea class="col-12 mt-1 text-area"
                            [(ngModel)]="improvementOption.possibleDevelopmentEnhancement"
                            (ngModelChange)="emitChanges()"
                            placeholder="Retain existing house and subdivide into full title property, creating 7 additional properties.">
                        </textarea>
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Planning Application</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="improvementOption.landUseApplication"
                            (ngModelChange)="emitChanges()" placeholder="Subdivision & Rezoning">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Time-Frame (±)</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="improvementOption.timeFrame"
                            (ngModelChange)="emitChanges()" placeholder="12 Months">
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-12 mt-3 center-content paragraph">
        <button (click)="addImprovementOption()" class="center-content add-button">
            <mat-icon class="circle-button">add</mat-icon>
            <span class="bold add-button-label">Add Option</span>
        </button>
    </div>
</div>