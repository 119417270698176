<div class="form-group">
    <div class="col-12 mt-3" *ngFor="let spatialFramework of spatialFrameworkAndPolicyData; let i = index">
        <button *ngIf="i > 0" (click)="removeSpatialFramework(i)" type="button" class="mat-icon-button circle-button">
            <mat-icon>remove</mat-icon>
        </button>
        <mat-card class="mt-3">
            <div class="form-group">
                <div class="heading-3 mb-3">
                    Spatial Framework {{ i + 1 | numberToWords }}
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Framework Name</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="spatialFramework.name"
                            placeholder="Regional Spatial Development Framework (RSDF)" (ngModelChange)="emitChanges()">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Interpretation</label>
                        <textarea class="col-12 mt-1 text-area" [(ngModel)]="spatialFramework.description"
                            placeholder="The Spatial Development Framework (SDF) is a policy that guides planning and future land development within the municipality."
                            (ngModelChange)="emitChanges()">
                        </textarea>
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="upload-background">
                            <div class="upload-block" appDragAndDrop>
                                <input type="file" (load)="loadFile(i)" (change)="setFile($event, i)"
                                    class="upload-block-inner" multiple accept="image/*" id="input" placeholder="image">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-icon class="file-icon">cloud_upload</mat-icon>
                                    </div>
                                    <div class="col-12">
                                        Click to upload.
                                    </div>
                                </div>
                                <div class="row" *ngIf="fileUrls[i]">
                                    <div class="col-12">
                                        <div class="image-upload">
                                            <img [src]="fileUrls[i]" alt="Image Preview" id="preview"
                                                class="upload-block-inner">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="hideButtons">
                    <div class="col-12 button-group mt-3 mb-3">
                        <a class="footer-button background-charcoal text-center" [href]="fileUrl"
                            [download]="file?.name">
                            Download
                        </a>
                        <button type="button" class="footer-button background-coral" (click)="replace()">
                            Replace
                        </button>
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Figure Name</label>
                        <input class="col-12 mt-1" type="text" [(ngModel)]="spatialFramework.imageName"
                            placeholder="Spatial Development Framework" (ngModelChange)="emitChanges()">
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-12 mt-3 center-content paragraph">
        <button (click)="addSpatialFramework()" class="center-content add-button">
            <mat-icon class="circle-button">add</mat-icon>
            <span class="bold add-button-label">Add figure</span>
        </button>
    </div>
</div>