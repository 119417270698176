import { NotAvailableDialogComponent } from 'src/app/shared/components/not-available-dialog/not-available-dialog.component';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-property-investigations',
  templateUrl: './property-investigations.component.html',
  styleUrls: ['./property-investigations.component.scss']
})
export class PropertyInvestigationsComponent implements AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  tabIndex: number = -1;

  open: boolean = false;
  isShow: boolean = true;
  isMenuOpen: boolean = false;
  showLevelOne: boolean = false;
  showLevelTwo: boolean = false;
  showLevelThree: boolean = false;
  addOverflow: boolean = false;

  constructor(public dialog: MatDialog) { }

  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    this.dialog.open(NotAvailableDialogComponent);
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
    this.addOverflow = false
  }

  openLearnMoreOne(index: number) {
    this.open = true;
    this.isShow = false;
    this.showLevelOne = true;
    this.showLevelTwo = false;
    this.showLevelThree = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }

  openLearnMoreTwo(index: number) {
    this.open = true;
    this.isShow = false;
    this.showLevelTwo = true;
    this.showLevelOne = false;
    this.showLevelThree = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }

  openLearnMoreThree(index: number) {
    this.open = true;
    this.isShow = false;
    this.showLevelThree = true;
    this.showLevelTwo = false;
    this.showLevelOne = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }
}
