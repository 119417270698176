<main>
    <div *ngIf="!adminActive" class="main-navbar-colors">
        <div *ngIf="router.url === '/'" id="logo-top" class="text-center">
            <img class="logo mt-3" src="/assets/images/home/logo.png">
        </div>
        <nav class="navbar main-navbar">
            <div class="nav-container">
                <button mat-button class="menu-toggle" (click)="toggleMainMenu()" *ngIf="isSmallScreenMain">
                    <mat-icon class="main-menu-light material-icons-outlined">menu_open</mat-icon>
                </button>
                <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <a class="nav-link heading-3-light" routerLink="/" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }">
                        HOME
                    </a>
                </div>
                <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <a class="nav-link heading-3-light" routerLink="/about-us" routerLinkActive="active">
                        ABOUT US
                    </a>
                </div>
                <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <a class="nav-link heading-3-light" routerLink="/property-for-sale" routerLinkActive="active">
                        PROPERTY FOR SALE
                    </a>
                </div>
                <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <a class="nav-link heading-3-light" routerLink="/selling-my-property" routerLinkActive="active">
                        SELL YOUR PROPERTY
                    </a>
                </div>
                <!-- TODO: Implement logic to switch between "Agent Listing" & "Seller Listing" pages based on the role of the logged in user -->
                <!-- <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <a class="nav-link heading-3-light" routerLink="/property-listing" routerLinkActive="active">
                        SELL YOUR PROPERTY
                    </a>
                </div> -->
                <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <a class="nav-link heading-3-light" routerLink="/share-in-our-database" routerLinkActive="active">
                        PLACE YOUR ORDER
                    </a>
                </div>
                <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <a class="nav-link heading-3-light" routerLink="/additional-services" routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: false }" [matMenuTriggerFor]="additionalServicesMenu">
                        ADDITIONAL SERVICES
                    </a>
                    <mat-menu #additionalServicesMenu="matMenu" class="additional-services-menu">
                        <button mat-menu-item routerLink="/additional-services/property-investigation">
                            <span>Property Investigations</span>
                        </button>
                        <button mat-menu-item routerLink="/additional-services/feasibility-studies">
                            <span>Feasibility Studies</span>
                        </button>
                        <button mat-menu-item routerLink="/additional-services/town-planning-application">
                            <span>Planning Applications</span>
                        </button>
                        <button mat-menu-item routerLink="/additional-services/property-valuation">
                            <span>Property Valuation</span>
                        </button>
                    </mat-menu>
                </div>
                <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <a class="nav-link heading-3-light" routerLink="/get-in-touch" routerLinkActive="active">
                        CONTACT US
                    </a>
                </div>
                <div class="m-2 nav-item" [class.hide]="isSmallScreenMain && !mainMenuOpen">
                    <button mat-button [matMenuTriggerFor]="mainMenu">
                        <mat-icon class="main-menu-light">menu</mat-icon>
                    </button>
                    <mat-menu #mainMenu="matMenu" class="main-menu">
                        <button mat-menu-item routerLinkActive="active" routerLink="/admin/home" href="admin/home"
                            (click)="enableAdminNav()">
                            <span>Admin Portal</span>
                        </button>
                        <button mat-menu-item (click)="goToRegister()">
                            <span>Register</span>
                        </button>
                        <button mat-menu-item *ngIf="!isLoggedIn" (click)="onClickLogin()">
                            <span>Log In</span>
                        </button>
                        <button mat-menu-item *ngIf="isLoggedIn" (click)="onClickLogout()">
                            <span>Log Out</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </nav>
    </div>
    <div *ngIf="adminActive" class="row admin-navbar-colors">
        <div class="company-title col">
            Property Matrix
        </div>
        <div class="col">
            <nav class="navbar admin-navbar">
                <div class="admin-nav-container">
                    <h2 class="m-2 nav-item" *ngIf="isSmallScreenAdmin">
                        Property Matrix
                    </h2>
                    <button mat-button class="menu-toggle" (click)="toggleAdminMenu()" *ngIf="isSmallScreenAdmin">
                        <mat-icon>menu_open</mat-icon>
                    </button>
                    <div class="m-2 nav-item" [class.hide]="isSmallScreenAdmin && !adminMenuOpen">
                        <a class="nav-link heading-3 admin-nav" routerLink="/admin/home" routerLinkActive="active">
                            HOME
                        </a>
                    </div>
                    <div class="m-2 nav-item" [class.hide]="isSmallScreenAdmin && !adminMenuOpen">
                        <a class="nav-link heading-3 admin-nav" routerLink="/admin/listings" routerLinkActive="active">
                            LISTINGS
                        </a>
                    </div>
                    <div class="m-2 nav-item" [class.hide]="isSmallScreenAdmin && !adminMenuOpen">
                        <a class="nav-link heading-3 admin-nav" routerLink="/contact-us" routerLinkActive="active">
                            ORDERS
                        </a>
                    </div>
                    <div class="m-2 nav-item" [class.hide]="isSmallScreenAdmin && !adminMenuOpen">
                        <a class="nav-link heading-3 admin-nav" routerLink="/admin/users" routerLinkActive="active">
                            USERS
                        </a>
                    </div>
                    <div class="m-2 nav-item" *ngIf="isLoggedIn" [class.hide]="isSmallScreenAdmin && !adminMenuOpen">
                        <button mat-button [matMenuTriggerFor]="adminMenu">
                            <mat-icon>menu</mat-icon>
                        </button>
                        <mat-menu #adminMenu="matMenu" class="admin-menu">
                            <button mat-menu-item routerLink="/" (click)="disableAdminNav()">
                                <span>Main Site</span>
                            </button>
                            <button mat-menu-item *ngIf="!isLoggedIn" (click)="onClickLogin()">
                                <span>Log In</span>
                            </button>
                            <button mat-menu-item *ngIf="isLoggedIn" (click)="onClickLogout()">
                                <span>Log Out</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</main>
<router-outlet></router-outlet>
<app-footer></app-footer>