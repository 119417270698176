<mat-sidenav-container (backdropClick)="close()" [hasBackdrop]="true">
    <main class="main-container">
        <div class="content">
            <div class="content-background"></div>
            <div class="content-inner">
                <div class="section-heading pb-3">
                    Property Investigations
                </div>
                <div class="section-text pb-5">
                    Our investigation studies will equip you with reliable information, enabling confident
                    decision-making.
                </div>
                <div class="section-sub-heading pb-3">
                    We offer three levels of property investigations:
                </div>
                <div class="levels-container">
                    <div class="level">
                        <div class="level-heading">
                            Level
                        </div>
                        <div class="level-number">
                            1
                        </div>
                        <div class="level-sub-heading">
                            Necessary to investigate:
                        </div>
                        <div class="list-items">
                            <ul class="paragraph">
                                <li>
                                    Property's existing rights and parameters (density, height).
                                </li>
                                <li>
                                    Property boundaries and possible servitudes.
                                </li>
                                <li>
                                    Permissibility of a specific use or business within existing rights.
                                </li>
                                <li>
                                    Favorable consideration of secondary right town planning applications (such as
                                    consent use and permission) and subdivisions.
                                </li>
                            </ul>
                        </div>
                        <div class="learn-more-button">
                            <button class="button" (click)="openLearnMoreOne(0)">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                    <div class="level">
                        <div class="level-heading">
                            Level
                        </div>
                        <div class="level-number">
                            2
                        </div>
                        <div class="level-sub-heading">
                            Necessary to investigate:
                        </div>
                        <div class="list-items">
                            <ul class="paragraph">
                                <li>
                                    Property's existing and potential rights (zoning, density, height).
                                </li>
                                <li>
                                    Proposed enhancement options and construction scope, according to municipal
                                    strategic plans and development policies.
                                </li>
                                <li>
                                    Optimal improvement/development potential of the property.
                                </li>
                                <li>
                                    Favorable consideration of planning applications.
                                </li>
                            </ul>
                        </div>
                        <div class="learn-more-button">
                            <button class="button" (click)="openLearnMoreTwo(0)">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                    <div class="level">
                        <div class="level-heading">
                            Level
                        </div>
                        <div class="level-number">
                            3
                        </div>
                        <div class="level-sub-heading">
                            Necessary to investigate:
                        </div>
                        <div class="list-items">
                            <ul class="paragraph">
                                <li>
                                    Property's existing and potential rights (zoning, density, height).
                                </li>
                                <li>
                                    Optimal building design and layout.
                                </li>
                                <li>
                                    Potential return on investment (ROI).
                                </li>
                                <li>
                                    Extended inputs from external professionals (engineers, architects, environmental
                                    experts, etc.) to address potential risks.
                                </li>
                                <li>
                                    Feasibility and market demand studies.
                                </li>
                            </ul>
                        </div>
                        <div class="learn-more-button">
                            <button class="button" (click)="openLearnMoreThree(0)">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <mat-sidenav #sidenav (keydown.escape)="close()" position="end">
        <section class="rotate-buttons" [hidden]="isShow">
            <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 0}"
                (click)="openLearnMoreOne(0)">
                LEVEL 1
            </button>
            <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 1}"
                (click)="openLearnMoreTwo(1)">
                LEVEL 2
            </button>
            <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 2}"
                (click)="openLearnMoreThree(2)">
                LEVEL 3
            </button>
        </section>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevelOne">
            <div class="row py-3">
                <div class="col-11 heading-1-light">
                    Level 1 Property Investigation
                </div>
                <div class="col-1 btn-close-align">
                    <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close">
                    </button>
                </div>
            </div>
            <div class="row pb-3">
                <div class="heading-2-light">
                    Property Matrix Offers The Following Services:
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Inspect:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Town Planning Scheme</li>
                        <li>Property's existing zoning and parameters</li>
                        <li>Surveyor-General Diagrams (confirming cadastral boundaries and possible servitudes)</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Review </span>
                    <span>(where information is applicable):</span>
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Site access: existing (and proposed) roads, bus routes and railway lines</li>
                        <li>Engineering services such as sewer, storm-water, electricity and water</li>
                        <li>Title deed - for possible restrictions/conditions</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Provide our interpretation on:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Allowable land uses within the existing rights</li>
                        <li>Construction scope within existing rights</li>
                        <li>Potential actions (subdivision, consent use applications, etc.)</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Give advice on:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Estimated costs and time-frames for planning applications.
                        </li>
                        <li>
                            Favorable consideration of secondary right town planning applications (such as consent use
                            and permission) and subdivisions.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-3">
                <div class="heading-2-light">
                    Order Summary:
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-5-light">
                    Receive a comprehensive report upon investigation completion:
                </div>
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Deliverable: </span>
                    <span>
                        8 to 12-page report. Click
                        <a href="#" class="here-light">here</a>
                        to view a sample report.
                    </span>
                </div>
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Time-frame: </span>
                    <span>1 - 5 working days</span>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Provisional Price structure (excl. VAT):
                </div>
                <ul class="no-bullets paragraph light">
                    <li>1 land parcel R3 000</li>
                    <li>2 - 5 land parcels R3 700</li>
                    <li>6 - 9 land parcels R4 300</li>
                    <li>10 - 19 land parcels R5 100</li>
                    <li>20 - 30 land parcels R7 400</li>
                    <li>31 - 100 land parcels R18 300</li>
                </ul>
            </div>
            <div class="row pb-4">
                <div class="col-12 heading-5-light">
                    Important
                </div>
                <div class="paragraph light">
                    The information offered is our professional opinion, based on a desktop study.
                </div>
                <div class="paragraph light">
                    Only <u>existing</u> land use rights are considered. Development/improvement scope based on
                    potential
                    rights form part of a <b>Level 2</b> investigation.
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-12 heading-2-light">
                    SPECIAL OFFER:
                </div>
                <div class="paragraph light">
                    If the study leads to a land use (planning) application for the rights proposed, the initial fee
                    will be deducted from the urban planner's professional fees. A comprehensive quotation can be
                    provided for detailed steps and payment schedules.
                </div>
            </div>
            <div class="row pb-3">
                <div class="center-content">
                    <button (click)="openDialog()" class="button button-light uppercase">
                        Order
                    </button>
                </div>
            </div>
        </div>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevelTwo">
            <div class="row py-3">
                <div class="col-11 heading-1-light">
                    Level 2 Property Investigation
                </div>
                <div class="col-1 btn-close-align">
                    <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close">
                    </button>
                </div>
            </div>
            <div class="row pb-3">
                <div class="heading-2-light">
                    Property Matrix Offers The Following Services:
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Inspect:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Property's existing and potential/proposed zoning and parameters</li>
                        <li>Town Planning Scheme and applicable bylaw</li>
                        <li>Surveyor-General Diagrams (confirming boundaries and servitudes)</li>
                        <li>Title deed - for possible restrictions/conditions</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Review </span>
                    <span>(where information is applicable):</span>
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Local municipality's strategic plans (IDP and SDF)
                        </li>
                        <li>
                            Precinct plan and development policies
                        </li>
                        <li>
                            Existing/proposed roads, bus and railway lines and engineering services (if information is
                            available)
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Consult and Confirm with Council:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Permissible and potential land uses and planning parameters</li>
                        <li>Possible restraints and parking requirements</li>
                        <li>Need for planning applications and related costs and time-frames</li>
                        <li>Existing and potential construction scope</li>
                        <li>Development potential - optimal approach to enhance the property’s value</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-3">
                <div class="heading-2-light">
                    Order Summary:
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-5-light">
                    Receive a comprehensive report upon investigation completion:
                </div>
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Deliverable: </span>
                    <span>
                        12-15 page report, including addendums. Click
                        <a href="#" class="here-light">here</a>
                        to view a sample report.
                    </span>
                </div>
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Time-frame: </span>
                    <span>1-2 weeks based on municipal feedback</span>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Provisional Price structure (excl. VAT):
                </div>
                <ul class="no-bullets paragraph light">
                    <li>1 land parcel R12 000</li>
                    <li>2 - 3 land parcels R13 000</li>
                    <li>6 - 9 land parcels R15 000</li>
                    <li>10 - 19 land parcels R17 000</li>
                    <li>20 - 30 land parcels R20 000</li>
                    <li>31 - 100 land parcels R50 000</li>
                </ul>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-2-light">
                    SPECIAL OFFER:
                </div>
                <div class="paragraph light">
                    If the study leads to a land use (planning) application for proposed rights/uses, the initial fee
                    will be deducted from urban planner fees.
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-12 heading-5-light">
                    Important
                </div>
                <div class="paragraph light">
                    The information offered is our professional opinion, based on a desktop study.
                </div>
                <div class="paragraph light">
                    Additional information from extended professionals (e.g. engineering, environmental or geotechnical
                    specialists) falls under our <b>Level 3</b> investigation, with an option of a feasibility study,
                    determining most viable development options and the expected return on investment (ROI). Level 3
                    investigations and fees are project-specific and contingent on the type of improvement/development.
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-12 heading-2-light">
                    SPECIAL OFFER:
                </div>
                <div class="paragraph light">
                    If the study leads to a land use (planning) application for the rights proposed, the initial fee
                    will be deducted from the urban planner's professional fees. A comprehensive quotation can be
                    provided for detailed steps and payment schedules.
                </div>
            </div>
            <div class="row pb-3">
                <div class="center-content">
                    <button (click)="openDialog()" class="button button-light uppercase">
                        Order
                    </button>
                </div>
            </div>
        </div>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevelThree">
            <div class="row py-3">
                <div class="col-11 heading-1-light">
                    Level 3 Property Investigation
                </div>
                <div class="col-1 btn-close-align">
                    <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close">
                    </button>
                </div>
            </div>
            <div class="row pb-3">
                <div class="heading-5-light">
                    Every site and every development opportunity is unique. Our Level 3 investigation will generally
                    include the following:
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Comprehensive property investigation:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Permissible and potential zoning</li>
                        <li>Boundaries, servitudes, constraints</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Market research:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Comparable sales, prices/sqm</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Design options:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Viable development scenarios</li>
                        <li>Architectural massing diagrams</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Development costs:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Detailed expense calculations</li>
                        <li>Return on investment (ROI) assessment</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Controls and calculations:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Rates per square meter</li>
                        <li>Net income, profit per opportunity</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Additional studies </span>
                    <span>(where applicable):</span>
                </div>
                <div class="col-12">
                    <ul class="no-bullets paragraph light">
                        <li>Engineers' reports, flood line studies, environmental assessments, access studies, etc.</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-3">
                <div class="heading-2-light">
                    Order Summary:
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Deliverable: </span>
                    <span>A 20-to-50-page report outlining property potential, market analysis and ROI.</span>
                </div>
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Time-frame: </span>
                    <span>4 - 6 weeks</span>
                </div>
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Fee: </span>
                    <span>R60k - R250k (quotation-based, depending on specific needs and project scope)</span>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-12 heading-5-light">
                    Important Note:
                </div>
                <div class="paragraph light">
                    For industries like hospitality, leisure, commercial, and retail, a socio-economic analysis is
                    recommended. This includes assessing the demographics, purchasing power, and spending habits of your
                    target market. These factors, combined with competitor analysis, determine the optimal size of your
                    development or business premises. For detailed insights, refer to our feasibility studies and market
                    gap analysis.
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-12 heading-2-light">
                    SPECIAL OFFER:
                </div>
                <div class="paragraph light">
                    If the study leads to a land use (planning) application for the rights proposed, the initial fee
                    will be deducted from the urban planner's professional fees. A comprehensive quotation can be
                    provided for detailed steps and payment schedules.
                </div>
            </div>
            <div class="row pb-3">
                <div class="center-content">
                    <button (click)="openDialog()" class="button button-light uppercase">
                        Order
                    </button>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>