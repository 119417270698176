<main class="background-image">
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <form id="registerForm" novalidate #registerForm="ngForm" class="submission-form">
                        <div class="row">
                            <div class="col-12">
                                <h1 class="heading-1 text-center planner-heading">
                                    OPPORTUNITY FOR SACPLAN-REGISTERED PLANNERS
                                </h1>
                            </div>
                            <div class="text-center text-size">
                                <p>
                                    Thank you for visiting our platform. <b>Property Matrix</b> is a national network of
                                    professional town planners, estate agents, property valuers, urban economists, bond
                                    originators, and conveyancers. Our operations cover Tshwane (Pretoria),
                                    Johannesburg, Ekurhuleni, Cape Town, and eThekwini (Durban). We invite estate
                                    agents, commercial brokers, and agencies in these areas to join our network.
                                </p>
                                <p>
                                    We facilitate connections between planners operating in these areas and clients in
                                    need of land use management (LUMS) expertise. Our process involves generating an
                                    initial planning report for a property, which is then sent to an "associate
                                    planner." This associate planner reviews, expands on, and approves the report.
                                </p>
                                <p>
                                    This report serves as a resource for <b>Property Matrix</b> clients, offering
                                    insights into the property's existing land use rights and, importantly, its
                                    development, expansion, or enhancement potential. The report also includes
                                    recommendations for necessary planning applications and estimated time-frames for
                                    realizing this potential. We commit to directing all statutory planning tasks
                                    stemming from such a property report to the appropriate associate planner.
                                </p>
                                <p>
                                    To partner as an "associate planner" with <b>Property Matrix</b>, you must be a
                                    SACPLAN registered professional town planner and hold a leadership role in an
                                    established consulting town planning firm, such as a director, partner, or senior
                                    planner.
                                </p>
                            </div>
                        </div>
                        <div class="heading-4">
                            Professional Details
                        </div>
                        <div class="d-flex">
                            <label for="SacPlan" class="sacplan-text sacplan-input">
                                Full SACPLAN registration number
                            </label>
                            <div>
                                <input type="text" class="form-control" id="SacPlan" name="SACPLAN" required
                                    [formControl]="sacPlanRegNumber">
                                <div *ngIf="sacPlanRegNumber.invalid && (sacPlanRegNumber.dirty || sacPlanRegNumber.touched)"
                                    class="sacplan-error-text">
                                    <div *ngIf="sacPlanRegNumber.errors.required">
                                        SACPLAN registration number is required
                                    </div>
                                    <div *ngIf="sacPlanRegNumber.errors.pattern">
                                        Invalid SACPLAN registration number format
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="heading-5">
                                <span class="font-weight-bold">
                                    Your SACPLAN registration type:
                                </span>
                            </div>
                            <mat-radio-group name="approximateBuildingAge"
                                [(ngModel)]="form.urbanPlanner.registrationType">
                                <mat-radio-button class="paragraph" *ngFor="let selectionValue of registrationTypes"
                                    [value]="selectionValue.id" (change)="selectRegistrationType(selectionValue.id)">
                                    {{ selectionValue.value }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <p *ngIf="isProfessional == false" class="text-size">
                            <b>Please note:</b> Only professional, senior planners - registered with SACPLAN - can
                            partner with Property Matrix, and sign off on our planning reports.
                        </p>
                        <div class="row text-center mt-3">
                            <div class="col-12">
                                <button id="btnRegister" (click)="proceed()"
                                    [disabled]="registerForm.invalid && sacPlanRegNumber.invalid " mat-button
                                    class="button heading-4 mt-2">
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</main>