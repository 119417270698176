<form id="informationForm" novalidate #informationForm="ngForm" class="submission-form">
    <section class="mat-input-section">
        <div class="heading-2 mt-4">
            STEP 1: PERSONAL AND COMPANY INFORMATION
        </div>
        <div>
            <form [formGroup]="validatedForm">
                <div class="row mt-2">
                    <div class="col-10">
                        <label class="paragraph"
                            [ngClass]="{'mat-invalid-input': validatedForm.get('urbanPlanner.graduationYear').invalid, 'mat-valid-input': validatedForm.get('urbanPlanner.graduationYear').valid}">
                            Which year did you graduate as a planner?
                        </label>
                    </div>
                    <div class="col-10" formGroupName="urbanPlanner">
                        <input name="plannerGraduationYear" class="paragraph mat-input-section" type="number"
                            pattern="\d{4}" formControlName="graduationYear" required>
                        <div *ngIf="validatedForm.get('urbanPlanner.graduationYear').errors?.yearInvalid && (validatedForm.get('urbanPlanner.graduationYear').dirty || validatedForm.get('urbanPlanner.graduationYear').touched)"
                            class="error text-size">
                            The year cannot be greater than the current year.
                        </div>
                        <div *ngIf="validatedForm.get('urbanPlanner.graduationYear').invalid && (validatedForm.get('urbanPlanner.graduationYear').dirty || validatedForm.get('urbanPlanner.graduationYear').touched)"
                            class="error text-size">
                            Please enter a valid year.
                        </div>
                    </div>
                </div>
            </form>
            <div class="row mt-2">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': graduationInstitution.invalid, 'mat-valid-input': graduationInstitution.valid}">
                        From which institution did you graduate?
                    </label>
                </div>
                <div class="col-10">
                    <input name="graduationInstitution" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="form.urbanPlanner.graduationInstitution" required #graduationInstitution="ngModel">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-10">
                    <label class="paragraph text-size"
                        [ngClass]="{'mat-invalid-input': statutoryPlanningExperienceYears.invalid, 'mat-valid-input': statutoryPlanningExperienceYears.valid}">
                        How many years of experience do you have in the field of land use management (statutory
                        planning) specifically?
                    </label>
                </div>
                <div class="col-10">
                    <input name="statutoryPlanningExperienceYears" class="paragraph mat-input-section" type="number"
                        [(ngModel)]="form.urbanPlanner.yearsOfExperience" required
                        #statutoryPlanningExperienceYears="ngModel">
                </div>
            </div>
            <div class="row mt-2 spacing-between-sections">
                <div class="col-10">
                    <label class="paragraph"
                        [ngClass]="{'mat-invalid-input': companyNameOperatedUnder.invalid, 'mat-valid-input': companyNameOperatedUnder.valid}">
                        Under which name does you/your company operate?
                    </label>
                </div>
                <div class="col-10">
                    <input name="companyNameOperatedUnder" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="form.urbanPlanner.companyDetail.name" required #companyNameOperatedUnder="ngModel">
                </div>
            </div>
            <h5 class="heading-2">Property Identification</h5>
            <p class="heading-5">
                <span class="required-text">|</span>
                Please search and select your company/office on the map. You only have three attempts.
            </p>
            <div class="row text-center">
                <div class="col-12">
                    <div class="map-container">
                        <div>
                            <div class="row text-center">
                                <div class="col-12">
                                    <div class="map-container">
                                        <div id="sypMap"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row map-section" *ngIf="loadingAddress">
                                <div class="col-12">
                                    <div class="spinner-container">
                                        <div class="spinner-container-center">
                                            <mat-spinner></mat-spinner>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="side-navbar">
                            <div class="property-details">
                                <mat-drawer-container class="example-container">
                                    <mat-drawer mode="side" opened>
                                        <div class="property-details">
                                            <p class="confirm-property">Confirm Property</p>
                                            <div class="small-map">
                                                <div class="side-navbar">
                                                    <h2>Property Boundary</h2>
                                                    <div id="sidebar-map" class="small-map">
                                                        <img [src]="cadasterPicture" class="cadastre-image"
                                                            alt="Cadaster Picture">
                                                    </div>
                                                    <div class="button-container">
                                                        <button class="incorrect-button"
                                                            [class.selected]="selectedBoundaryButton === 1"
                                                            (click)="selectBoundaryButton(1)" confirmPropertyBoundary=1>
                                                            Incorrect
                                                        </button>
                                                        <button class="unsure-button"
                                                            [class.selected]="selectedBoundaryButton === 2"
                                                            (click)="selectBoundaryButton(2)" confirmPropertyBoundary=2>
                                                            Unsure
                                                        </button>
                                                        <button class="correct-button"
                                                            [class.selected]="selectedBoundaryButton === 3"
                                                            (click)="selectBoundaryButton(3)" confirmPropertyBoundary=3>
                                                            Correct
                                                        </button>
                                                    </div>
                                                    <div class="property-information">
                                                        <h2>Property Details</h2>
                                                        <table>
                                                            <tr>
                                                                <td>Unit Number:</td>
                                                                <td>{{unitNumber}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Erf/Portion Nr:</td>
                                                                <td>{{erfOrPortionNr}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Short Address:</td>
                                                                <td>{{shortAddress}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Suburb Name:</td>
                                                                <td>{{suburbName}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>City/Town:</td>
                                                                <td>{{cityTown}}</td>
                                                            </tr>
                                                        </table>
                                                        <div class="button-container">
                                                            <button class="incorrect-button"
                                                                [class.selected]="selectedButton === 1"
                                                                (click)="selectButton(1)" confirmPropertyDetail=1>
                                                                Incorrect
                                                            </button>
                                                            <button class="unsure-button"
                                                                [class.selected]="selectedButton === 2"
                                                                (click)="selectButton(2)" confirmPropertyDetail=2>
                                                                Unsure
                                                            </button>
                                                            <button class="correct-button"
                                                                [class.selected]="selectedButton === 3"
                                                                (click)="selectButton(3)" confirmPropertyDetail=3>
                                                                Correct
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-drawer>
                                </mat-drawer-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="confirmPropertyBoundary == 1 || confirmPropertyBoundary == 2">
                <div class="heading-3 top-spacing">
                    Property Boundary
                </div>
                <div class="heading-5">
                    <span class="burnt-red">|</span>
                    Please explain your concern regarding the boundary indicated:
                </div>
                <div class="row">
                    <div class="col-7">
                        <textarea style="height: 100px; resize: none;" [matTextareaAutosize]="true"
                            [(ngModel)]="form.propertyBoundaryConcern" id="txtPropertyBoundaryConcern"
                            name="propertyBoundaryConcern" class="paragraph"></textarea>
                    </div>
                </div>
            </div>
            <div *ngIf="confirmPropertyDetail == 1 || confirmPropertyDetail == 2" class="spacing-between-sections">
                <div class="heading-3 top-spacing">
                    Property Details
                </div>
                <div class="heading-5">
                    Please provide the correct details:
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-3">
                        <label id="lblPropertyAddress" for="txtPropertyAddress" class="paragraph">
                            Property address
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" id="txtPropertyAddress" required name="propertyAddress" class="paragraph"
                            [(ngModel)]="form.propertyAddress">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-3">
                        <label id="lblPropertyDescription" for="txtPropertyDescription" class="paragraph">
                            Property description:
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" id="txtPropertyDescription" required name="propertyDescription"
                            class="paragraph" [(ngModel)]="form.propertyDescription">
                    </div>
                </div>
            </div>
            <section>
                <div class="row top-spacing">
                    <div class="col-10">
                        <label class="paragraph mat-not-required">
                            If applicable - your company registration number?
                        </label>
                    </div>
                    <div class="col-10">
                        <input name="companyRegistrationNumber" class="paragraph mat-input-section" type="text"
                            [(ngModel)]="form.urbanPlanner.companyDetail.companyRegistrationNumber"
                            companyRegistrationNumber="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-10">
                        <label class="paragraph mat-not-required">
                            If applicable - your company VAT number?
                        </label>
                    </div>
                    <div class="col-10">
                        <input name="companyVatNumber" class="paragraph mat-input-section" type="text"
                            [(ngModel)]="form.urbanPlanner.companyDetail.companyVatNumber" companyVatNumber="ngModel">
                    </div>
                </div>
            </section>
            <section class="submission-form">
                <div class="heading-5 top-spacing">
                    <span class="burnt-red">|</span>
                    At which municipal council(s) do you mostly submit planning applications?
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of municipalCouncilLookup  let index = i" class="paragraph mt-1">
                            <label class="checkbox-container paragraph mt-1">
                                <span class="paragraph">
                                    {{option.value}}
                                    <span
                                        [ngClass]="{'specify-text': form.municipalCouncilsMostlySubmittedToOther?.length == 0}"
                                        *ngIf="municipalOtherCheck(option)">
                                        - Please specify
                                    </span>
                                </span>
                                <input type="checkbox" [(ngModel)]="option.isChecked"
                                    [name]="municipalCouncilsMostlySubmittedToOptions"
                                    #municipalCouncilsMostlySubmittedToOptions="ngModel"
                                    (change)="selectedMunicipalCouncil(option)" />
                                <span class="checkmark"></span>
                            </label>
                            <div class="mt-2" *ngIf="option.isChecked && municipalOtherCheck(option)">
                                <input name="municipalCouncilsMostlySubmittedToOther"
                                    class="paragraph mat-input-section" type="text"
                                    [(ngModel)]="form.urbanPlanner.companyDetail.municipalCouncilOther" required
                                    #municipalCouncilsMostlySubmittedToOther="ngModel">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="heading-5">
                    <span class="burnt-red">|</span>
                    Indicate any type of land development (planning) applications in which you have extensive experience
                    and knowledge:
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of this.landDevelopmentType; let index = i">
                            <label class="checkbox-container paragraph mt-1">
                                <span class="paragraph">
                                    {{option.value}}
                                    <span [ngClass]="{'specify-text': form.landDevelopmentTypeOther?.length == 0}"
                                        *ngIf="planningOtherCheck(option)">
                                        - Please specify
                                    </span>
                                </span>
                                <input type="checkbox" [(ngModel)]="option.isChecked"
                                    [name]="planningApplicationsExperienceAndKnowledgeOptions"
                                    #planningApplicationsExperienceAndKnowledgeOptions="ngModel"
                                    (change)="selectedLandDevelopmentType(option)" />
                                <span class="checkmark"></span>
                            </label>
                            <div class="mt-2" *ngIf="planningOtherCheck(option)">
                                <div class="row mt-2">
                                    <div class="col-10">
                                        <input name="planningApplicationsExperienceAndKnowledgeOther" type="text"
                                            class="paragraph mat-input-section mat-input-section-other"
                                            [(ngModel)]="form.urbanPlanner.companyDetail.landDevelopmentTypeOther"
                                            required #planningApplicationsExperienceAndKnowledgeOther="ngModel">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="heading-5">
                    <span class="burnt-red">|</span>
                    Do you have proven experience in feasibility/viability studies: conducting market research and
                    determining the most viable development to calculate the maximum ROI?
                </div>
                <div class="row">
                    <div class="col-10">
                        <label class="radio-container paragraph mt-1">
                            <span class="paragraph">Yes</span>
                            <input type="radio" class="radio-button-text" name="experienceMarket"
                                [(ngModel)]="experienceInMarketResearch" [value]="true" />
                        </label>
                    </div>
                    <div class="col-10">
                        <label class="radio-container paragraph mt-1">
                            <span class="paragraph">No</span>
                            <input type="radio" class="radio-button-text" name="experienceMarket"
                                [(ngModel)]="experienceInMarketResearch" [value]="false" />
                        </label>
                    </div>
                </div>
                <div>
                    <div [hidden]="experienceInMarketResearch != true">
                        <div class="heading-5">
                            <span class="burnt-red">|</span>
                            Indicate any type of planning applications in which you have extensive experience and
                            knowledge:
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div *ngFor="let option of this.developmentSpeciality; let index = i">
                                    <label class="checkbox-container paragraph mt-1">
                                        <span class="paragraph">
                                            {{option.value}}
                                            <span
                                                [ngClass]="{'specify-text': form.planningApplicationsExperienceAndKnowledgeOther?.length == 0}"
                                                *ngIf="developmentTypeOtherCheck(option)">
                                                - Please specify
                                            </span>
                                        </span>
                                        <input type="checkbox" [(ngModel)]="option.isChecked"
                                            [name]="planningApplicationsExperienceAndKnowledgeOptions"
                                            #planningApplicationsExperienceAndKnowledgeOptions="ngModel"
                                            (change)="selectedDevelopmentType(option)" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <div class="mt-2" *ngIf="developmentTypeOtherCheck(option)">
                                        <div class="row mt-2">
                                            <div class="col-10">
                                                <input name="planningApplicationsExperienceAndKnowledgeOther"
                                                    type="text"
                                                    class="paragraph mat-input-section mat-input-section-other"
                                                    [(ngModel)]="form.urbanPlanner.companyDetail.developmentSpecialityOther"
                                                    required #planningApplicationsExperienceAndKnowledgeOther="ngModel">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="submission-form">
                <p class="paragraph top-spacing">
                    <strong>
                        Please provide the details of one support staff member we may contact regarding
                        <u>administrative</u>
                        queries:
                    </strong>
                </p>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="paragraph not-required" name="administrativeName"
                            [(ngModel)]="form.urbanPlanner.companyDetail.contactDetail.name" #name="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Surname:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="paragraph not-required" name="administrativeSurname"
                            [(ngModel)]="form.urbanPlanner.companyDetail.contactDetail.surname" #surname="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Position:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="townPlanningPosition" class="paragraph not-required"
                            [(ngModel)]="form.urbanPlanner.companyDetail.contactDetail.position">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Landline:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativeLandline" class="paragraph not-required"
                            [(ngModel)]="form.urbanPlanner.companyDetail.contactDetail.landLine">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Mobile:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativePhoneNumber" class="paragraph not-required"
                            [(ngModel)]="form.urbanPlanner.companyDetail.contactDetail.mobile" #phoneNumber="ngModel"
                            minlength="10">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">
                            Email Address:
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativeEmailAddress" class="paragraph not-required"
                            [(ngModel)]="form.urbanPlanner.companyDetail.contactDetail.emailAddress"
                            #emailAddress="ngModel" [pattern]="emailPattern">
                    </div>
                </div>
            </section>
            <div class="row text-center">
                <div class="col-12">
                    <button id="btnSaveFinal" class="button heading-4 top-spacing" [disabled]="informationForm.invalid"
                        (click)="submitForm()">
                        SUBMIT
                    </button>
                </div>
            </div>
        </div>
    </section>
</form>