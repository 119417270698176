<main class="main-container">
    <div class="heading">
        Get In Touch
    </div>
    <div class="underline"></div>
    <div class="text">
        For property related queries, to view a property or to make an offer, please contact the Broker/Agent directly.
    </div>
    <div class="text">
        For general queries, email: queries&#64;propertymatrix.co.za
    </div>
</main>