import { AdditionalPolicyPlanFigureDto, AreaAvailableDto, CostEstimatesDto, CurrentConstructionScopeDto, ExistingLandUseDto, ExistingZoningControlsDto, ImportantFactorsToConsiderDto, ImprovementOptionDto, PlanningReportDto, PlanningReportReferenceDto, PotentialConstructionScopeDto, ProposedDevelopmentScenarioDto, RiskscapeExistingLandUseAndFeatureDto, RiskscapePropertyInfoDto, SpatialFrameworkAndPolicyDto, WayForwardDto, ZoningMapDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { Section } from 'src/app/common/global-models/section';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { debounce } from 'lodash';

import { ReportKeyDialogComponent } from '../report-key-dialog/report-key-dialog.component';

@Component({
  selector: 'app-planning-report',
  templateUrl: './planning-report.component.html',
  styleUrls: ['./planning-report.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class PlanningReportComponent implements OnInit, OnChanges {

  @Input() planningReportData: PlanningReportDto;
  @Input() listingId: string;
  @Input() multipleListingId: string;
  @Input() isMultiple: boolean;
  @Input() isGeneralInformation: boolean;

  @Output() tabNumberEvent = new EventEmitter<number>();

  sections: Section[];
  riskscapePropertyInfoData: RiskscapePropertyInfoDto;
  riskscapeExistingLandUseAndFeatureData: RiskscapeExistingLandUseAndFeatureDto;

  loading: boolean = false;
  listingType: string | null = null;
  municipality: string | null = null;
  planningReportId: string | null = null;
  localityMapFileId: string | null = null;
  amountOfLandParcels: number | null = null;
  potentialUsableProperty: number | null = null;
  proposedDevelopmentEnhancement: string | null = null;
  potentialConstructionScopeParagraph: string | null = null;

  sectionCache: { [key: string]: Section[] } = {};

  planningReportSections = [
    'Property Details',
    'Address Details',
    'Locality Map',
    'Existing Land Use',
    'Existing Zoning Controls',
    'Spatial Development Framework & Policies',
    'Additional Policies, Plans, Figures',
    'Development Potential - Area Available',
    'Improvement Options',
    'Quantified Extent (A Possible Development Scenario)',
    'Current Construction Scope',
    'Potential Construction Scope',
    'Important Factors To Consider',
    'Cost Estimates',
    'Way Forward',
    'References',
  ];

  generalInformationSections = [
    'Locality Map',
    'Existing Land Use',
    'Spatial Development Framework & Policies',
    'Additional Policies, Plans, Figures',
    'Development Potential - Consolidated Area Available',
    'Improvement Options',
    'Proposed Development Scenario - "Highest/Best" Right',
    'Current Construction Scope',
    'Potential Construction Scope',
    'Important Factors To Consider',
    'Cost Estimates',
    'Way Forward',
    'References',
  ];

  propertySpecificInformationSections = [
    'Property Details',
    'Address Details',
    'Existing Zoning Controls',
    'References',
  ];

  propertyTypes: KeyValuePair[] = [
    { key: 1, value: 'Erf' },
    { key: 2, value: 'Holding' },
    { key: 3, value: 'Farm' },
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.listingType = params['listingType'];
    });
    this.sections = this.generateSections();
    this.loadAllPlanningReportData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.planningReportData && changes.planningReportData.currentValue !== changes.planningReportData.previousValue) {
      this.loadAllPlanningReportData();
    }
  }

  collapseOrExpandSection = debounce((section: { checked: boolean; }) => {
    section.checked = !section.checked;
  }, 100);

  private generateSections(): Section[] {
    const sectionKey = `${this.isMultiple}-${this.isGeneralInformation}`;
    if (this.sectionCache[sectionKey]) {
      return this.sectionCache[sectionKey];
    }

    let sections = this.isMultiple ?
      (this.isGeneralInformation ? this.generalInformationSections : this.propertySpecificInformationSections)
      : this.planningReportSections;

    const generatedSections = sections.map((section, index) => ({
      id: index + 1,
      value: section,
      checked: false
    }));

    this.sectionCache[sectionKey] = generatedSections;
    return generatedSections;
  }

  private loadAllPlanningReportData(): void {
    const reportData = this.planningReportData || {};
    this.planningReportId = reportData?.id || '';
    this.localityMapFileId = reportData?.localityMapFileId || '';
    this.municipality = reportData?.riskscapePropertyInfo?.municipality;
    this.amountOfLandParcels = reportData?.amountOfLandParcels || 0;
    this.proposedDevelopmentEnhancement = reportData?.proposedDevelopmentEnhancement || '';
    this.potentialConstructionScopeParagraph = reportData?.potentialConstructionScopeParagraph || '';
    this.riskscapePropertyInfoData = reportData?.riskscapePropertyInfo || {};
    this.riskscapeExistingLandUseAndFeatureData = reportData?.riskscapeExistingLandUseAndFeature || {};
  }

  protected async saveReportDetails(): Promise<void> {
    this.setLoadingState(true);
    if (!this.isMultiple) {
      this.saveReport(() => this.planningReportService.apiV1PlanningReportAddOrUpdatePlanningReportPost({
        propertyListingId: this.listingId,
        body: this.planningReportData
      }));
    } else if (this.isGeneralInformation) {
      this.saveReport(() => this.planningReportService.apiV1PlanningReportAddOrUpdateGeneralReportPost({
        multipleListingId: this.multipleListingId,
        body: this.planningReportData
      }));
    } else {
      this.saveReport(() => this.planningReportService.apiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPost({
        propertyListingId: this.listingId,
        body: this.planningReportData
      }));
    }
  }

  private async saveReport(apiCall: () => Observable<any>): Promise<void> {
    try {
      await firstValueFrom(apiCall());
      this.notificationService.showSuccessMessage('Success', 'Successfully saved report details.');
    } catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not save report details.');
    } finally {
      this.setLoadingState(false);
    }
  }

  private setLoadingState(state: boolean): void {
    this.loading = state;
  }

  protected handlePropertyTypeChange(event: string): void {
    this.riskscapePropertyInfoData.propertyType = event;
  }

  protected handleLocalityMapFileIdChange(event: string): void {
    this.planningReportData.localityMapFileId = event;
  }

  protected handleExistingLandUseChange(event: ExistingLandUseDto): void {
    this.planningReportData.existingLandUse = event;
  }

  protected handleExistingZoningControlsChange(event: ExistingZoningControlsDto): void {
    this.planningReportData.existingZoningControls = event;
  }

  protected handleZoningMapChange(event: ZoningMapDto): void {
    this.planningReportData.zoningMap = event;
  }

  protected handleSpatialFrameworkChange(event: SpatialFrameworkAndPolicyDto[]): void {
    this.planningReportData.spatialFrameworksAndPolicies = event;
  }

  protected handleAdditionalPolicyChange(event: AdditionalPolicyPlanFigureDto[]): void {
    this.planningReportData.additionalPoliciesPlansFigures = event;
  }

  protected handleAreaAvailableChange(event: AreaAvailableDto): void {
    this.planningReportData.areaAvailable = event;
  }

  protected handlePotentialUsablePropertyChange(event: number): void {
    this.potentialUsableProperty = event;
  }

  protected handleImprovementOptionChange(event: ImprovementOptionDto[]): void {
    this.planningReportData.improvementOptions = event;
  }

  protected handleProposedDevelopmentScenariosChange(event: ProposedDevelopmentScenarioDto[]): void {
    this.planningReportData.proposedDevelopmentScenarios = event;
  }

  protected handleLandParcelSelectionChange(event: number): void {
    this.planningReportData.amountOfLandParcels = event;
    this.amountOfLandParcels = event;
  }

  protected handleProposedDevelopmentEnhancementChange(event: string): void {
    this.planningReportData.proposedDevelopmentEnhancement = event;
  }

  protected handleCurrentConstructionScopeChange(event: CurrentConstructionScopeDto): void {
    this.planningReportData.currentConstructionScope = event;
  }

  protected handlePotentialConstructionScopesChange(event: PotentialConstructionScopeDto[]): void {
    this.planningReportData.potentialConstructionScopes = event;
  }

  protected handlePotentialConstructionScopeParagraphChange(event: string): void {
    this.planningReportData.potentialConstructionScopeParagraph = event;
  }

  protected handleReferenceChange(event: PlanningReportReferenceDto[]): void {
    this.planningReportData.references = event;
  }

  protected handleImportantFactorsToConsiderChange(event: ImportantFactorsToConsiderDto): void {
    this.planningReportData.importantFactorsToConsider = event;
  }

  protected handleCostEstimatesChange(event: CostEstimatesDto): void {
    this.planningReportData.costEstimates = event;
  }

  protected handleWayForwardChange(event: WayForwardDto): void {
    this.planningReportData.wayForward = event;
  }

  protected goToListingOverview(): void {
    this.router.navigate(['admin/listings/listing-overview'], {
      queryParams: {
        listingType: this.listingType
      }
    });
  }

  protected goToAddendumOne(): void {
    this.tabNumberEvent.emit(2);
  }

  protected openReportKeyDialog(): void {
    this.dialog.open(ReportKeyDialogComponent, {
      width: '30vw',
      height: '35vh'
    });
  }
}
