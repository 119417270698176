<mat-sidenav-container (backdropClick)="closeNav()">
    <mat-sidenav #sidenav (keydown.escape)="closeNav()" position="end">
        <div class="container-side sidenav-container container" fxLayout="column" fxLayoutAlign="space-between start">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    {{ sideNavPage.value }}
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white" (click)="closeNav()" aria-label="Close">
                    </button>
                </div>
            </div>
            <div *ngIf="sideNavPage.key == 1"></div>
            <div *ngIf="sideNavPage.key == 2">
                <div class="row">
                    <p class="col-12 heading-5-light">
                        <span class="coral">
                            Please note:
                        </span>
                        The list below is by no means exhaustive and will be
                        influenced by the property type and
                        valuation method applied. However, please be assured
                        that your property will be objectively
                        assessed and valued in terms of a thorough
                        investigation, according to property specific
                        parameters.
                    </p>
                    <br />
                    <p class="col-12 heading-5-light">
                        Every industry differs, but the Property Matrix value
                        assessment team, assisted by our
                        professional valuer network, will generally take the
                        following factors into consideration to
                        determine the open market value of your property:
                    </p>
                    <div class="col-12 heading-5-light">
                        Property price
                    </div>
                    <div class="col-12">
                        <div class="paragraph light">
                            Previous purchase price(s) and date(s), as well as
                            current municipal value.
                        </div>
                    </div>
                    <br />
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">Location</div>
                    <div class="col-12">
                        <p class="paragraph light">
                            Macro- and micro-location, accessibility and
                            exposure, demographic trends, developments in
                            the area, surrounding properties and amenities.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">Site specifics</div>
                    <div class="col-12">
                        <p class="paragraph light">
                            Property size and shape, planning parameters
                            (existing zoning, possible future zoning,
                            density, height, coverage, floor area ratio (FAR)
                            and building lines), servitudes and other
                            endorsements.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">Site improvements</div>
                    <div class="col-12">
                        <p class="paragraph light">
                            Type and quality of buildings on site, building
                            sizes, building grades, age of buildings,
                            parking provision, site security, surrounding works.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">Building tenancy</div>
                    <div class="col-12">
                        <p class="paragraph light">
                            Owner occupied or tenanted, the leasability of
                            buildings, number and type of tenants,
                            tenant spread and rating, lease terms and
                            escalations, lease renewals, or tenant failures.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">Market conditions</div>
                    <div class="col-12">
                        <p class="paragraph light">
                            General macro economy, supply and demand; and where
                            applicable: rental rates, vacancy rates
                            and vacancy periods.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">Comparative Market
                        Analysis</div>
                    <div class="col-12">
                        <p class="paragraph light">
                            Comparable sales in the nearby surrounds, comparable
                            sales in similar investor nodes,
                            comparable rentals in the nearby surrounds and
                            saleability of the subject property.
                        </p>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="closeNav()">
                            GOT IT
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="sideNavPage.key == 3">
                <div class="row">
                    <p class="col-12 heading-5-light">
                        <span class="coral">
                            Please note:
                        </span>
                        Our national panel of selected Professional Valuers -
                        all registered with the South African
                        Council for the Property Valuers Profession (“SACPVP”) -
                        will provide this service at a
                        competitive rate. Be assured that in terms of the
                        Professional Valuers code of conduct, all
                        valuations will be performed in an independent and
                        objective manner.
                    </p>
                    <br />
                    <div class="col-12 heading-5-light">
                        Description of a Formal Valuation Report:
                    </div>
                    <div class="col-12">
                        <div class="paragraph light">
                            This is a detailed report and will also include a
                            site visit by the property valuer. The
                            report will contain full details of market research
                            and all relevant information and will be
                            a more accurate determination of the market value of
                            the property. This type of report can
                            also be used for negotiation purposes, tax and audit
                            purposes, financing purposes, etc. The
                            report also provides a replacement value of the
                            property improvements for insurance
                            purposes.
                        </div>
                    </div>
                    <br />
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="paragraph light">
                            There are various methods commonly used for
                            determining the market value of real estate.
                            These methods of valuation comprise:
                        </p>
                        <ul class="montserrat-bullets paragraph light">
                            <li>Direct comparable sales approach</li>
                            <li>Cost approach</li>
                            <li>Income approach</li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="paragraph light">
                            Every property is unique and will be valued
                            according to the type of property and
                            improvements there-on. Our valuation reports
                            generally incorporate the following phases:
                        </p>
                        <ul class="montserrat-bullets paragraph light">
                            <li>Phase 1: Data collection</li>
                            <li>Phase 2: Site inspection</li>
                            <li>Phase 3: Market research</li>
                            <li>Phase 4: Analysis and calculations</li>
                            <li>Phase 5: Report writing and conclusion</li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">DELIVERABLES AND
                        TIMEFRAMES</div>
                    <div class="col-12">
                        <ul class="montserrat-bullets paragraph light">
                            <li>15 to 20-page report in PDF format</li>
                            <li>Includes site visit</li>
                            <li>Provides full detail of all market research and
                                calculations</li>
                            <li>10 to 12 working days for delivery</li>
                            <li>
                                <strong>Please note:</strong>
                                This type of report will provide all the
                                relevant information required for a financing
                                application however most financial institutions
                                / banks have their own templates and
                                requirements. We will be able to transfer the
                                information to the relevant template,
                                however this will incur a small additional fee
                                and the instruction will have to be
                                issued through the specific bank.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        FEE STRUCTURE (estimates only)
                    </div>
                    <div class="col-12">
                        <p class="paragraph light">
                            All fees as indicated in the table below exclude
                            VAT.
                            <strong>Important:</strong>
                            This is only a general indication of fees; each
                            request for a valuation will be assessed
                            individually and a property specific quotation will
                            be provided.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <table id="fee-schedule-table">
                            <thead>
                                <tr class="heading-3-light text-center">
                                    <th colspan="2">
                                        FEE SCHEDULE: FORMAL VALUATION REPORT
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="paragraph grey-background-row">
                                    <td colspan="2">
                                        <span>
                                            <strong>
                                                Vacant land
                                            </strong>
                                        </span>
                                    </td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Vacant land (single zoning)
                                        </span>
                                    </td>
                                    <td class="text-center">R4 500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Vacant land with township rights
                                        </span>
                                    </td>
                                    <td class="text-center">Depends on size of
                                        development</td>
                                </tr>
                                <tr class="paragraph grey-background-row">
                                    <td colspan="2">
                                        <span>
                                            <strong>
                                                Land with built improvements
                                                (residential/industrial/offices/flats/retail/other)
                                            </strong>
                                        </span>
                                    </td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Single land use; value less than
                                            R5-million
                                        </span>
                                    </td>
                                    <td class="text-center">R3 500 to R5
                                        000</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Single land use; value between
                                            R5-million and R20-million
                                        </span>
                                    </td>
                                    <td class="text-center">R5 500 to R8
                                        500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Single land use; value between
                                            R20-million and R50-million
                                        </span>
                                    </td>
                                    <td class="text-center">R9 500 to R12
                                        500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Single land use; value above
                                            R50-million
                                        </span>
                                    </td>
                                    <td class="text-center">R17 500 +</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Multiple land uses; value less than
                                            R5-million
                                        </span>
                                    </td>
                                    <td class="text-center">R5 000 to R6
                                        500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Multiple land uses; value between
                                            R5-million and R20-million
                                        </span>
                                    </td>
                                    <td class="text-center">R7 500 to R11
                                        500</td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Multiple land uses; value between
                                            R20-million and R50-million
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span>
                                            R11 500 to R15 500
                                        </span>
                                    </td>
                                </tr>
                                <tr class="paragraph light">
                                    <td>
                                        <span>
                                            Multiple land uses; value above
                                            R50-million
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span>
                                            R22 500+
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <p class="paragraph light">
                            <span class="heading-5-light">
                                OBTAIN QUOTE:
                            </span>
                            A quotation for a formal valuation of your property
                            can be requested under “Additional
                            Services”; “Property Valuation” (level 2 to be
                            selected).
                        </p>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="closeNav()">
                            GOT IT
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="sideNavPage.key == 5">
                <div class="row">
                    <ol class="paragraph light">
                        <li>
                            A map or aerial photo, indicating all the properties
                            involved and the exact boundaries.
                        </li>
                        <li>
                            A Joint Sales Agreement, setting out the rules and
                            general structure that your group will
                            adhere to. The Property Matrix legal team can assist
                            you in this matter.
                        </li>
                        <li>
                            A resolution, signed by all owners, nominating a
                            single representative (co-seller) who will
                            liaise with Property Matrix.
                        </li>
                        <li>
                            A resolution, signed by all owners, nominating a
                            single conveyancer experienced in acting
                            for groups. Our legal team specializes in these
                            transactions
                        </li>
                        <li>
                            A resolution, signed by all owners, nominating a
                            single commercial broker/estate agent
                            registered with Property Matrix, who will act on
                            behalf of all other commercial
                            broker/estate agents involved.
                        </li>
                        <li>
                            A Commission Split Agreement, drafted by the
                            nominated agent signed by all agents, owners,
                            and the nominated conveyancer.
                        </li>
                    </ol>
                </div>
                <div class="col-12 heading-5-light">
                    Please note:
                </div>
                <div class="col-12">
                    <div class="paragraph light">
                        Each owner/seller involved will sign a sole and
                        exclusive mandate contract, indicating the
                        asking price as agreed upon. It is required that the
                        Joint Sales Agreement indicates the
                        percentage split of the selling price between all
                        parties once an offer is received. Property
                        Matrix can assist in this matter: we have a national
                        panel of SACPVP-registered valuers in our
                        network and can refer a professional valuer who will
                        objectively assess the open market value of
                        each property involved.
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="closeNav()">
                            GOT IT
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <main>
        <div id="top-page"></div>
        <div class="content content-padding">
            <div class="container">
                <section>
                    <div class="row mt-3 no-gutters">
                        <div class="col-3 px-1" *ngFor="let header of formHeaders">
                            <button class="form-section form-section-button active-form-section"
                                [ngClass]="{ 'active-form-section': pageNumber >= header.key }"
                                (click)="selectPage(header.key)" [disabled]="pageNumber <= header.key">
                                <div class="heading-3-light">
                                    {{header.value}}
                                </div>
                            </button>
                        </div>
                    </div>
                </section>
                <section>
                    <div [hidden]="pageNumber != 1">
                        <app-ownership-details (pageNumberEvent)="changePage($event)"
                            (ownershipDetailsEvent)="assignOwnershipDetails($event)">
                        </app-ownership-details>
                    </div>
                    <div [hidden]="pageNumber != 2">
                        <app-property-details (pageNumberEvent)="changePage($event)"
                            (openSideNavEvent)="openNav($event)" (propertyDetailsEvent)="assignPropertyDetails($event)"
                            (cadastreEvent)="assignCadastre($event)">
                        </app-property-details>
                    </div>
                    <div [hidden]="pageNumber != 3">
                        <app-contract-details (pageNumberEvent)="changePage($event)"
                            (contractDetailsEvent)="assignContractDetails($event)"
                            (loadUploadBlocksEvent)="loadUploadBlocks($event)">
                        </app-contract-details>
                    </div>
                    <div [hidden]="pageNumber != 4">
                        <app-upload-documents [ownershipDetails]="ownershipDetails"
                            [loadUploadDocumentBlocks]="loadUploadDocumentBlocks"
                            [isMultipleProperty]="isMultipleProperty" [isMultipleTenants]="isMultipleTenants"
                            (openSideNavEvent)="openNav($event)"
                            (uploadDocumentDetailsEvent)="assignUploadDocumentDetails($event)">
                        </app-upload-documents>
                    </div>
                </section>
            </div>
        </div>
    </main>
</mat-sidenav-container>