import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { initialDeedsReportsText } from 'src/app/constants/data/important-factors-initial-values';
import { ImportantFactorsToConsiderDto } from 'src/app/services/property-matrixV2/models';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-important-factors-to-consider',
  templateUrl: './important-factors-to-consider.component.html',
  styleUrls: ['./important-factors-to-consider.component.scss']
})
export class ImportantFactorsToConsiderComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() importantFactorsToConsiderChange = new EventEmitter<ImportantFactorsToConsiderDto>();

  importantFactorsToConsiderData: ImportantFactorsToConsiderDto;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadImportantFactorsToConsider();
  }

  async loadImportantFactorsToConsider(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetImportantFactorsToConsiderGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response: ImportantFactorsToConsiderDto) => {
        this.importantFactorsToConsiderData = response;
        if (this.importantFactorsToConsiderData?.deedsReports === '' || this.importantFactorsToConsiderData?.deedsReports === null) {
          this.importantFactorsToConsiderData.deedsReports = initialDeedsReportsText;
        }
        if (this.importantFactorsToConsiderData?.developmentCharges === '' || this.importantFactorsToConsiderData?.developmentCharges === null) {
          this.importantFactorsToConsiderData.developmentCharges = initialDeedsReportsText;
        }
        if (this.importantFactorsToConsiderData?.futureRoads === '' || this.importantFactorsToConsiderData?.futureRoads === null) {
          this.importantFactorsToConsiderData.futureRoads = initialDeedsReportsText;
        }
        if (this.importantFactorsToConsiderData?.heritage === '' || this.importantFactorsToConsiderData?.heritage === null) {
          this.importantFactorsToConsiderData.heritage = initialDeedsReportsText;
        }
        if (this.importantFactorsToConsiderData?.historicApprovals === '' || this.importantFactorsToConsiderData?.historicApprovals === null) {
          this.importantFactorsToConsiderData.historicApprovals = initialDeedsReportsText;
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load important factors to consider data.');
      }
    });
  }

  emitChanges() {
    this.importantFactorsToConsiderChange.emit(this.importantFactorsToConsiderData);
  }
}
