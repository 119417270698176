import { NotAvailableDialogComponent } from 'src/app/shared/components/not-available-dialog/not-available-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-in-our-database',
  templateUrl: './share-in-our-database.component.html',
  styleUrls: ['./share-in-our-database.component.scss']
})
export class ShareInOurDatabaseComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  page = 1;

  knowledgeAdvantageItems = [
    'Instantly receive notifications when properties that precisely match your needs enter the market.',
    'Our cutting-edge software tools will give you a competitive edge and will save you valuable time.'
  ];

  unparalleledMenuItems = [
    'Our extensive selection of property types, each uniquely categorized, sets us apart from other platforms.'
  ];

  professionalPlanningReportItems = [
    'Each property presented to you comes with a comprehensive planning report, signed by an experienced SACPLAN-registered planner.',
    'The report highlights the property\'s potential, permissible uses (zoning), and, if applicable, the status of any ongoing planning applications.'
  ];

  multipleOrdersMaximumNetworkingItems = [
    'Up to 15 distinct property \'orders,\' can be placed and edited at any time.',
    'You can specify your preferred property type, budget, size, and location for each subscription.'
  ]

  constructor(
    private route: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.openDialog();
  }

  openNav() {
    this.sidenav.open();
  }

  closeNav() {
    this.sidenav.close();
  }

  openDialog(): void {
    this.dialog.open(NotAvailableDialogComponent);
  }

  continueToForm() {
    this.route.navigateByUrl('/share-in-our-database/capture');
  }
}
