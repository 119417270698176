import { NotAvailableDialogComponent } from 'src/app/shared/components/not-available-dialog/not-available-dialog.component';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-feasibility-studies',
  templateUrl: './feasibility-studies.component.html',
  styleUrls: ['./feasibility-studies.component.scss']
})
export class FeasibilityStudiesComponent implements AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  tabIndex: number = -1;

  open: boolean = false;
  isShow: boolean = true;
  isMenuOpen: boolean = false;
  showLevelOne: boolean = false;
  showLevelTwo: boolean = false;
  newButtonOne: boolean = false;
  newButtonTwo: boolean = false;
  addOverflow: boolean = false;

  constructor(public dialog: MatDialog) { }

  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    this.dialog.open(NotAvailableDialogComponent);
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
    if (this.newButtonOne == true) {
      this.newButtonOne = false
    }
    if (this.newButtonTwo == true) {
      this.newButtonTwo = false
    }
  }

  openLearnMoreOne(index: number) {
    this.newButtonOne = !this.newButtonOne;
    this.open = true;
    this.isShow = false;
    this.showLevelOne = true;
    this.showLevelTwo = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  openLearnMoreTwo(index: number) {
    this.newButtonTwo = !this.newButtonTwo;
    this.open = true;
    this.isShow = false;
    this.showLevelTwo = true;
    this.showLevelOne = false;
    this.sidenav.open();
    this.tabIndex = index;
  }
}
