/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddendumOneDto } from '../models/addendum-one-dto';
import { AddendumOneReferenceDto } from '../models/addendum-one-reference-dto';
import { AddendumThreeDto } from '../models/addendum-three-dto';
import { AddendumTwoDto } from '../models/addendum-two-dto';
import { AddendumTwoReferenceDto } from '../models/addendum-two-reference-dto';
import { AdditionalPolicyPlanFigureDto } from '../models/additional-policy-plan-figure-dto';
import { AreaAvailableDto } from '../models/area-available-dto';
import { CostEstimatesDto } from '../models/cost-estimates-dto';
import { CurrentConstructionScopeDto } from '../models/current-construction-scope-dto';
import { DiagramAndPlanDto } from '../models/diagram-and-plan-dto';
import { ExistingLandUseDto } from '../models/existing-land-use-dto';
import { ExistingZoningControlsDto } from '../models/existing-zoning-controls-dto';
import { ExportReportDto } from '../models/export-report-dto';
import { ImportantFactorsToConsiderDto } from '../models/important-factors-to-consider-dto';
import { ImprovementOptionDto } from '../models/improvement-option-dto';
import { OtherUnusableSpaceDto } from '../models/other-unusable-space-dto';
import { PlanningReportDto } from '../models/planning-report-dto';
import { PlanningReportReferenceDto } from '../models/planning-report-reference-dto';
import { PotentialConstructionScopeDto } from '../models/potential-construction-scope-dto';
import { ProposedDevelopmentScenarioDto } from '../models/proposed-development-scenario-dto';
import { ServitudeDto } from '../models/servitude-dto';
import { SiteAccessDto } from '../models/site-access-dto';
import { SpatialFrameworkAndPolicyDto } from '../models/spatial-framework-and-policy-dto';
import { WayForwardDto } from '../models/way-forward-dto';
import { ZoningMapDto } from '../models/zoning-map-dto';

@Injectable({
  providedIn: 'root',
})
export class PlanningReportService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1PlanningReportGetPlanningReportDetailsGet
   */
  static readonly ApiV1PlanningReportGetPlanningReportDetailsGetPath = '/api/v1/PlanningReport/GetPlanningReportDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetPlanningReportDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetPlanningReportDetailsGet$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<PlanningReportDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetPlanningReportDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<PlanningReportDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetPlanningReportDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetPlanningReportDetailsGet(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PlanningReportDto> {

    return this.apiV1PlanningReportGetPlanningReportDetailsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<PlanningReportDto>) => r.body as PlanningReportDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportAddOrUpdatePlanningReportPost
   */
  static readonly ApiV1PlanningReportAddOrUpdatePlanningReportPostPath = '/api/v1/PlanningReport/AddOrUpdatePlanningReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportAddOrUpdatePlanningReportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdatePlanningReportPost$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: PlanningReportDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportAddOrUpdatePlanningReportPostPath, 'post');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportAddOrUpdatePlanningReportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdatePlanningReportPost(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: PlanningReportDto
  }
): Observable<void> {

    return this.apiV1PlanningReportAddOrUpdatePlanningReportPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPost
   */
  static readonly ApiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPostPath = '/api/v1/PlanningReport/AddOrUpdatePropertySpecificReportAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPost$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: PlanningReportDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPostPath, 'post');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPost(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: PlanningReportDto
  }
): Observable<void> {

    return this.apiV1PlanningReportAddOrUpdatePropertySpecificReportAsyncPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportAddOrUpdateGeneralReportPost
   */
  static readonly ApiV1PlanningReportAddOrUpdateGeneralReportPostPath = '/api/v1/PlanningReport/AddOrUpdateGeneralReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportAddOrUpdateGeneralReportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdateGeneralReportPost$Response(params?: {
    multipleListingId?: string;
    context?: HttpContext
    body?: PlanningReportDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportAddOrUpdateGeneralReportPostPath, 'post');
    if (params) {
      rb.query('multipleListingId', params.multipleListingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportAddOrUpdateGeneralReportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdateGeneralReportPost(params?: {
    multipleListingId?: string;
    context?: HttpContext
    body?: PlanningReportDto
  }
): Observable<void> {

    return this.apiV1PlanningReportAddOrUpdateGeneralReportPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetExistingLandUseGet
   */
  static readonly ApiV1PlanningReportGetExistingLandUseGetPath = '/api/v1/PlanningReport/GetExistingLandUse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetExistingLandUseGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetExistingLandUseGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<ExistingLandUseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetExistingLandUseGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<ExistingLandUseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetExistingLandUseGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetExistingLandUseGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<ExistingLandUseDto> {

    return this.apiV1PlanningReportGetExistingLandUseGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<ExistingLandUseDto>) => r.body as ExistingLandUseDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetExistingZoningControlsGet
   */
  static readonly ApiV1PlanningReportGetExistingZoningControlsGetPath = '/api/v1/PlanningReport/GetExistingZoningControls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetExistingZoningControlsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetExistingZoningControlsGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<ExistingZoningControlsDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetExistingZoningControlsGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<ExistingZoningControlsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetExistingZoningControlsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetExistingZoningControlsGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<ExistingZoningControlsDto> {

    return this.apiV1PlanningReportGetExistingZoningControlsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<ExistingZoningControlsDto>) => r.body as ExistingZoningControlsDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetZoningMapGet
   */
  static readonly ApiV1PlanningReportGetZoningMapGetPath = '/api/v1/PlanningReport/GetZoningMap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetZoningMapGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetZoningMapGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<ZoningMapDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetZoningMapGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<ZoningMapDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetZoningMapGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetZoningMapGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<ZoningMapDto> {

    return this.apiV1PlanningReportGetZoningMapGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<ZoningMapDto>) => r.body as ZoningMapDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetSpatialFrameworksGet
   */
  static readonly ApiV1PlanningReportGetSpatialFrameworksGetPath = '/api/v1/PlanningReport/GetSpatialFrameworks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetSpatialFrameworksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetSpatialFrameworksGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<SpatialFrameworkAndPolicyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetSpatialFrameworksGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<SpatialFrameworkAndPolicyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetSpatialFrameworksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetSpatialFrameworksGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<Array<SpatialFrameworkAndPolicyDto>> {

    return this.apiV1PlanningReportGetSpatialFrameworksGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<SpatialFrameworkAndPolicyDto>>) => r.body as Array<SpatialFrameworkAndPolicyDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetAdditionalPoliciesGet
   */
  static readonly ApiV1PlanningReportGetAdditionalPoliciesGetPath = '/api/v1/PlanningReport/GetAdditionalPolicies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetAdditionalPoliciesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAdditionalPoliciesGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AdditionalPolicyPlanFigureDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetAdditionalPoliciesGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AdditionalPolicyPlanFigureDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetAdditionalPoliciesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAdditionalPoliciesGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<Array<AdditionalPolicyPlanFigureDto>> {

    return this.apiV1PlanningReportGetAdditionalPoliciesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AdditionalPolicyPlanFigureDto>>) => r.body as Array<AdditionalPolicyPlanFigureDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetAreaAvailableGet
   */
  static readonly ApiV1PlanningReportGetAreaAvailableGetPath = '/api/v1/PlanningReport/GetAreaAvailable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetAreaAvailableGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAreaAvailableGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AreaAvailableDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetAreaAvailableGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AreaAvailableDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetAreaAvailableGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAreaAvailableGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<AreaAvailableDto> {

    return this.apiV1PlanningReportGetAreaAvailableGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AreaAvailableDto>) => r.body as AreaAvailableDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetImprovementOptionsGet
   */
  static readonly ApiV1PlanningReportGetImprovementOptionsGetPath = '/api/v1/PlanningReport/GetImprovementOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetImprovementOptionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetImprovementOptionsGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<ImprovementOptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetImprovementOptionsGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<ImprovementOptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetImprovementOptionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetImprovementOptionsGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<Array<ImprovementOptionDto>> {

    return this.apiV1PlanningReportGetImprovementOptionsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<ImprovementOptionDto>>) => r.body as Array<ImprovementOptionDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetProposedDevelopmentScenariosGet
   */
  static readonly ApiV1PlanningReportGetProposedDevelopmentScenariosGetPath = '/api/v1/PlanningReport/GetProposedDevelopmentScenarios';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetProposedDevelopmentScenariosGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetProposedDevelopmentScenariosGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<ProposedDevelopmentScenarioDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetProposedDevelopmentScenariosGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<ProposedDevelopmentScenarioDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetProposedDevelopmentScenariosGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetProposedDevelopmentScenariosGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<Array<ProposedDevelopmentScenarioDto>> {

    return this.apiV1PlanningReportGetProposedDevelopmentScenariosGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<ProposedDevelopmentScenarioDto>>) => r.body as Array<ProposedDevelopmentScenarioDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetCurrentConstructionScopeGet
   */
  static readonly ApiV1PlanningReportGetCurrentConstructionScopeGetPath = '/api/v1/PlanningReport/GetCurrentConstructionScope';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetCurrentConstructionScopeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetCurrentConstructionScopeGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<CurrentConstructionScopeDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetCurrentConstructionScopeGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<CurrentConstructionScopeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetCurrentConstructionScopeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetCurrentConstructionScopeGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<CurrentConstructionScopeDto> {

    return this.apiV1PlanningReportGetCurrentConstructionScopeGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<CurrentConstructionScopeDto>) => r.body as CurrentConstructionScopeDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetPotentialConstructionScopesGet
   */
  static readonly ApiV1PlanningReportGetPotentialConstructionScopesGetPath = '/api/v1/PlanningReport/GetPotentialConstructionScopes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetPotentialConstructionScopesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetPotentialConstructionScopesGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<PotentialConstructionScopeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetPotentialConstructionScopesGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<PotentialConstructionScopeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetPotentialConstructionScopesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetPotentialConstructionScopesGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<Array<PotentialConstructionScopeDto>> {

    return this.apiV1PlanningReportGetPotentialConstructionScopesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<PotentialConstructionScopeDto>>) => r.body as Array<PotentialConstructionScopeDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetImportantFactorsToConsiderGet
   */
  static readonly ApiV1PlanningReportGetImportantFactorsToConsiderGetPath = '/api/v1/PlanningReport/GetImportantFactorsToConsider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetImportantFactorsToConsiderGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetImportantFactorsToConsiderGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<ImportantFactorsToConsiderDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetImportantFactorsToConsiderGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<ImportantFactorsToConsiderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetImportantFactorsToConsiderGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetImportantFactorsToConsiderGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<ImportantFactorsToConsiderDto> {

    return this.apiV1PlanningReportGetImportantFactorsToConsiderGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<ImportantFactorsToConsiderDto>) => r.body as ImportantFactorsToConsiderDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetCostEstimatesGet
   */
  static readonly ApiV1PlanningReportGetCostEstimatesGetPath = '/api/v1/PlanningReport/GetCostEstimates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetCostEstimatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetCostEstimatesGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<CostEstimatesDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetCostEstimatesGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<CostEstimatesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetCostEstimatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetCostEstimatesGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<CostEstimatesDto> {

    return this.apiV1PlanningReportGetCostEstimatesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<CostEstimatesDto>) => r.body as CostEstimatesDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetWayForwardGet
   */
  static readonly ApiV1PlanningReportGetWayForwardGetPath = '/api/v1/PlanningReport/GetWayForward';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetWayForwardGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetWayForwardGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<WayForwardDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetWayForwardGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<WayForwardDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetWayForwardGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetWayForwardGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<WayForwardDto> {

    return this.apiV1PlanningReportGetWayForwardGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<WayForwardDto>) => r.body as WayForwardDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetPlanningReportReferencesGet
   */
  static readonly ApiV1PlanningReportGetPlanningReportReferencesGetPath = '/api/v1/PlanningReport/GetPlanningReportReferences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetPlanningReportReferencesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetPlanningReportReferencesGet$Response(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<PlanningReportReferenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetPlanningReportReferencesGetPath, 'get');
    if (params) {
      rb.query('planningReportId', params.planningReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<PlanningReportReferenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetPlanningReportReferencesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetPlanningReportReferencesGet(params?: {
    planningReportId?: string;
    context?: HttpContext
  }
): Observable<Array<PlanningReportReferenceDto>> {

    return this.apiV1PlanningReportGetPlanningReportReferencesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<PlanningReportReferenceDto>>) => r.body as Array<PlanningReportReferenceDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetAddendumOneDetailsGet
   */
  static readonly ApiV1PlanningReportGetAddendumOneDetailsGetPath = '/api/v1/PlanningReport/GetAddendumOneDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetAddendumOneDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumOneDetailsGet$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AddendumOneDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetAddendumOneDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AddendumOneDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetAddendumOneDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumOneDetailsGet(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<AddendumOneDto> {

    return this.apiV1PlanningReportGetAddendumOneDetailsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AddendumOneDto>) => r.body as AddendumOneDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportAddOrUpdateAddendumOnePost
   */
  static readonly ApiV1PlanningReportAddOrUpdateAddendumOnePostPath = '/api/v1/PlanningReport/AddOrUpdateAddendumOne';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportAddOrUpdateAddendumOnePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdateAddendumOnePost$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: AddendumOneDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportAddOrUpdateAddendumOnePostPath, 'post');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportAddOrUpdateAddendumOnePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdateAddendumOnePost(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: AddendumOneDto
  }
): Observable<void> {

    return this.apiV1PlanningReportAddOrUpdateAddendumOnePost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetOtherUnusableSpacesGet
   */
  static readonly ApiV1PlanningReportGetOtherUnusableSpacesGetPath = '/api/v1/PlanningReport/GetOtherUnusableSpaces';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetOtherUnusableSpacesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetOtherUnusableSpacesGet$Response(params?: {
    addendumOneId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<OtherUnusableSpaceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetOtherUnusableSpacesGetPath, 'get');
    if (params) {
      rb.query('addendumOneId', params.addendumOneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<OtherUnusableSpaceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetOtherUnusableSpacesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetOtherUnusableSpacesGet(params?: {
    addendumOneId?: string;
    context?: HttpContext
  }
): Observable<Array<OtherUnusableSpaceDto>> {

    return this.apiV1PlanningReportGetOtherUnusableSpacesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<OtherUnusableSpaceDto>>) => r.body as Array<OtherUnusableSpaceDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetSiteAccessesGet
   */
  static readonly ApiV1PlanningReportGetSiteAccessesGetPath = '/api/v1/PlanningReport/GetSiteAccesses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetSiteAccessesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetSiteAccessesGet$Response(params?: {
    addendumOneId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<SiteAccessDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetSiteAccessesGetPath, 'get');
    if (params) {
      rb.query('addendumOneId', params.addendumOneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<SiteAccessDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetSiteAccessesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetSiteAccessesGet(params?: {
    addendumOneId?: string;
    context?: HttpContext
  }
): Observable<Array<SiteAccessDto>> {

    return this.apiV1PlanningReportGetSiteAccessesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<SiteAccessDto>>) => r.body as Array<SiteAccessDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetAddendumOneReferencesGet
   */
  static readonly ApiV1PlanningReportGetAddendumOneReferencesGetPath = '/api/v1/PlanningReport/GetAddendumOneReferences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetAddendumOneReferencesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumOneReferencesGet$Response(params?: {
    addendumOneId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AddendumOneReferenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetAddendumOneReferencesGetPath, 'get');
    if (params) {
      rb.query('addendumOneId', params.addendumOneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AddendumOneReferenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetAddendumOneReferencesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumOneReferencesGet(params?: {
    addendumOneId?: string;
    context?: HttpContext
  }
): Observable<Array<AddendumOneReferenceDto>> {

    return this.apiV1PlanningReportGetAddendumOneReferencesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AddendumOneReferenceDto>>) => r.body as Array<AddendumOneReferenceDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetAddendumTwoDetailsGet
   */
  static readonly ApiV1PlanningReportGetAddendumTwoDetailsGetPath = '/api/v1/PlanningReport/GetAddendumTwoDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetAddendumTwoDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumTwoDetailsGet$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AddendumTwoDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetAddendumTwoDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AddendumTwoDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetAddendumTwoDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumTwoDetailsGet(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<AddendumTwoDto> {

    return this.apiV1PlanningReportGetAddendumTwoDetailsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AddendumTwoDto>) => r.body as AddendumTwoDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportAddOrUpdateAddendumTwoPost
   */
  static readonly ApiV1PlanningReportAddOrUpdateAddendumTwoPostPath = '/api/v1/PlanningReport/AddOrUpdateAddendumTwo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportAddOrUpdateAddendumTwoPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdateAddendumTwoPost$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: AddendumTwoDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportAddOrUpdateAddendumTwoPostPath, 'post');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportAddOrUpdateAddendumTwoPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdateAddendumTwoPost(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: AddendumTwoDto
  }
): Observable<void> {

    return this.apiV1PlanningReportAddOrUpdateAddendumTwoPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetServitudesGet
   */
  static readonly ApiV1PlanningReportGetServitudesGetPath = '/api/v1/PlanningReport/GetServitudes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetServitudesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetServitudesGet$Response(params?: {
    addendumTwoId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<ServitudeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetServitudesGetPath, 'get');
    if (params) {
      rb.query('addendumTwoId', params.addendumTwoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<ServitudeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetServitudesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetServitudesGet(params?: {
    addendumTwoId?: string;
    context?: HttpContext
  }
): Observable<Array<ServitudeDto>> {

    return this.apiV1PlanningReportGetServitudesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<ServitudeDto>>) => r.body as Array<ServitudeDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetDiagramsAndPlansGet
   */
  static readonly ApiV1PlanningReportGetDiagramsAndPlansGetPath = '/api/v1/PlanningReport/GetDiagramsAndPlans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetDiagramsAndPlansGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetDiagramsAndPlansGet$Response(params?: {
    addendumTwoId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<DiagramAndPlanDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetDiagramsAndPlansGetPath, 'get');
    if (params) {
      rb.query('addendumTwoId', params.addendumTwoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<DiagramAndPlanDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetDiagramsAndPlansGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetDiagramsAndPlansGet(params?: {
    addendumTwoId?: string;
    context?: HttpContext
  }
): Observable<Array<DiagramAndPlanDto>> {

    return this.apiV1PlanningReportGetDiagramsAndPlansGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<DiagramAndPlanDto>>) => r.body as Array<DiagramAndPlanDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetAddendumTwoReferencesGet
   */
  static readonly ApiV1PlanningReportGetAddendumTwoReferencesGetPath = '/api/v1/PlanningReport/GetAddendumTwoReferences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetAddendumTwoReferencesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumTwoReferencesGet$Response(params?: {
    addendumTwoId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AddendumTwoReferenceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetAddendumTwoReferencesGetPath, 'get');
    if (params) {
      rb.query('addendumTwoId', params.addendumTwoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AddendumTwoReferenceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetAddendumTwoReferencesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumTwoReferencesGet(params?: {
    addendumTwoId?: string;
    context?: HttpContext
  }
): Observable<Array<AddendumTwoReferenceDto>> {

    return this.apiV1PlanningReportGetAddendumTwoReferencesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AddendumTwoReferenceDto>>) => r.body as Array<AddendumTwoReferenceDto>)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetAddendumThreeDetailsGet
   */
  static readonly ApiV1PlanningReportGetAddendumThreeDetailsGetPath = '/api/v1/PlanningReport/GetAddendumThreeDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetAddendumThreeDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumThreeDetailsGet$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AddendumThreeDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetAddendumThreeDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AddendumThreeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetAddendumThreeDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetAddendumThreeDetailsGet(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<AddendumThreeDto> {

    return this.apiV1PlanningReportGetAddendumThreeDetailsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AddendumThreeDto>) => r.body as AddendumThreeDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportGetExportReportDetailsGet
   */
  static readonly ApiV1PlanningReportGetExportReportDetailsGetPath = '/api/v1/PlanningReport/GetExportReportDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportGetExportReportDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetExportReportDetailsGet$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<ExportReportDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportGetExportReportDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<ExportReportDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportGetExportReportDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PlanningReportGetExportReportDetailsGet(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<ExportReportDto> {

    return this.apiV1PlanningReportGetExportReportDetailsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<ExportReportDto>) => r.body as ExportReportDto)
    );
  }

  /**
   * Path part for operation apiV1PlanningReportAddOrUpdateExportReportPost
   */
  static readonly ApiV1PlanningReportAddOrUpdateExportReportPostPath = '/api/v1/PlanningReport/AddOrUpdateExportReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PlanningReportAddOrUpdateExportReportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdateExportReportPost$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: ExportReportDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningReportService.ApiV1PlanningReportAddOrUpdateExportReportPostPath, 'post');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PlanningReportAddOrUpdateExportReportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1PlanningReportAddOrUpdateExportReportPost(params?: {
    propertyListingId?: string;
    context?: HttpContext
    body?: ExportReportDto
  }
): Observable<void> {

    return this.apiV1PlanningReportAddOrUpdateExportReportPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

}
