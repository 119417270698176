<main class="main-container">
    <div class="heading py-3">
        Property Listing
    </div>
    <div class="text py-3">
        Thank you for choosing <span class="text-bold">Property Matrix</span>.
    </div>
    <div class="text-bold py-3">
        Completing this form should take 15-20 minutes in 4 easy steps:
    </div>
    <div class="steps-container py-3">
        <div *ngFor="let step of steps" class="step">
            <div class="step-text">
                Step
            </div>
            <div class="step-number">
                {{ step.number }}
            </div>
            <div class="text-bold">
                {{ step.title }}
            </div>
            <div class="text-bold">
                {{ step.subtitle }}
            </div>
        </div>
    </div>
    <div>
        <button (click)="switchListingType()" class="button uppercase">
            Switch Listing Type
        </button>
    </div>
    <div class="text">
        {{ this.listingType }}
    </div>
    <div *ngIf="listingType == 'agent-listing'" class="paragraph py-3">
        <div class="bold">
            For step 4, please have the following ready in PDF format:
        </div>
        <ul class="list-items">
            <li *ngFor="let item of agentListingItems">
                {{ item }}
            </li>
        </ul>
    </div>
    <div *ngIf="listingType == 'seller-listing'" class="paragraph py-3">
        <div class="bold">
            For step 4, please have the following ready in PDF format:
        </div>
        <ul class="list-items">
            <li *ngFor="let item of sellerListingItems">
                {{ item }}
            </li>
        </ul>
        <div class="bold uppercase coral-text pt-3">
            Important:
        </div>
        <ul class="list-items">
            <li *ngFor="let item of sellerListingImportantItems">
                {{ item }}
            </li>
        </ul>
        <div class="text-bold pt-5">
            Information provided will enable us to generate a sole and exclusive mandate for your signature
        </div>
    </div>
    <div class="pt-3">
        <button class="button uppercase">
            Continue
        </button>
    </div>
</main>