<div class="dialog-container">
    <div mat-dialog-title class="dialog-title-container">
        Please select a unit:
    </div>
    <div mat-dialog-content class="dialog-content-container">
        <div *ngFor="let unit of data.units">
            <button (click)="selectUnit(unit)" class="unit-button button" [ngClass]="{'active': selectedUnit === unit}">
                Unit {{ unit | removeLeadingZeros }}
            </button>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-actions-container">
        <button mat-button (click)="onCancel()" class="cancel-button">
            Cancel
        </button>
        <button mat-button (click)="onConfirm()" class="confirm-button" [disabled]="isConfirmDisabled()">
            Confirm
        </button>
    </div>
</div>