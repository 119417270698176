<main class="main-container">
    <div class="content">
        <div class="content-background"></div>
        <div class="content-inner">
            <div class="section-heading pb-3">
                Land Development Applications
            </div>
            <div class="sub-section-container paragraph">
                <div class="pb-3">
                    You may need to obtain the necessary legal rights or permissions from your local council to:
                    <ul class="list-items">
                        <li>
                            Utilize a property for a specific land use activity or business; or
                        </li>
                        <li>
                            Develop or improve the property by altering the current approved parameters, such as zoning,
                            height, density, etc.
                        </li>
                    </ul>
                </div>
                <div class="pb-3">
                    <div class="bold">Please note:</div>
                    <div>
                        It is essential for Property Matrix to conduct a planning investigation on the property before
                        providing you with a quotation. The cost of this investigation starts from R3 000. We conduct
                        this preliminary check to ensure that the highest and best use is proposed and that we do not
                        quote you for something that may not be feasible for the property.
                    </div>
                </div>
                <div class="pb-3">
                    <div class="bold">Planning Application Quote:</div>
                    <div>
                        Request a quotation below. A Level 1 property investigation report will accompany the quotation,
                        and one of our planners will reach out to discuss your specific needs or project, as well as the
                        planning application requirements.
                    </div>
                </div>
                <div class="pb-5">
                    <div class="bold uppercase">Our Offer:</div>
                    <div>
                        If the initial investigation leads to a planning application to obtain any of the proposed
                        rights, the amount already paid <b>will be deducted</b> from the professional fees charged by
                        the urban planner.
                    </div>
                </div>
                <div class="pb-2">
                    <div class="center-content">
                        <button (click)="openDialog()" class="button uppercase">
                            Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>