<mat-card [ngClass]="{'selected': selected}">
    <div class="card-heading-container">
        <span class="paragraph card-heading">
            {{ this.surveyorGeneralKey }}
        </span>
    </div>
    <div class="listing-image-container">
        <img [src]="listing?.image" (error)="onError($event)">
        <div *ngIf="!listing?.isPartOfMultiple" class="image-overlay-right">
            <div class="paragraph report-reference-number">
                {{ this.addressTitle }}
            </div>
            <div class="paragraph report-status">
                {{ listing?.lifeCycleState ?? 'On Time' }}
            </div>
        </div>
        <div *ngIf="listing?.isPartOfMultiple" class="image-overlay-left">
            <div class="multiple-icon-container col-2 p-0">
                <mat-icon class="material-icons-outlined multiple-icon">
                    holiday_village
                </mat-icon>
            </div>
        </div>
    </div>
    <div class="listing-address-container row">
        <div class="paragraph listing-address-title col-10">
            {{ this.addressTitle }}
        </div>
        <div class="circle-button-container col-2">
            <button *ngIf="!listing?.isPartOfMultiple" class="select-button" matTooltip="Select Property"
                matTooltipShowDelay="1000" [matMenuTriggerFor]="menu">
                <mat-icon class="circle-button">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="toggleSelectListing()">{{ getSelectButtonText() }}</button>
                <button mat-menu-item
                    (click)="listing.lifeCycleState === 'Archived' ? unarchiveListing() : archiveListing()">{{
                    getArchiveButtonText() }}</button>
            </mat-menu>
        </div>
    </div>
    <div class="paragraph listing-address">
        {{ this.completeAddress }}
    </div>
    <div *ngIf="!listing?.isPartOfMultiple" class="divider"></div>
    <div *ngIf="!listing?.isPartOfMultiple" class="paragraph listing-card-footer row">
        <div class="icon-container col-2 p-0">
            <mat-icon class="user-icon">person</mat-icon>
            <div class="role-icon" [ngStyle]="{'background-color': getCurrentUser().roleColor}">
                {{ getCurrentUser().roleInitial ?? 'P' }}
            </div>
        </div>
        <div class="paragraph professional-title col-8">
            {{ getCurrentUser().name ?? 'No professional available' }}
        </div>
        <div class="circle-button-container col-2">
            <button class="center-content next-button" (click)="goToListingOverview()">
                <mat-icon class="next-button-icon">keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
    <div *ngIf="listing?.isPartOfMultiple" class="paragraph listing-card-footer row">
        <div class="paragraph professional-title col-8">
            Property: {{ listing?.number ?? 'N/A' }}
        </div>
    </div>
</mat-card>