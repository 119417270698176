import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { SacPlanRegistrationDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { UserManagementService } from 'src/app/services/property-matrixV2/services';
import { Validators } from 'src/app/models/validation/validators';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';

export const ROUTES = {
  WEBSITE_USER_REGISTRATION: 'website-user-registration',
  ESTATE_AGENT_REGISTRATION: 'estate-agent-registration',
  URBAN_PLANNER_REGISTRATION: 'planner-registration',
  CONVEYANCER_REGISTRATION: 'conveyancer-registration',
  PROPERTY_VALUER_REGISTRATION: 'property-valuer-registration',
  BOND_ORIGINATOR_REGISTRATION: 'bond-originator-registration',
  ARCHITECTURAL_TECHNOLOGIST_REGISTRATION: 'architectural-technologist-registration'
};

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../../../css/2-modules/_forms.scss'],
})
export class RegisterComponent {

  @Output() pageNumberEvent = new EventEmitter<number>();

  registrationSubscription: Subscription;
  registrationType: SacPlanRegistrationDto;

  loading: boolean = false;
  isRsaResident: boolean = true;
  confirmConsent: boolean = false;

  userType: string = '';
  sacPlanRegNumber: string = '';

  emailPattern: string = GlobalConstants.emailPattern;
  passwordPattern: string = GlobalConstants.passwordPattern;

  validators = new Validators();

  form: UserDto = {
    confirmEmail: '',
    confirmMobile: '',
    email: '',
    idNumber: '',
    isSouthAfricanResident: this.isRsaResident,
    landLine: '',
    mobile: '',
    name: '',
    passportNumber: '',
    surname: '',
    userName: '',
    password: '',
    passwordConfirm: ''
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private keycloak: KeycloakService,
    private authService: UserManagementService,
    private registrationService: RegistrationService,
    private notificationService: NotificationService,
    private estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.userType = params['userType'];
    });
    if (this.userType === 'urban-planner') {
      this.receiveUrbanPlannerData();
    }
  }

  private async receiveUrbanPlannerData(): Promise<void> {
    this.registrationSubscription = this.registrationService.userData.subscribe(
      (urbanPlannerData) => {
        if (urbanPlannerData && urbanPlannerData.urbanPlanner) {
          this.form.urbanPlanner.sacPlanRegNumber = urbanPlannerData.urbanPlanner.sacPlanRegNumber || '';
          this.form.urbanPlanner.registrationType = urbanPlannerData.urbanPlanner.registrationType || null;
        }
      }
    );
  }

  onResidencyChange(event: any): void {
    this.isRsaResident = event.value;
    this.form.isSouthAfricanResident = this.isRsaResident
  }

  register() {
    if (this.userType === 'website-user') {
      this.registerWebsiteUser();
    }
    else if (this.userType === 'estate-agent') {
      this.registerEstateAgent();
    }
    else if (this.userType === 'urban-planner') {
      this.registerUrbanPlanner();
    }
    else {
      this.notificationService.showErrorMessage('Error', 'No user type selected.');
    }
  }

  private async registerWebsiteUser(): Promise<void> {
    this.loading = true;
    let userRegistrationForm: UserDto = { ...this.form };
    this.authService.apiV1UserManagementAddUserPost({
      body: userRegistrationForm
    }).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.notificationService.showSuccessMessage('Success', 'You have successfully registered with Property Matrix. Keep an eye on your email inbox.');
          this.keycloak.login();
        } else {
          if (result.errorMessage.includes('Duplicate entry')) {
            this.notificationService.showErrorMessage('Error', 'Duplicate entry. The user already exists.');
          } else {
            this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
          }
        }
        this.loading = false;
      },
      error: (_error) => {
        this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
        this.loading = false;
      }
    });
  }

  private async registerEstateAgent(): Promise<void> {
    this.loading = true;
    let userRegistrationForm: UserDto = { ...this.form };
    this.authService.apiV1UserManagementAddUserPost({
      body: userRegistrationForm
    }).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.estateAgentRegistrationStateService.setUserId(result.userId);
          this.router.navigate([ROUTES.ESTATE_AGENT_REGISTRATION]);
          this.loading = false;
        } else {
          if (result.errorMessage.includes('Duplicate entry')) {
            this.notificationService.showErrorMessage('Error', 'Duplicate entry. The user already exists.');
            this.loading = false;
          } else {
            this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
            this.loading = false;
          }
        }
      },
      error: (_error) => {
        this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
        this.loading = false;
      }
    });
  }

  private async registerUrbanPlanner(): Promise<void> {
    this.loading = true;
    let userRegistrationForm: UserDto = { ...this.form };
    this.authService.apiV1UserManagementAddUserPost({
      body: userRegistrationForm
    }).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.router.navigate([ROUTES.URBAN_PLANNER_REGISTRATION]);
          this.loading = false;
        } else {
          if (result.errorMessage.includes('Duplicate entry')) {
            this.notificationService.showErrorMessage('Error', 'Duplicate entry. The user already exists.');
            this.loading = false;
          } else {
            this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
            this.loading = false;
          }
        }
      },
      error: (_error) => {
        this.notificationService.showErrorMessage('Error', 'Something went wrong. Please try again.');
        this.loading = false;
      }
    });
  }
}
