import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { initialCostEstimatesText } from 'src/app/constants/data/cost-estimates-initial-data';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CostEstimatesDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-cost-estimates',
  templateUrl: './cost-estimates.component.html',
  styleUrls: ['./cost-estimates.component.scss']
})
export class CostEstimatesComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() costEstimatesChange = new EventEmitter<CostEstimatesDto>();

  costEstimatesData: CostEstimatesDto;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadCostEstimates();
  }

  async loadCostEstimates(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetCostEstimatesGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response: CostEstimatesDto) => {
        this.costEstimatesData = response;
        if (this.costEstimatesData?.description === '' || this.costEstimatesData?.description === null) {
          this.costEstimatesData.description = initialCostEstimatesText;
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load cost estimates data.');
      }
    });
  }

  emitChanges(): void {
    this.costEstimatesChange.emit(this.costEstimatesData);
  }
}
