<mat-sidenav-container (backdropClick)="closeNav()">
    <mat-sidenav class="container" #sidenav (keydown.escape)="closeNav()" position="end">
        <div class="row">
            <div class="col-11 pt-2">
                <h1 class="heading-1">PROPERTY TYPE (LAND USES)</h1>
            </div>
            <div class="col-1 btn-close-align pt-2">
                <button type="button" id="btnClose" class="btn-close btn-close" (click)="closeNav()" aria-label="Close">
                </button>
            </div>
        </div>
        <div class="row paragraph">
            <div class="col-3">
                <table>
                    <tr>
                        <th class="heading-5">BUSINESS & SERVICES</th>
                    </tr>
                    <tr>
                        <td><b>Retail</b></td>
                    </tr>
                    <tr>
                        <td>Shop, Market, Mail</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Administrative</b></td>
                    </tr>
                    <tr>
                        <td>Bank, Financial services</td>
                    </tr>
                    <tr>
                        <td>Office building/block/park</td>
                    </tr>
                    <tr>
                        <td>Call centre, Computers</td>
                    </tr>
                    <tr>
                        <td>Postal services, Internet café</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Beauty</b></td>
                    </tr>
                    <tr>
                        <td>Salon, Beauty/health spa</td>
                    </tr>
                    <tr>
                        <td><b>Vehicles</b></td>
                    </tr>
                    <tr>
                        <td>Filling station, Car wash</td>
                    </tr>
                    <tr>
                        <td>Services: Repair, Testing</td>
                    </tr>
                    <tr>
                        <td>Sales: Dealership, Display</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Food & Drinks</b></td>
                    </tr>
                    <tr>
                        <td>Food, Beverages, Alcohol</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Animal Care (excl. sales)</b></td>
                    </tr>
                    <tr>
                        <td>Vet, Rehab, SPCA</td>
                    </tr>
                    <tr>
                        <td>Kennels, Boarding</td>
                    </tr>
                    <tr>
                        <td>Grooming, Training</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Plants</b></td>
                    </tr>
                    <tr>
                        <td>Garden centre, Nursery</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Household Services</b></td>
                    </tr>
                    <tr>
                        <td>Laundry, Carpet/dry cleaners</td>
                    </tr>
                    <tr>
                        <td>Appliance repairs</td>
                    </tr>
                    <tr>
                        <td>Recycling centre</td>
                    </tr>
                </table>
                <table class="bottom-border">
                    <tr>
                        <td><b>Workshops</b></td>
                    </tr>
                    <tr>
                        <td>Lawn, Paint, Print, Plumbing</td>
                    </tr>
                </table>
            </div>
            <div class="col-3">
                <table>
                    <tr>
                        <th class="heading-5">RESIDENTIAL & LODGING</th>
                    </tr>
                    <tr>
                        <td><b>Single Residential</b></td>
                    </tr>
                    <tr>
                        <td>Dwelling house</td>
                    </tr>
                    <tr>
                        <td>House with 2nd dwelling</td>
                    </tr>
                    <tr>
                        <td>House with home business</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Multiple Residential</b></td>
                    </tr>
                    <tr>
                        <td>Estate, Complex</td>
                    </tr>
                    <tr>
                        <td>Block of flats</td>
                    </tr>
                    <tr>
                        <td>Old age/nursing home</td>
                    </tr>
                    <tr>
                        <td>Embassy, Consulate</td>
                    </tr>
                    <tr>
                        <td>Mobile units, Home park</td>
                    </tr>
                </table>
                <table class="bottom-border">
                    <tr>
                        <td><b>Temporary Accommodation</b></td>
                    </tr>
                    <tr>
                        <td>Guest & Short stay</td>
                    </tr>
                    <tr>
                        <td>Leisure: Resort, Camping</td>
                    </tr>
                </table>
                <div class="margin">
                    <table>
                        <tr>
                            <th class="heading-5">SPORT & ENTERTAINMENT</th>
                        </tr>
                        <tr>
                            <td><b>Sport</b></td>
                        </tr>
                        <tr>
                            <td>Facilities, Grounds</td>
                        </tr>
                    </table>
                    <table class="bottom-border">
                        <tr>
                            <td><b>Entertainment</b></td>
                        </tr>
                        <tr>
                            <td>Hall, Conference, Expo</td>
                        </tr>
                        <tr>
                            <td>Amusement, Entertainment</td>
                        </tr>
                    </table>
                </div>
                <div class="margin">
                    <table>
                        <tr>
                            <th class="heading-5">MEDICAL & WELFARE</th>
                        </tr>
                        <tr>
                            <td><b>Medical & Health Care</b></td>
                        </tr>
                        <tr>
                            <td>Pharmacy</td>
                        </tr>
                        <tr>
                            <td>Clinic, Medical suites</td>
                        </tr>
                        <tr>
                            <td>Hospital</td>
                        </tr>
                    </table>
                    <table class="bottom-border">
                        <tr>
                            <td><b>Institutional Welfare</b></td>
                        </tr>
                        <tr>
                            <td>Special care, Supervision</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-3">
                <table>
                    <tr>
                        <th class="heading-5">EDUCATION & RESEARCH</th>
                    </tr>
                    <tr>
                        <td><b>Education</b></td>
                    </tr>
                    <tr>
                        <td>After school, Day care</td>
                    </tr>
                    <tr>
                        <td>Pre-primary, Creche</td>
                    </tr>
                    <tr>
                        <td>Primary & Secondary</td>
                    </tr>
                    <tr>
                        <td>Tertiary, Technical</td>
                    </tr>
                </table>
                <table class="bottom-border">
                    <tr>
                        <td><b>Research & Development</b></td>
                    </tr>
                    <tr>
                        <td>Laboratory, Research inst.,</td>
                    </tr>
                    <tr>
                        <td>Library, Museums, Gallery</td>
                    </tr>
                </table>
                <div class="margin">
                    <table>
                        <tr>
                            <th class="heading-5">INDUSTRIAL</th>
                        </tr>
                        <tr>
                            <td><b>General Industry</b></td>
                        </tr>
                        <tr>
                            <td>Panel-beater, Scrap yard</td>
                        </tr>
                        <tr>
                            <td>Abattoir, Noxious</td>
                        </tr>
                        <tr>
                            <td>Other industrial</td>
                        </tr>
                    </table>
                    <table class="bottom-border">
                        <tr>
                            <td><b>Storage & Distribution</b></td>
                        </tr>
                        <tr>
                            <td>Containers, Self/public storage</td>
                        </tr>
                        <tr>
                            <td>Warehouse, Yards, Depot</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-3">
                <table>
                    <tr>
                        <th class="heading-5">TRANSPORT</th>
                    </tr>
                    <tr>
                        <td><b>Land</b></td>
                    </tr>
                    <tr>
                        <td>Parking site</td>
                    </tr>
                    <tr>
                        <td>Truck stop</td>
                    </tr>
                    <tr>
                        <td>Taxi rank</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Air</b></td>
                    </tr>
                    <tr>
                        <td>Airport, hanger</td>
                    </tr>
                    <tr>
                        <td>Helistop, Heliport</td>
                    </tr>
                </table>
                <table class="bottom-border">
                    <tr>
                        <td><b>Water</b></td>
                    </tr>
                    <tr>
                        <td>Shipping port, Boat house</td>
                    </tr>
                </table>
                <div class="margin">
                    <table>
                        <tr>
                            <th class="heading-5">COMMUNITY & RELIGIOUS</th>
                        </tr>
                        <tr>
                            <td><b>Community</b></td>
                        </tr>
                        <tr>
                            <td>Community centre</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td><b>Religious</b></td>
                        </tr>
                        <tr>
                            <td>Religious centre, Place of worship</td>
                        </tr>
                    </table>
                    <table class="bottom-border">
                        <tr>
                            <td><b>Funeral Services</b></td>
                        </tr>
                        <tr>
                            <td>Burial site, Pet cemetery</td>
                        </tr>
                        <tr>
                            <td>Crematorium, Funeral parlour</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <img src="../../../assets/images/home/Land Uses.png" class="sidenav-image">
    </mat-sidenav>
    <div class="page-1" *ngIf="page == 1">
        <div class="container-fluid background-image">
            <div class="container center-block">
                <div class="heading-1 text-center pb-3">
                    PLACE YOUR ORDER
                </div>
                <div class="heading-4 text-center pb-3">
                    To share in our database is entirely FREE!
                </div>
                <div class="center-content pb-4">
                    <div class="underline"></div>
                </div>
                <div class="paragraph pb-3">
                    <div class="bold">
                        Knowledge Advantage
                    </div>
                    <ul class="list-items">
                        <li *ngFor="let item of knowledgeAdvantageItems">
                            {{ item }}
                        </li>
                    </ul>
                </div>
                <div class="paragraph pb-3">
                    <div class="bold">
                        Unparalleled Menu
                    </div>
                    <ul class="list-items">
                        <li *ngFor="let item of unparalleledMenuItems">
                            {{ item }}
                        </li>
                    </ul>
                </div>
                <div class="paragraph pb-3">
                    <div class="bold">
                        Professional planning report
                    </div>
                    <ul class="list-items">
                        <li *ngFor="let item of professionalPlanningReportItems">
                            {{ item }}
                        </li>
                    </ul>
                </div>
                <div class="paragraph pb-4">
                    <div class="bold">
                        Multiple orders, maximum networking
                    </div>
                    <ul class="list-items">
                        <li *ngFor="let item of multipleOrdersMaximumNetworkingItems">
                            {{ item }}
                        </li>
                    </ul>
                </div>
                <div class="paragraph pb-5 text-center">
                    Subscribe below to embark on a journey of informed and efficient property searches.
                </div>
                <div class="center-content pb-3">
                    <button (click)="openDialog()" class="button uppercase">
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-sidenav-container>
<div *ngIf="page == 2" class="background container-fluid">
    <div class="heading-1 text-center pb-3">
        SUBSCRIBE
    </div>
    <div class="paragraph text-center pb-3">
        Congratulations on the smart decision to tap into the <b>Property Matrix</b> database.
    </div>
    <div class="paragraph text-center pb-4">
        <b>
            It will take about 5-10 minutes to complete your first subscription through 4 easy steps:
        </b>
    </div>
    <div class="row pb-4">
        <div class="col-3">
            <div class="numbers-right-border">
                <div class="heading-3 text-center charcoal">STEP</div>
                <div class="numbers text-center">1</div>
                <div class="heading-5 text-center">Required</div>
            </div>
        </div>
        <div class="col-3">
            <div class="numbers-right-border">
                <div class="heading-3 text-center charcoal">STEP</div>
                <div class="numbers text-center">2</div>
                <div class="heading-5 text-center">Kick off with subscription one</div>
            </div>
        </div>
        <div class="col-3">
            <div class="numbers-right-border">
                <div class="heading-3 text-center charcoal">STEP</div>
                <div class="numbers text-center">3</div>
                <div class="heading-5 text-center">Approve subscription</div>
            </div>
        </div>
        <div class="col-3">
            <div class="heading-3 text-center charcoal">STEP</div>
            <div class="numbers text-center">4</div>
            <div class="heading-5 text-center">Add subscriptions or submit</div>
        </div>
    </div>
    <div class="pb-3 note-style">
        <div class="heading-5"><b>Please note:</b></div>
        <ul class="montserrat-bullets paragraph">
            <li>
                Multiple subscriptions will be required if you would like to be notified of different property
                types/sectors within different locations.
            </li>
            <li>
                Property Matrix will not spam you - your selections will determine the number of notification emails you
                are about to receive.
            </li>
            <li>
                You will receive log-in details via email to add, delete or edit subscriptions at any given time.
            </li>
        </ul>
    </div>
    <div class="text-center">
        <button class="button heading-4" (click)="continueToForm()">PROCEED</button>
    </div>
</div>