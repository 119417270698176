import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { ImprovementOptionDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-improvement-option',
  templateUrl: './improvement-option.component.html',
  styleUrls: ['./improvement-option.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ImprovementOptionComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() improvementOptionChange = new EventEmitter<ImprovementOptionDto[]>();

  improvementOptionData: ImprovementOptionDto[] = [];
  maxImprovementOptions: number = 9;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadImprovementOptionDetails();
  }

  addItemToArray(array: any[], item: any): void {
    array.push(item);
    this.emitChanges();
  }

  removeItemFromArray(array: any[], index: number): void {
    if (index >= 0 && index < array.length) {
      array.splice(index, 1);
      this.emitChanges();
    }
  }

  createImprovementOption(): void {
    const newImprovementOption: ImprovementOptionDto = {
      id: uuidv4(),
      landUseApplication: '',
      possibleDevelopmentEnhancement: '',
      timeFrame: ''
    };
    this.addItemToArray(this.improvementOptionData, newImprovementOption);
  }

  addImprovementOption(): void {
    if (this.improvementOptionData.length < this.maxImprovementOptions) {
      this.createImprovementOption();
    } else {
      this.notificationService.showWarningMessage('Improvement Option Limit Reached', 'You cannot add more than ' + this.maxImprovementOptions + ' improvement options.');
    }
  }

  async removeImprovementOption(index: number): Promise<void> {
    const confirmed = await this.notificationService.showConfirmMessage(
      'Confirm Deletion',
      'Are you sure you want to remove this item? This action cannot be undone.',
      'Remove',
      'Cancel'
    );

    if (!confirmed) {
      return;
    }

    this.removeItemFromArray(this.improvementOptionData, index);
  }

  loadImprovementOptionDetails(): void {
    this.planningReportService.apiV1PlanningReportGetImprovementOptionsGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: async (response) => {
        this.improvementOptionData = response;
        if (this.improvementOptionData === null || this.improvementOptionData.length === 0) {
          this.createImprovementOption();
        }
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load improvement options.');
      }
    });
  }

  emitChanges(): void {
    this.improvementOptionChange.emit(this.improvementOptionData);
  }
}
