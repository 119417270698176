import { AddendumOneDto, AddendumThreeDto, AddendumTwoDto, ExportReportDto, MultiplePropertyListingDto, PlanningReportDto, PropertyListingDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';
import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Component({
  selector: 'app-report-overview',
  templateUrl: './report-overview.component.html',
  styleUrls: ['./report-overview.component.scss']
})
export class ReportOverviewComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto;
  @Input() isMultiple: boolean;

  propertyListings: PropertyListingDto[];
  planningReportData: PlanningReportDto;
  generalPlanningReportData: PlanningReportDto;
  addendumOneData: AddendumOneDto;
  addendumTwoData: AddendumTwoDto;
  addendumThreeData: AddendumThreeDto;
  exportReportData: ExportReportDto;

  listingId: string;
  listingType: string;
  multipleListingId: string;
  reportTypeTabNumber: number = 1;
  reportItemTabNumber: number = 1;
  isGeneralInformation: boolean = true;

  loading: boolean = false;

  private listingIdSubject = new BehaviorSubject<any>(null);
  listingId$ = this.listingIdSubject.asObservable();

  reportTypes: KeyValuePair[] = [
    { key: 1, value: 'General Information' },
    { key: 2, value: 'Property Specific Information' }
  ];

  reportItems: KeyValuePair[] = [
    { key: 1, value: 'Planning Report' },
    { key: 2, value: 'Addendum 1' },
    { key: 3, value: 'Addendum 2' },
    { key: 4, value: 'Addendum 3' },
    { key: 5, value: 'Export Report' }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private planningReportService: PlanningReportService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });

    this.listingId$.subscribe(newListingId => {
      if (newListingId) {
        this.listingId = newListingId;
        this.fetchAllReportDetails();
      }
    });

    if (this.isMultiple) {
      this.propertyListings = this.selectedListingGroup?.propertyListings;
      this.multipleListingId = this.selectedListingGroup?.id;
      this.listingIdSubject.next(this.propertyListings?.[0]?.id);
    } else {
      this.listingId = this.selectedListing?.id;
      this.fetchAllReportDetails();
    }
  }

  updateSelectedListing(listingId: string): void {
    this.listingIdSubject.next(listingId);
  }

  async fetchAllReportDetails(): Promise<void> {
    if (this.listingId != null) {
      this.loading = true;

      const observables = [
        this.planningReportService.apiV1PlanningReportGetPlanningReportDetailsGet({ propertyListingId: this.listingId }),
        this.planningReportService.apiV1PlanningReportGetAddendumOneDetailsGet({ propertyListingId: this.listingId }),
        this.planningReportService.apiV1PlanningReportGetAddendumTwoDetailsGet({ propertyListingId: this.listingId }),
        this.planningReportService.apiV1PlanningReportGetAddendumThreeDetailsGet({ propertyListingId: this.listingId }),
        this.planningReportService.apiV1PlanningReportGetExportReportDetailsGet({ propertyListingId: this.listingId })
      ];

      forkJoin(observables).subscribe({
        next: (results: [PlanningReportDto, AddendumOneDto, AddendumTwoDto, AddendumThreeDto, ExportReportDto]) => {
          this.planningReportData = results[0];
          this.addendumOneData = results[1];
          this.addendumTwoData = results[2];
          this.addendumThreeData = results[3];
          this.exportReportData = results[4];

          if (this.isMultiple) {
            if (this.generalPlanningReportData == null) {
              this.generalPlanningReportData = this.planningReportData;
            } else {
              this.planningReportData.riskscapeExistingLandUseAndFeature = this.generalPlanningReportData.riskscapeExistingLandUseAndFeature;
              this.planningReportData.spatialFrameworksAndPolicies = this.generalPlanningReportData.spatialFrameworksAndPolicies;
              this.planningReportData.additionalPoliciesPlansFigures = this.generalPlanningReportData.additionalPoliciesPlansFigures;
              this.planningReportData.areaAvailable = this.generalPlanningReportData.areaAvailable;
              this.planningReportData.improvementOptions = this.generalPlanningReportData.improvementOptions;
              this.planningReportData.currentConstructionScope = this.generalPlanningReportData.currentConstructionScope;
              this.planningReportData.potentialConstructionScopes = this.generalPlanningReportData.potentialConstructionScopes;
              this.planningReportData.wayForward = this.generalPlanningReportData.wayForward;
              this.planningReportData.references = this.generalPlanningReportData.references;
            }
          }
          this.loading = false;
        },
        error: (_error): void => {
          this.notificationService.showErrorMessage('Error', 'Error fetching report details.');
          this.loading = false;
        }
      });
    }
  }

  goToListingOverview(): void {
    this.router.navigate(['admin/listings/listing-overview'], {
      queryParams: {
        listingType: this.listingType
      }
    });
  }

  navigateToReportTypeTab(index: number): void {
    this.reportTypeTabNumber = index;
    this.isGeneralInformation = (this.reportTypes.find(type => type.key === index)?.value === 'General Information');
  }

  navigateToReportItemTab(index: number): void {
    this.reportItemTabNumber = index;
    GlobalMethods.scrollToTop();
  }
}
