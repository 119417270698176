import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-listing',
  templateUrl: './property-listing.component.html',
  styleUrls: ['./property-listing.component.scss']
})
export class PropertyListingComponent implements OnInit {

  @Input() listingType: string;

  steps = [
    { number: 1, title: 'Ownership', subtitle: 'details' },
    { number: 2, title: 'Property', subtitle: 'information' },
    { number: 3, title: 'Contract', subtitle: 'details' },
    { number: 4, title: 'Upload', subtitle: 'documents' }
  ];

  agentListingItems = [
    'Sole and exclusive mandate, signed by duly authorized seller(s), indicating a fair market related asking price.',
    'Property information form - fully completed.',
    'If natural person(s): clear copy of seller\'s ID (or passport if not a resident of RSA). If married in community: copy of spouse\'s ID/passport.',
    'If a juristic person (company/cc/NPO): registration certificate and copy of resolution wherein the nominated representative is authorized by all applicable persons to act on behalf of such entity. If a trust: letter of authority.',
    'If the property forms part of a development opportunity, to be advertised with adjacent properties, a map (or image), indicating all properties and boundaries.',
    'For properties located in eThekwini municipality: a copy of the water & electricity bill.'
  ];

  sellerListingItems = [
    'Natural person(s): clear copy of seller\'s ID (or passport if not a resident of RSA). If married in community: copy of spouse\'s ID/passport.',
    'Juristic person (company/cc/NPO): registration certificate and copy of resolution wherein the nominated representative is authorized by all applicable persons to act on behalf of such entity. If a trust: letter of authority.',
    'If the property forms part of a development opportunity, to be advertised with adjacent properties, a map (or image), indicating all properties and boundaries.',
    'For properties located in eThekwini municipality: a copy of the water & electricity bill.'
  ];

  sellerListingImportantItems = [
    'To fill in this form you have to be logged-in as the owner (or an owner) of the property.',
    'Separate properties (land parcels) will each require an individual, separate form.',
    'If one of our brokers/agents referred you to Property Matrix, you may consult them to list this property on your behalf.',
    'There will always be a broker involved if you partner with us, collecting specialized information on your property.'
  ];

  constructor() { }

  ngOnInit(): void { }

  switchListingType(): void {
    this.listingType = this.listingType === 'agent-listing' ? 'seller-listing' : 'agent-listing';
  }
}
