<mat-card class="mt-3">
    <div class="form-group">
        <div class="col-12 heading-2">
            Zoning Map
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Figure Name</label>
                <input class="col-12 mt-1" type="text" [(ngModel)]="zoningMapData.name" (ngModelChange)="emitChanges()">
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <label class="col-12 bold">Figure Description</label>
                <textarea class="col-12 mt-1 text-area" [(ngModel)]="zoningMapData.description"
                    (ngModelChange)="emitChanges()">
                </textarea>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-12">
                <div class="upload-background">
                    <div class="upload-block" appDragAndDrop>
                        <input type="file" (change)="setFile($event)" class="upload-block-inner" multiple
                            accept="image/*" id="input" placeholder="image">
                        <div class="row">
                            <div class="col-12">
                                <mat-icon class="file-icon">cloud_upload</mat-icon>
                            </div>
                            <div class="col-12">
                                Click to upload.
                            </div>
                        </div>
                        <div class="row" *ngIf="fileUrl">
                            <div class="col-12">
                                <div class="image-upload">
                                    <img [src]="fileUrl" alt="Image Preview" id="preview" class="upload-block-inner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="hideButtons">
            <div class="col-12 button-group mt-3 mb-3">
                <a class="footer-button background-charcoal text-center" [href]="fileUrl" [download]="file?.name">
                    Download
                </a>
                <button type="button" class="footer-button background-coral" (click)="replace()">
                    Replace
                </button>
            </div>
        </div>
    </div>
</mat-card>