import { PotentialConstructionScopeDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';

@Component({
  selector: 'app-potential-land-parcel',
  templateUrl: './potential-land-parcel.component.html',
  styleUrls: ['./potential-land-parcel.component.scss']
})
export class PotentialLandParcelComponent implements OnInit {

  @Input() scope: PotentialConstructionScopeDto;
  @Input() index: number;
  @Input() amountOfLandParcels: number;
  @Input() substituteUsableArea: number;

  @Output() scopeChange = new EventEmitter<PotentialConstructionScopeDto>();

  squareMetersConversion: number = 10000;
  previousMetricUnit: string | null = null;
  potentialUsableAreaInHectares: number | null = null;
  potentialUsableAreaInSquareMeters: number | null = null;

  metricUnits: KeyValuePair[] = [
    { key: 'm²', value: 'Square Meters (m²)' },
    { key: 'ha', value: 'Hectares (ha)' },
  ];

  constructor() { }

  ngOnInit(): void {
    if (this.scope.metricUnit) {
      this.previousMetricUnit = this.scope.metricUnit;
    } else {
      this.scope.metricUnit = 'ha';
      this.previousMetricUnit = 'ha';
    }
    this.calculatePotentialUsableArea();
    this.calculateValues();
  }

  onMetricUnitChange(newUnit: string): void {
    this.scope.propertySize = this.convertArea(this.scope.propertySize, this.previousMetricUnit, newUnit);
    this.scope.unusableArea = this.convertArea(this.scope.unusableArea, this.previousMetricUnit, newUnit);
    this.previousMetricUnit = newUnit;
    this.scope.metricUnit = newUnit;
    this.calculatePotentialUsableArea();
  }

  onCalculationValueChange(): void {
    this.calculatePotentialUsableArea();
  }

  private calculatePotentialUsableArea(): void {
    let potentialUsableArea = this.scope.propertySize - this.scope.unusableArea;

    if (this.amountOfLandParcels === 1 && this.substituteUsableArea) {
      this.scope.potentialUsableArea = this.substituteUsableArea;
    } else {
      this.scope.potentialUsableArea = potentialUsableArea;
    }

    if (this.scope.potentialUsableArea < 0) {
      this.scope.potentialUsableArea = 0;
    }

    this.scope.potentialUsableArea = this.roundToFixed(this.scope.potentialUsableArea, 2);

    if (this.scope.metricUnit === 'ha') {
      this.potentialUsableAreaInHectares = this.convertArea(this.scope.potentialUsableArea, this.scope.metricUnit, 'ha');
      this.scope.potentialUsableArea = this.roundToFixed(this.potentialUsableAreaInHectares, 2);
    }

    if (this.scope.metricUnit === 'm²') {
      this.potentialUsableAreaInSquareMeters = this.convertArea(this.scope.potentialUsableArea, this.scope.metricUnit, 'm²');
      this.scope.potentialUsableArea = this.roundToFixed(this.potentialUsableAreaInSquareMeters, 2);
    }

    this.calculateValues();
  }

  calculateValues(): void {
    if (this.scope) {
      this.scope.potentialMaximumOpenSpaceRequired = Math.max(0, parseFloat((this.potentialUsableAreaInHectares * (this.scope.potentialOpenSpaceRequired / 100)).toFixed(2)));
      this.scope.potentialMaximumUnitsOnUsableSpace = Math.max(0, Math.round(this.potentialUsableAreaInHectares * this.scope.potentialDensity));
      this.scope.potentialMaximumSquareMetersBasedOnFloorAreaRatio = Math.max(0, parseFloat(((this.potentialUsableAreaInHectares * this.scope.potentialFloorAreaRatio) * this.squareMetersConversion).toFixed(2)));
      this.scope.potentialMaximumSquareMetersBasedOnCoverageAndHeight = Math.max(0, parseFloat(((this.potentialUsableAreaInHectares * this.scope.potentialHeight * (this.scope.potentialCoverage / 100)) * this.squareMetersConversion).toFixed(2)));
      this.scope.potentialMaximumUnitsAverageSize = Math.max(0, parseFloat((this.scope.potentialMaximumSquareMetersBasedOnCoverageAndHeight / this.scope.potentialMaximumUnitsOnUsableSpace).toFixed(2)));

      this.scope.potentialParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.scope.potentialParkingSpaces * this.scope.potentialMaximumUnitsOnUsableSpace).toFixed(2)));
      this.scope.potentialVisitorsParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.scope.potentialVisitorsParkingSpaces * this.scope.potentialMaximumUnitsOnUsableSpace).toFixed(0)));
      this.scope.potentialParkingSpacesPerHundredSquareMetersWithMaxRights = Math.max(0, parseFloat(((this.scope.potentialMaximumSquareMetersBasedOnFloorAreaRatio / 100) * this.scope.potentialParkingSpacesPerHundredSquareMeters).toFixed(2)));
      this.scope.potentialTotalParkingSpacesWithMaxRights = Math.max(0, parseFloat((this.scope.potentialParkingSpacesWithMaxRights + this.scope.potentialParkingSpacesPerHundredSquareMetersWithMaxRights).toFixed(0)));
      this.scope.potentialParkingSpacesAvailableOnVacantLandWithMaxRights = Math.max(0, parseFloat(((1 - (this.scope.potentialCoverage / 100)) * ((this.potentialUsableAreaInHectares - this.scope.potentialMaximumOpenSpaceRequired) * this.squareMetersConversion) / 23).toFixed(0)));
    }
    this.emitChanges();
  }

  private convertArea(area: number, fromUnit: string, toUnit: string): number {
    if (fromUnit === 'ha' && toUnit === 'm²') {
      return area * 10000;
    } else if (fromUnit === 'm²' && toUnit === 'ha') {
      return area / 10000;
    } else {
      return area;
    }
  }

  private roundToFixed(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
  }

  emitChanges(): void {
    this.scopeChange.emit(this.scope);
  }
}
