import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit-selection-dialog',
  templateUrl: './unit-selection-dialog.component.html',
  styleUrls: ['./unit-selection-dialog.component.scss']
})
export class UnitSelectionDialogComponent implements OnInit {

  selectedUnit: any = null;

  constructor(
    public dialogRef: MatDialogRef<UnitSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { units: any[] }
  ) { }

  ngOnInit(): void {
    console.log(this.data.units);
  }

  selectUnit(unit: any): void {
    if (this.selectedUnit === unit) {
      this.selectedUnit = null;
    } else {
      this.selectedUnit = unit;
    }
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onConfirm(): void {
    if (this.selectedUnit) {
      this.dialogRef.close(this.selectedUnit);
    }
  }

  isConfirmDisabled(): boolean {
    return this.selectedUnit === null;
  }
}
