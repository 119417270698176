import { ProposedDevelopmentScenarioDto, SchemeDto, UseRightTypeDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { LookupService, PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-proposed-development-scenario',
  templateUrl: './proposed-development-scenario.component.html',
  styleUrls: ['./proposed-development-scenario.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ProposedDevelopmentScenarioComponent implements OnInit {

  @Input() planningReportId: string;
  @Input() municipality: string;
  @Input() amountOfLandParcels: number;
  @Input() proposedDevelopmentEnhancement: string;

  @Output() proposedDevelopmentScenariosChange = new EventEmitter<ProposedDevelopmentScenarioDto[]>();
  @Output() landParcelSelectionChange = new EventEmitter<number>();
  @Output() proposedDevelopmentEnhancementChange = new EventEmitter<string>();

  proposedDevelopmentScenarios: ProposedDevelopmentScenarioDto[] = [];

  schemeId: string | null = null;
  amountOfLandParcelsOption: number | null = null;
  previousAmountOfLandParcelsOption: number | null = null;
  proposedDevelopmentEnhancementText: string | null = null;

  constructor(
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    if (this.municipality != null && this.municipality !== '') {
      this.loadSchemeData();
    }
    if (this.amountOfLandParcels != null || this.amountOfLandParcels > 0) {
      this.amountOfLandParcelsOption = this.amountOfLandParcels;
    }
    if (this.proposedDevelopmentEnhancement != null) {
      this.proposedDevelopmentEnhancementText = this.proposedDevelopmentEnhancement;
    }
    this.loadProposedDevelopmentScenarios();
  }

  async loadProposedDevelopmentScenarios(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetProposedDevelopmentScenariosGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response) => {
        this.proposedDevelopmentScenarios = response;
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load proposed development scenarios.');
      }
    });
  }

  async loadSchemeData(): Promise<void> {
    this.lookupService.apiV1LookupGetSchemeDataGet({
      metroDescription: this.municipality
    }).subscribe({
      next: (response: SchemeDto[]) => {
        if (response.length > 0) {
          this.schemeId = response[0].id;
        } else {
          this.notificationService.showErrorMessage('Error', 'No scheme data found.');
        }
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load scheme data.');
      }
    });
  }

  async onLandParcelSelectionChange(value: number) {
    if (this.previousAmountOfLandParcelsOption === null) {
      this.previousAmountOfLandParcelsOption = this.amountOfLandParcelsOption;
    }

    const currentLength = this.proposedDevelopmentScenarios.length;

    if (value < currentLength) {
      const confirmed = await this.notificationService.showConfirmMessage(
        'Confirm Change',
        'Switching to fewer land parcels will cause the loss of data for the removed parcels. Do you want to proceed?',
        'Proceed',
        'Cancel'
      );

      if (!confirmed) {
        this.amountOfLandParcelsOption = this.previousAmountOfLandParcelsOption;
        return;
      }
    }

    this.previousAmountOfLandParcelsOption = value;
    this.amountOfLandParcelsOption = value;
    this.landParcelSelectionChange.emit(this.amountOfLandParcelsOption);

    if (value > 3) {
      this.proposedDevelopmentScenarios = [];
    } else {
      if (value < currentLength) {
        this.proposedDevelopmentScenarios.splice(value);
      } else {
        for (let i = currentLength; i < value; i++) {
          this.proposedDevelopmentScenarios.push(this.createProposedDevelopmentScenario());
        }
      }
    }

    this.emitChanges();
  }

  createProposedDevelopmentScenario(): ProposedDevelopmentScenarioDto {
    return {
      id: uuidv4(),
      additionalLandUseIds: [],
      coverage: null,
      density: null,
      floorAreaRatio: null,
      height: null,
      proposedLandUseIds: [],
      schemeId: this.schemeId,
      sizeOfLandParcel: null,
      zoningId: null,
    };
  }

  onProposedDevelopmentEnhancementChange(value: string) {
    this.proposedDevelopmentEnhancementText = value;
    this.proposedDevelopmentEnhancementChange.emit(this.proposedDevelopmentEnhancementText);
  }

  onScenarioChange(index: number, updatedScenario: ProposedDevelopmentScenarioDto) {
    this.proposedDevelopmentScenarios[index] = updatedScenario;
    this.emitChanges();
  }

  emitChanges(): void {
    this.proposedDevelopmentScenariosChange.emit(this.proposedDevelopmentScenarios);
  }
}
