import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: User;

  constructor(
    private userService: UserService,
    private authService: PropertyMatrixSharedService
  ) { }

  ngOnInit() {
    if (!this.user) {
      return;
    }
    if (this.user.userID == null) {
      this.user = this.authService.userValue;
    }
    this.userService
      .getById(this.user.userID)
      .pipe(first())
      .subscribe(user => {
        this.user = user;
      });
  }
}
