import { NotAvailableDialogComponent } from 'src/app/shared/components/not-available-dialog/not-available-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-town-planning-applications',
  templateUrl: './town-planning-applications.component.html',
  styleUrls: ['./town-planning-applications.component.scss']
})
export class TownPlanningApplicationsComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    this.dialog.open(NotAvailableDialogComponent);
  }
}
