import { AddendumOneDto, AddendumOneReferenceDto, BuildingRestrictionAreaDto, OtherUnusableSpaceDto, RiskscapeEmergencyServiceDto, RiskscapeSiteAttributeDto, SiteAccessDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { MatDialog } from '@angular/material/dialog';

import { ReportKeyDialogComponent } from '../report-key-dialog/report-key-dialog.component';

@Component({
  selector: 'app-addendum-one',
  templateUrl: './addendum-one.component.html',
  styleUrls: ['./addendum-one.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class AddendumOneComponent implements OnInit, OnChanges {

  @Input() addendumOneData: AddendumOneDto;
  @Input() listingId: string;

  @Output() tabNumberEvent = new EventEmitter<number>();

  addendumOneId: string = '';

  riskscapeEmergencyServiceData: RiskscapeEmergencyServiceDto[];
  buildingRestrictionData: BuildingRestrictionAreaDto;
  otherUnusableSpaceData: OtherUnusableSpaceDto[];
  riskscapeSiteAttributeData: RiskscapeSiteAttributeDto;
  siteAccessData: SiteAccessDto[];
  referenceData: AddendumOneReferenceDto[];

  loading: boolean = false;

  sections = [
    { id: 1, value: 'Distance To Emergency Services', checked: false },
    { id: 2, value: 'Building Restriction Areas', checked: false },
    { id: 3, value: 'Geotechnical Aspects', checked: false },
    { id: 4, value: 'Site Access & Engineering Services', checked: false },
    { id: 5, value: 'References', checked: false }
  ]

  constructor(
    public dialog: MatDialog,
    private planningReportService: PlanningReportService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.addendumOneId = this.addendumOneData?.id || '';
    this.loadAllAddendumOneData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.addendumOneData) {
      this.loadAllAddendumOneData();
    }
  }

  loadAllAddendumOneData() {
    this.riskscapeEmergencyServiceData = this.addendumOneData?.riskscapeSiteAttribute?.riskscapeEmergencyServices || [];
    this.buildingRestrictionData = this.addendumOneData?.buildingRestrictionArea || {};
    this.otherUnusableSpaceData = this.addendumOneData?.otherUnusableSpaces || [];
    this.riskscapeSiteAttributeData = this.addendumOneData?.riskscapeSiteAttribute || {};
    this.siteAccessData = this.addendumOneData?.siteAccesses || [];
    this.referenceData = this.addendumOneData?.references || [];
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  saveAddendumOneDetails() {
    this.loading = true;
    this.addendumOneData.buildingRestrictionArea = this.buildingRestrictionData;
    this.planningReportService.apiV1PlanningReportAddOrUpdateAddendumOnePost({
      propertyListingId: this.listingId,
      body: this.addendumOneData
    }).subscribe({
      next: () => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Successfully saved addendum one details.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not save addendum one details.');
      }
    });
  }

  handleOtherUnusableSpaceChange(event: OtherUnusableSpaceDto[]) {
    this.addendumOneData.otherUnusableSpaces = event;
  }

  handleSiteAccessChange(event: SiteAccessDto[]) {
    this.addendumOneData.siteAccesses = event;
  }

  handleReferenceChange(event: AddendumOneReferenceDto[]) {
    this.addendumOneData.references = event;
  }

  openReportKeyDialog(): void {
    this.dialog.open(ReportKeyDialogComponent, {
      width: '30vw',
      height: '35vh'
    });
  }

  goToPlanningReport() {
    this.tabNumberEvent.emit(1);
  }

  goToAddendumTwo() {
    this.tabNumberEvent.emit(3);
  }
}
