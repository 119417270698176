/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FooterDocumentDto } from '../models/footer-document-dto';
import { UploadFileDto } from '../models/upload-file-dto';

@Injectable({
  providedIn: 'root',
})
export class FileManagementService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1FileManagementUploadFilePost
   */
  static readonly ApiV1FileManagementUploadFilePostPath = '/api/v1/FileManagement/UploadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileManagementUploadFilePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementUploadFilePost$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UploadFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagementService.ApiV1FileManagementUploadFilePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UploadFileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileManagementUploadFilePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementUploadFilePost(params?: {
    context?: HttpContext
  }
): Observable<UploadFileDto> {

    return this.apiV1FileManagementUploadFilePost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UploadFileDto>) => r.body as UploadFileDto)
    );
  }

  /**
   * Path part for operation apiV1FileManagementUploadFilesPost
   */
  static readonly ApiV1FileManagementUploadFilesPostPath = '/api/v1/FileManagement/UploadFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileManagementUploadFilesPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementUploadFilesPost$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UploadFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagementService.ApiV1FileManagementUploadFilesPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UploadFileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileManagementUploadFilesPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementUploadFilesPost(params?: {
    context?: HttpContext
  }
): Observable<UploadFileDto> {

    return this.apiV1FileManagementUploadFilesPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UploadFileDto>) => r.body as UploadFileDto)
    );
  }

  /**
   * Path part for operation apiV1FileManagementDeleteFileFileIdDelete
   */
  static readonly ApiV1FileManagementDeleteFileFileIdDeletePath = '/api/v1/FileManagement/DeleteFile/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileManagementDeleteFileFileIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementDeleteFileFileIdDelete$Response(params: {
    fileId: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagementService.ApiV1FileManagementDeleteFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileManagementDeleteFileFileIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementDeleteFileFileIdDelete(params: {
    fileId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1FileManagementDeleteFileFileIdDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1FileManagementGetFileUrlGet
   */
  static readonly ApiV1FileManagementGetFileUrlGetPath = '/api/v1/FileManagement/GetFileUrl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileManagementGetFileUrlGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementGetFileUrlGet$Response(params?: {
    fileId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<string>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagementService.ApiV1FileManagementGetFileUrlGetPath, 'get');
    if (params) {
      rb.query('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileManagementGetFileUrlGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementGetFileUrlGet(params?: {
    fileId?: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.apiV1FileManagementGetFileUrlGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1FileManagementGetFileFileIdGet
   */
  static readonly ApiV1FileManagementGetFileFileIdGetPath = '/api/v1/FileManagement/GetFile/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileManagementGetFileFileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementGetFileFileIdGet$Response(params: {
    fileId: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<string>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagementService.ApiV1FileManagementGetFileFileIdGetPath, 'get');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileManagementGetFileFileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementGetFileFileIdGet(params: {
    fileId: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.apiV1FileManagementGetFileFileIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1FileManagementUploadFooterDocumentPost
   */
  static readonly ApiV1FileManagementUploadFooterDocumentPostPath = '/api/v1/FileManagement/UploadFooterDocument';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileManagementUploadFooterDocumentPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementUploadFooterDocumentPost$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagementService.ApiV1FileManagementUploadFooterDocumentPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileManagementUploadFooterDocumentPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementUploadFooterDocumentPost(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1FileManagementUploadFooterDocumentPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1FileManagementGetFooterDocumentsGet
   */
  static readonly ApiV1FileManagementGetFooterDocumentsGetPath = '/api/v1/FileManagement/GetFooterDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileManagementGetFooterDocumentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementGetFooterDocumentsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<FooterDocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, FileManagementService.ApiV1FileManagementGetFooterDocumentsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<FooterDocumentDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileManagementGetFooterDocumentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileManagementGetFooterDocumentsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<FooterDocumentDto>> {

    return this.apiV1FileManagementGetFooterDocumentsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<FooterDocumentDto>>) => r.body as Array<FooterDocumentDto>)
    );
  }

}
