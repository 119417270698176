<mat-sidenav-container (backdropClick)="close()">
    <main class="main-container">
        <div class="content">
            <div class="content-background"></div>
            <div class="content-inner">
                <div class="section-heading pb-3">
                    Feasibility Studies
                </div>
                <div class="section-text pb-5">
                    'Feasibility' refers to the degree or state of being easily, conveniently, or reasonably done.
                    Our team of experts can assist you in evaluating your project's potential success,considering
                    financial, market, legal and environmental factors.
                </div>
                <div class="section-sub-heading pb-3">
                    Options Available
                </div>
                <div class="row levels-container">
                    <div class="level col-6">
                        <div class="level-heading">
                            Level
                        </div>
                        <div class="level-number">
                            1
                        </div>
                        <div class="level-sub-heading">
                            Desktop feasibility analysis:
                        </div>
                        <div class="list-items">
                            <ul class="paragraph">
                                <li>15 to 20 page report</li>
                                <li>Desktop location analysis</li>
                                <li>Demographic overview</li>
                                <li>Market trend analysis</li>
                                <li>Comparative supply analysis</li>
                                <li>3 to 4 week delivery</li>
                            </ul>
                        </div>
                        <div class="section-text py-4">
                            R20k - R30k
                        </div>
                        <div class="learn-more-button">
                            <button class="button" (click)="openLearnMoreOne(0)">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                    <div class="level col-6">
                        <div class="level-heading">
                            Level
                        </div>
                        <div class="level-number">
                            2
                        </div>
                        <div class="level-sub-heading">
                            Full feasibility analysis:
                        </div>
                        <div class="list-items">
                            <ul class="paragraph">
                                <li>
                                    20 to 30 page report
                                </li>
                                <li>
                                    Includes all elements of Level 1 PLUS a visit to and analysis of the site and
                                    surrounds
                                </li>
                                <li>
                                    4 to 6 week delivery
                                </li>
                            </ul>
                        </div>
                        <div class="section-text py-4">
                            R40k - R70k
                        </div>
                        <div class="learn-more-button">
                            <button class="button" (click)="openLearnMoreTwo(0)">
                                LEARN MORE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <mat-sidenav #sidenav (keydown.escape)="close()" position="end">
        <section class="rotate-buttons" [hidden]="isShow">
            <button class="sidenav-button heading-2" [ngClass]="{'open':open, 'active': tabIndex === 0}"
                (click)="openLearnMoreOne(0)">
                LEVEL 1
            </button>
            <button class="sidenav-button heading-2" [ngClass]="{'open':open, 'active': tabIndex === 1}"
                (click)="openLearnMoreTwo(1)">
                LEVEL 2
            </button>
        </section>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevelOne">
            <div class="row py-3">
                <div class="col-11 feasibility-dialog-heading">
                    Our LEVEL 1 feasibility study generally incorporates the following:
                </div>
                <div class="col-1 btn-close-align">
                    <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close">
                    </button>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Note: </span>
                    <span>
                        Every site and every development opportunity is unique and will be treated on its own merits.
                        The Level 1 study excludes a visit to the site.
                    </span>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Phase 1: Data Collection
                </div>
                <div class="col-12 heading-5-light">
                    Land-use rights investigation:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Determining current and prospected zoning, acceptable future densities, exact boundaries,
                            servitudes, building restrictions etc. to determine the potential as well as the possible
                            constraints of your property and/or proposed development.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Location Analysis:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Desktop analysis: site and ease of access to analysis</li>
                        <li>Drive time analysis</li>
                        <li>Target area delineation</li>
                        <li>High-level assessment of construction activities in the area</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Demographic Overview:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Socio-economic overview of target area
                        </li>
                        <li>
                            Survey data includes age, race, population, household income, level of education,
                            employment, etc.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Phase 2: Data Analysis
                </div>
                <div class="col-12 heading-5-light">
                    Market trends
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Number of households</li>
                        <li>Household income distribution</li>
                        <li>Residential real estate market trends</li>
                        <li>Micro economic trends</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Detailed overview of comparative supply
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Location and overview of existing and planned comparative supply within the catchment area.
                        </li>
                        <li>
                            Location and overview of new and proposed residential and commercial developments within the
                            catchment area.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Phase 3: Full Feasibility Analysis
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Various elements of the proposed development are compared against the information collected.
                        </li>
                        <li>
                            The results will allow for a professional assessment of the potential feasibility of the
                            proposed project.
                        </li>
                        <li>
                            Comments and recommendations will be included in the report.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 heading-5-light uppercase">
                    Deliverable And Time-frames
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>15 to 20 page report</li>
                        <li>3 to 4 week delivery</li>
                        <li>
                            <span class="heading-5-light">Fee: </span>
                            <span>R20k - R30k (quotation-based, depending on specific needs and project scope)</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-3">
                <div class="center-content">
                    <button (click)="openDialog()" class="button button-light uppercase">
                        Request
                    </button>
                </div>
            </div>
        </div>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevelTwo">
            <div class="row py-3">
                <div class="col-11 feasibility-dialog-heading">
                    Our LEVEL 2 feasibility study generally incorporates the following:
                </div>
                <div class="col-1 btn-close-align">
                    <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close">
                    </button>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    <span class="heading-5-light">Note: </span>
                    <span>
                        Every site and every development opportunity is unique and will be treated on its own merits.
                        The Level 2 study includes a visit to the site and an assessment of surrounding areas.
                    </span>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Phase 1: Data Collection
                </div>
                <div class="col-12 heading-5-light">
                    Land-use rights investigation:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Determining current and prospected zoning, acceptable future densities, exact boundaries,
                            servitudes, building restrictions etc. to determine the potential as well as the possible
                            constraints of your property and/or proposed development.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Location Analysis:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Drive time analysis</li>
                        <li>Target area delineation</li>
                        <li>Site visit and ease-of-access analysis</li>
                        <li>Surrounding land-use assessment</li>
                        <li>Assessment of construction activities in vicinity</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Demographic Overview:
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Socio-economic overview of target area
                        </li>
                        <li>
                            Survey data includes age, race, population, household income, level of education,
                            employment, etc.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Phase 2: Data Analysis
                </div>
                <div class="col-12 heading-5-light">
                    Market trends
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Number of households</li>
                        <li>Household income distribution</li>
                        <li>Residential real estate market trends</li>
                        <li>Micro economic trends</li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light">
                    Detailed overview of comparative supply
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Location and overview of existing and planned comparative supply within the catchment area.
                        </li>
                        <li>
                            Location and overview of new and proposed residential and commercial developments within the
                            catchment area.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Phase 3: Full Feasibility Analysis
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>
                            Various elements of the proposed development are compared against the information collected.
                        </li>
                        <li>
                            The results will allow for a professional assessment of the potential feasibility of the
                            proposed project.
                        </li>
                        <li>
                            Comments and recommendations will be included in the report.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-12 heading-5-light uppercase">
                    Deliverable And Time-frames
                </div>
                <div class="col-12">
                    <ul class="list-items paragraph light">
                        <li>Visit the property: analysis of the site and surrounding areas</li>
                        <li>20 to 25-page report in PDF format</li>
                        <li>4 to 6 weeks for delivery</li>
                        <li>
                            <span class="heading-5-light">Fee: </span>
                            <span>R40k - R70k (quotation-based, depending on specific needs and project scope)</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12 paragraph light">
                    Request below and one of our development economists will give you a call to discuss your specific
                    site and project scope to provide you with a project-specific quote.
                </div>
            </div>
            <div class="row pb-3">
                <div class="center-content">
                    <button (click)="openDialog()" class="button button-light uppercase">
                        Request
                    </button>
                </div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>