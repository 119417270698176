<form id="contractDetailsForm" novalidate #contractDetails="ngForm" class="submission-form">
    <div class="heading-2">
        STEP 3: CONTRACT DETAILS
    </div>
    <div class="heading-3">
        Conveyancing
    </div>
    <span class="paragraph">
        We strongly recommend using our in house team of specialized conveyancers.
        Click <a class="here" (click)="openBenefitsOfLegalTeamDialog()">here</a> for benefits.
    </span>
    <div class="heading-5">
        <span class="burnt-red">| </span>
        <span>Will you make use of the Property Matrix legal team for conveyancing purposes?</span>
    </div>
    <div class="row">
        <mat-radio-group name="propertyGovernedByAssociation" [(ngModel)]="useInHouseLegalTeam">
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of useInHouseLegalTeamOptionsRadio"
                [value]="selectionValue.key" (change)="selectionValue.key == 2? openDisclaimerDialog() : undefined">
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="useInHouseLegalTeam == 1" class="submission-form">
        <span class="paragraph">
            Thank you for choosing Property Matrix to provide you with this service. Our conveyancing team will be in
            contact with you shortly
        </span>
    </div>

    <div *ngIf="useInHouseLegalTeam == 2" class="submission-form">
        <div class="heading-5">
            Please provide the following details of your preferred <span class="coral">conveyancer</span>:
        </div>
        <div class="row">
            <div class="col-4">
                <label id="lblCompanyName" for="txtCompanyName" class="paragraph">
                    Company name:
                </label>
            </div>
            <div class="col-8">
                <input type="text" placeholder="Mandatory" id="txtAgentName" class="paragraph" name="companyName"
                    required [(ngModel)]="form.conveyancerDetail.companyName">
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label id="lblContactPerson" for="txtContactPerson" class="paragraph">
                    Contact person:
                </label>
            </div>
            <div class="col-8">
                <input type="text" placeholder="Mandatory" id="txtContactPerson" class="paragraph" name="contactPerson"
                    required [(ngModel)]="form.conveyancerDetail.contactPerson">
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label id="lblCompanyEmailAddress" for="txtCompanyEmailAddress" class="paragraph">
                    Email Address:
                </label>
            </div>
            <div class="col-8">
                <input type="text" placeholder="Mandatory" id="txtCompanyEmailAddress" class="paragraph"
                    name="companyEmailAddress" required [pattern]="emailPattern"
                    [(ngModel)]="form.conveyancerDetail.emailAddress">
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label id="lblContactMobile" for="txtContactMobile" class="paragraph">
                    Contact number mobile:
                </label>
            </div>
            <div class="col-8">
                <input type="text" placeholder="Mandatory" id="txtContactMobile" class="paragraph" name="contactMobile"
                    required minlength="10" [(ngModel)]="form.conveyancerDetail.mobileNumber">
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label id="lblContactOffice" for="txtContactOffice" class="paragraph">
                    Contact number office:
                </label>
            </div>
            <div class="col-8">
                <input type="text" placeholder="Mandatory" id="txtContactOffice" class="paragraph" name="contactOffice"
                    required minlength="10" [(ngModel)]="form.conveyancerDetail.officeNumber">
            </div>
        </div>
    </div>
    <div *ngIf="useInHouseLegalTeam" class="heading-3">
        COMMISSION
    </div>
    <strong *ngIf="useInHouseLegalTeam" class="paragraph">
        <strong>
            <span class="coral">IMPORTANT</span>
        </strong>
        <br *ngIf="useInHouseLegalTeam" />
        Property Matrix is unique in the sense that our partnering agents are trained to collect specialized
        information. There will always be an agent involved and commission payable if you partner with us to sell your
        property. Our commission structure is as follows:
    </strong>
    <div *ngIf="useInHouseLegalTeam" class="row mb-1">
        <div class="col-8">
            <div class="commission-table">
                <table class="text-center paragraph">
                    <thead>
                        <tr>
                            <th>SELLING PRICE OF YOUR PROPERTY</th>
                            <th>% COMMISSION PAYABLE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tableData">
                            <td>{{item.priceRange}}</td>
                            <td>{{item.percentage}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <label *ngIf="useInHouseLegalTeam" class="checkbox-container">
        <strong class="paragraph">I accept the commission structure as set out above (calculated on the selling price of
            my property) and will not engage in any disputes regarding the commission payable.</strong>
        <input type="checkbox" [(ngModel)]="form.acceptCommissionStructure" name="troubleUploadingCopies"
            #acceptCommissionStructure="ngModel" id="acceptCommissionStructure" data-toggle="toggle" />
        <span class="checkmark"></span>
    </label>
    <div *ngIf="form.acceptCommissionStructure == true" class="submission-form">
        <div class="heading-5">
            <span class="burnt-red">| </span>
            <span>
                Were you introduced to Property Matrix by a partnering estate agent who recommended us to market this
                property?
            </span>
        </div>
        <div class="row">
            <mat-radio-group name="recommendedByPartnerAgent" [(ngModel)]="recommendedByPartnerAgent">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of recommendedByPartnerAgentOptionsRadio"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="submission-form">
            <div class="heading-5">
                <span class="burnt-red">| </span>
                <span>Are you already connected to any urban planner who you prefer to work with?</span>
            </div>
            <div class="row">
                <mat-radio-group name="connectedToUrbanPlanner" [(ngModel)]="connectedToUrbanPlanner">
                    <mat-radio-button class="paragraph"
                        *ngFor="let selectionValue of connectedToUrbanPlannerOptionsRadio" [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="connectedToUrbanPlanner == 1" class="submission-form">
            <div class="heading-5">
                Details of <span class="coral">urban planner:</span>
            </div>
            <div class="row">
                <div class="col-4">
                    <label id="lblUrbanPlannerName" for="txtUrbanPlannerName" class="paragraph">
                        Planner Name:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" placeholder="Mandatory" id="txtUrbanPlannerName" class="paragraph"
                        name="urbanPlannerName" required #urbanPlannerName="ngModel"
                        [(ngModel)]="form.urbanPlanner.plannerName"
                        [ngClass]="{'invalid-input': urbanPlannerName.invalid, 'valid-input': urbanPlannerName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label id="lblUrbanPlannerSurname" for="txtUrbanPlannerSurname" class="paragraph">
                        Planner surname:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" placeholder="Mandatory" id="txtUrbanPlannerSurname" class="paragraph"
                        name="urbanPlannerSurname" required #urbanPlannerSurname="ngModel"
                        [(ngModel)]="form.urbanPlanner.plannerSurname"
                        [ngClass]="{'invalid-input': urbanPlannerSurname.invalid, 'valid-input': urbanPlannerSurname.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label id="lblCompanyName" for="txtCompanyName" class="paragraph">
                        Company name:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" placeholder="Mandatory" id="txtAgentName" class="paragraph" name="companyName"
                        [(ngModel)]="form.urbanPlanner.companyName" required #companyName="ngModel"
                        [ngClass]="{'invalid-input': companyName.invalid, 'valid-input': companyName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label id="lblPlannerLocation" for="txtPlannerLocation" class="paragraph">
                        City/town where planner is located:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" placeholder="Mandatory" id="txtPlannerLocation" class="paragraph"
                        name="plannerLocation" required #plannerLocation="ngModel" [(ngModel)]="form.urbanPlanner.city"
                        [ngClass]="{'invalid-input': plannerLocation.invalid, 'valid-input': plannerLocation.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label id="lblUrbanPlannerEmailAddress" for="txtUrbanPlannerEmailAddress" class="paragraph">
                        Planner Email Address:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" placeholder="Mandatory" id="txtUrbanPlannerEmailAddress" class="paragraph"
                        name="urbanPlannerEmailAddress" required #urbanPlannerEmailAddress="ngModel"
                        [pattern]="emailPattern" [(ngModel)]="form.urbanPlanner.plannerEmail"
                        [ngClass]="{'invalid-input': urbanPlannerEmailAddress.invalid, 'valid-input': urbanPlannerEmailAddress.valid}">
                    <small class="form-control-feedback"
                        *ngIf="urbanPlannerEmailAddress.errors && (urbanPlannerEmailAddress.dirty || urbanPlannerEmailAddress.touched)">
                        *Must be a valid email address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label id="lblUrbanPlannerMobile" for="txtUrbanPlannerMobile" class="paragraph">
                        Planner mobile number:
                    </label>
                </div>
                <div class="col-8">
                    <input type="text" placeholder="Mandatory" id="txtUrbanPlannerMobile" class="paragraph"
                        name="urbanPlannerMobile" required #urbanPlannerMobile="ngModel" minlength="10"
                        [(ngModel)]="form.urbanPlanner.plannerMobile"
                        [ngClass]="{'invalid-input': urbanPlannerMobile.invalid, 'valid-input': urbanPlannerMobile.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(urbanPlannerMobile.errors && (urbanPlannerMobile.dirty || urbanPlannerMobile.touched)) && urbanPlannerMobile.errors.minlength">
                        *Mobile number must contain at least the 10 numbers
                    </small>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="connectedToUrbanPlanner > 0" class="row text-center">
        <div class="col-12">
            <button id="btnSaveFinal" [disabled]="contractDetails.invalid" class="button heading-4"
                (click)="openCollaborationDialog()">
                SUBMIT
            </button>
        </div>
    </div>
</form>